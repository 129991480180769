<template>
  <div>
    <div id="preloader" v-if="loading">
      <div id="preloader-status">
        <div class="spinner">
          <div class="rect1"></div>
          <div class="rect2"></div>
          <div class="rect3"></div>
          <div class="rect4"></div>
          <div class="rect5"></div>
        </div>
        <div id="preloader-title">Loading</div>
      </div>
    </div>

    <section class="page-section breadcrumbs text-center">
      <div class="container">
        <div class="page-header">
          <h1>Login</h1>
        </div>
        <ul class="breadcrumb justify-content-center">
          <li class="mx-2"><a href="#">Home</a></li>
          <i class="fas fa-angle-right"></i>
          <li class="mx-2"><a href="#">Pages</a></li>
          <i class="fas fa-angle-right"></i>
          <li class="active">Login</li>
        </ul>
      </div>
    </section>

    <div class="page-section color">
      <div class="container">
        <div class="row">
          <div class="col-sm-6">
            <h3 class="block-title"><span>Login</span></h3>
            <form action="#" class="form-login">
              <div class="row">
                <div class="col-md-12 hello-text-wrap">
                  <span class="hello-text text-thin"
                    >Hello, welcome to your account</span
                  >
                </div>
                <!-- <div class="col-md-12 col-lg-6">
                                <a class="btn btn-theme btn-block btn-icon-left facebook" href="#"><i class="fa fa-facebook"></i>Sign in with Facebook</a>
                            </div>
                            <div class="col-md-12 col-lg-6">
                                <a class="btn btn-theme btn-block btn-icon-left twitter" href="#"><i class="fa fa-twitter"></i>Sign in with Twitter</a>
                            </div> -->
                <div class="col-md-12">
                  <div class="form-group">
                    <input
                      class="form-control"
                      type="text"
                      placeholder="Your email"
                      v-model="email"
                      :class="errors.email && 'border-danger'"
                    />
                  </div>
                  <span class="errors-label" v-if="errors.email">{{
                    errors.email[0]
                  }}</span>
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <input
                      class="form-control"
                      type="password"
                      placeholder="Your password"
                      v-model="password"
                      :class="errors.password && 'border-danger'"
                    />
                  </div>
                  <span class="errors-label" v-if="errors.password">{{
                    errors.password[0]
                  }}</span>
                </div>
                <div class="col-md-12 col-lg-6">
                  <label
                    class="container-checkbox"
                    style="color: #000 !important"
                    >Remember Me
                    <input
                      id="extras_checkbox"
                      type="checkbox"
                      v-model="rememberMe"
                    />
                    <span class="mark-checkbox"></span>
                  </label>
                </div>
                <div class="col-md-12 col-lg-6 text-right-lg">
                  <router-link to="/forgot-password" class="forgot-password"
                    >forgot password?</router-link
                  >
                </div>
                <div class="col-md-6 pull-right">
                  <a
                    class="btn btn-theme btn-block btn-theme-dark"
                    v-on:click="login()"
                    >Login</a
                  >
                </div>
              </div>
            </form>
          </div>
          <div class="col-sm-6">
            <h3 class="block-title"><span>Create New Account</span></h3>
            <div class="row mt-1">
              <div class="col-md-12 hello-text-wrap my-3">
                <span class="hello-text text-thin">Create Your Account</span>
              </div>
              <div class="col-md-6 my-3">
                <b-button
                  @click="register"
                  class="btn btn-block btn-theme btn-theme-dark btn-create"
                  >Create Account</b-button
                >
              </div>
            </div>
            <form action="#" class="create-account">
              <div class="row mt-2">
                <div class="col-md-12">
                  <h3 class="block-title">
                    Be the first to get our exclusive offers and latest news  {{subscribeCheck}}
                  </h3>
                  <label
                    class="container-checkbox"
                    style="color: #000 !important"
                  >
                    Subscribe to our newsletter
                   
                    <input
                      id="subscribe_checkbox"
                      type="checkbox"
                      v-model="subscribeCheck"
                    />
                    <span class="mark-checkbox"></span>
                  </label>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
    <FooterInformation></FooterInformation>

    <RegisterModal></RegisterModal>
  </div>
</template>

<script>
import FooterInformation from "@/components/FooterInformation";
import { apiUrl } from "@/constants/config.js";
import axios from "axios";
import RegisterModal from "../components/RegisterModal.vue";
export default {
  components: { FooterInformation, RegisterModal },
  data() {
    return {
      email: this.$store.state.rememberEmail
        ? this.$store.state.rememberEmail
        : "",
      password: "",
      rememberMe: false,
      subscribeCheck: false,
      errors: [],
      loading: true,
    };
  },
  created() {
    if (this.$store.state.showSideMenu == true) {
      this.$store.state.showSideMenu = !this.$store.state.showSideMenu;
    }
    this.hideLoading();
  },
  methods: {
    register() {
      this.$modal.show("register");
    },
    login() {
      const body = {
        email: this.email,
        password: this.password,
      };

      if (this.rememberMe == true) {
        this.$store.commit("setRememberEmail", this.email);
      }

      axios
        .post(apiUrl + "v1/auth/loggedin", body)
        .then((res) => {
          if (res.data.status) {
            this.$store.commit("setLogged", true);
            this.$store.commit("setToken", res.data.token);
            this.$store.commit("setUserData", res.data.customer);

            if (this.$store.state.reservationData) {
              this.$router.go(-1);
            } else {
              this.$router.push("/");
            }

            if(this.subscribeCheck == true){
              this.subscribe();
            }
          } else if (res.data.error == "invalid_credentials") {
            this.errors = {
              password: ["Invalid credential"],
            };
            console.log(this.errors);
          } else {
            this.errors = res.data.errors;
          }
        })
        .catch((err) => console.log(err));
    },

    subscribe() {
      const body = {
        email: this.email,
      };
      axios
        .post(apiUrl + "v1/subscribe", body)
        .then((data) => {
          console.log(data.data.status);
        })
        .catch((err) => {
          console.log(err);
        });
    },

    hideLoading() {
      setTimeout(() => {
        this.loading = false;
      }, 1000);
    },
  },
};
</script>

<style>
.form-login .btn-theme,
.create-account .btn-theme {
  padding-top: 14px !important;
  padding-bottom: 14px !important;
}

.form-login .form-control {
  font-size: 14px !important;
}

.form-login .container-checkbox {
  color: #000 !important;
  text-transform: uppercase;
  padding-left: 25px !important;
  font-weight: bold !important;
}

.create-account .list-check {
  padding-left: 0px !important;
  font-size: 15px !important;
}
</style>