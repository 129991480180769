<template>
  <div>
    <section class="page-section breadcrumbs text-center">
      <div class="container mt-5">
        <ul class="breadcrumb">
          <li class="mx-2"><a href="#">Home</a></li>
          <i class="fas fa-angle-right"></i>
          <li class="mx-2"><a href="#">Pages</a></li>
          <i class="fas fa-angle-right"></i>
          <li class="active">Forgot Password</li>
        </ul>
      </div>
    </section>

    <div class="page-section color">
      <div class="container">
        <div id="preloader" v-if="loading">
          <div id="preloader-status">
            <div class="spinner">
              <div class="rect1"></div>
              <div class="rect2"></div>
              <div class="rect3"></div>
              <div class="rect4"></div>
              <div class="rect5"></div>
            </div>
            <div id="preloader-title">Loading</div>
          </div>
        </div>

        <div class="row justify-content-center">
          <div v-if="!successReset" class="col-sm-8 p-5 shadow">
            <h3 class="block-title"><span>Forgot Password</span></h3>
            <form action="#" class="form-login">
              <div class="row">
                <div class="col-md-12 hello-text-wrap">
                  <span class="hello-text text-thin"
                    >Forgot your password ? Don't worry! Just type the email you
                    registered to our website.</span
                  >
                </div>
                <div class="col-md-12">
                  <div class="form-group">
                    <input
                      class="form-control"
                      type="text"
                      placeholder="Your email"
                      v-model="email"
                      :class="errors.email && 'border-danger'"
                    />
                  </div>
                  <small class="errors-label" v-if="errors.email">{{
                    errors.email[0]
                  }}</small>
                </div>
                <div class="col-md-6">
                  <b-button
                    @click="resetPassword"
                    class="btn btn-block btn-theme btn-theme-dark btn-create"
                    >Reset Password</b-button
                  >
                </div>
              </div>
            </form>
          </div>

          <div v-if="successReset" class="col-sm-8 p-5 shadow text-center">
            <p>
              Your password reset email was sent, verify link on your email to
              reset password
            </p>
            <div class="d-flex justify-content-center">
            <router-link to="/login">
              <b-button
                class="btn btn-block btn-theme btn-theme-dark btn-create"
              >
                Go to login page
              </b-button>
            </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
    <FooterInformation></FooterInformation>
  </div>
</template>

<script>
import FooterInformation from "@/components/FooterInformation";
import { apiUrl } from "@/constants/config.js";
import axios from "axios";
export default {
  components: { FooterInformation },
  data() {
    return {
      loading: false,
      successReset: false,
      email: "",
      errors: [],
    };
  },
  methods: {
    register() {
      this.$modal.show("register");
    },

    resetPassword() {
      this.loading = true;
      const body = {
        email: this.email,
      };

      axios
        .post(apiUrl + "v1/auth/forgot-password", body)
        .then((res) => {
          if (res.data.status == true) {
            this.successReset = true;
            this.loading = false;
          } else {
            this.errors = res.data.errors;
          }
        })
        .catch((err) => console.log(err));
    },
  },
};
</script>

<style>
.form-login .btn-theme,
.create-account .btn-theme {
  padding-top: 14px !important;
  padding-bottom: 14px !important;
}
.list-check {
  padding-left: 0px !important;
}
</style>