<template>
  <div>
    <div class="content-area content my-profile">
      <div id="preloader" v-if="loading">
        <div id="preloader-status">
          <div class="spinner">
            <div class="rect1"></div>
            <div class="rect2"></div>
            <div class="rect3"></div>
            <div class="rect4"></div>
            <div class="rect5"></div>
          </div>
          <div id="preloader-title">Loading</div>
        </div>
      </div>
      <!-- BREADCRUMBS -->
      <section class="page-section breadcrumbs text-center">
        <div class="container">
          <div class="page-header">
            <h1>My Profile</h1>
          </div>
          <ul class="breadcrumb">
            <li><a href="#">Home</a></li>
            <i class="fas fa-angle-right mx-2"></i>
            <li><a href="#">Pages</a></li>
            <i class="fas fa-angle-right mx-2"></i>
            <li class="active">Profile</li>
          </ul>
        </div>
      </section>
      <!-- /BREADCRUMBS -->

      <!-- PAGE WITH SIDEBAR -->
      <section class="page-section with-sidebar sub-page">
        <div class="container">
          <form>
            <h3 class="block-title alt mt-2">Personal Information</h3>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group my-2">
                  <label for="name"> Customer Type </label>
                  <select
                    name="fd-name"
                    title="Name is required"
                    data-toggle="tooltip"
                    class="form-select-control w-100"
                    type="text"
                    placeholder="Name and Surname:*"
                    v-model="customerType"
                  >
                    <option
                      value="international"
                      :selected="customerType === 'international'"
                    >
                      International
                    </option>
                    <option value="local" :selected="customerType === 'local'">
                      Local
                    </option>
                  </select>
                  <span v-if="errors.type" class="form-text text-danger"
                    >errors.type[0]</span
                  >
                </div>
              </div>
              <div class="col-md-12">
                <div class="radio radio-inline">
                  <input
                    type="radio"
                    id="inlineRadio1"
                    value="Mr"
                    name="radioInline"
                    v-model="title"
                  />
                  <label for="inlineRadio1">Mr</label>
                </div>
                <div class="radio radio-inline">
                  <input
                    type="radio"
                    id="inlineRadio2"
                    value="Ms"
                    name="radioInline"
                    v-model="title"
                  />
                  <label for="inlineRadio2">Ms</label>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Name</label>
                  <input
                    id="fd-name"
                    title=""
                    data-toggle="tooltip"
                    class="form-control alt"
                    type="text"
                    placeholder="Name and Surname:*"
                    data-original-title="Name is required"
                    v-model="name"
                  />
                  <span v-if="errors.name" class="form-text text-danger">{{
                    errors.name[0]
                  }}</span>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Email</label>
                  <input
                    id="fd-email"
                    title=""
                    data-toggle="tooltip"
                    class="form-control alt"
                    type="email"
                    placeholder="Your Email Address:*"
                    data-original-title="Email is required"
                    v-model="email"
                  />
                  <span v-if="errors.email" class="form-text text-danger">{{
                    errors.email[0]
                  }}</span>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Telephone Number</label>
                  <input
                    class="form-control alt"
                    type="tel"
                    placeholder="Telephone Number:"
                    data-original-title=""
                    title=""
                    v-model="telephone"
                  />
                  <span v-if="errors.telephone" class="form-text text-danger">{{
                    errors.telephone[0]
                  }}</span>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Cell Phone Number</label><br>

                  <input
                    class="form-control alt"
                    type="tel"
                    placeholder="Cell Phone Number:"
                    data-original-title=""
                    title=""
                    v-model="phone"
                  />
                  <small style="font-size:95% !important;">Use country code. ex: 61*** , 62***</small>
                  <span v-if="errors.phone" class="form-text text-danger">{{
                    errors.phone[0]
                  }}</span>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label for="date_of_birth">Date Of Birth </label>
                  <input
                    class="form-control p-18 alt"
                    type="date"
                    v-model="date_of_birth"
                  />
                  <span
                    v-if="errors.date_of_birth"
                    class="form-text text-danger"
                    >{{ errors.date_of_birth[0] }}</span
                  >
                </div>
              </div>
              <div class="col-md-6 col-sm-0"></div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>New Password</label>
                  <input
                    class="form-control alt"
                    type="password"
                    placeholder="New Password:"
                    data-original-title=""
                    v-model="new_password"
                    autocomplete="new-password"
                  />
                  <span v-if="errors.password" class="form-text text-danger">{{
                    errors.password[0]
                  }}</span>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Password Confirmation</label>
                  <input
                    class="form-control alt"
                    type="password"
                    placeholder="Password Confirmation:"
                    data-original-title=""
                    v-model="confirm_password"
                    autocomplete="new-password"
                  />
                  <span v-if="errors.password" class="form-text text-danger">{{
                    errors.password[0]
                  }}</span>
                </div>
              </div>
            </div>
            <div v-if="customerType === 'international'">
              <h3 class="block-title alt mt-2">Original Address</h3>
              <div class="row mb-4">
                <div class="col-md-12">
                  <div class="form-group my-2">
                    <label for="au_adress">Search Address</label>
                    <vue-google-autocomplete
                      ref="oriaddress"
                      id="map1"
                      classname="form-control p-18 alt"
                      placeholder="Please type your address"
                      v-on:placechanged="getOriAddressData"
                    >
                    </vue-google-autocomplete>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group my-2">
                    <label for="au_adress">Original Street Address</label>
                    <input
                      class="form-control p-18 alt"
                      type="text"
                      placeholder="Street Address:"
                      v-model="ori_address"
                    />
                    <span
                      v-if="errors.ori_address"
                      class="form-text text-danger"
                      >{{ errors.ori_address[0] }}</span
                    >
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group my-2">
                    <label for="au_post_code">Original Post Code</label>
                    <input
                      class="form-control p-18 alt"
                      type="tel"
                      placeholder="Post Code:"
                      v-model="ori_post_code"
                    />
                    <span
                      v-if="errors.ori_post_code"
                      class="form-text text-danger"
                      >{{ errors.ori_post_code[0] }}</span
                    >
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group my-2">
                    <label for="au_post_code">Original City</label>
                    <input
                      class="form-control p-18 alt"
                      type="text"
                      placeholder="Original City:"
                      v-model="ori_city"
                    />
                    <span
                      v-if="errors.ori_city"
                      class="form-text text-danger"
                      >{{ errors.ori_city[0] }}</span
                    >
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group my-2">
                    <label for="au_post_code">Original State</label>
                    <input
                      class="form-control p-18 alt"
                      type="text"
                      placeholder="Original State:"
                      v-model="ori_state"
                    />
                    <span
                      v-if="errors.ori_state"
                      class="form-text text-danger"
                      >{{ errors.ori_state[0] }}</span
                    >
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group my-2">
                    <label for="au_post_code">Original Country</label>
                    <input
                      class="form-control p-18 alt"
                      type="text"
                      placeholder="Original Country:"
                      v-model="ori_country"
                    />
                    <span
                      v-if="errors.ori_country"
                      class="form-text text-danger"
                      >{{ errors.ori_country[0] }}</span
                    >
                  </div>
                </div>
              </div>
            </div>

            <div>
              <h3 class="block-title alt mt-2">Address</h3>
              <div class="row mb-4">
                <div class="col-md-12">
                  <div class="form-group my-2">
                    <label for="au_adress">Search Address</label>
                    <vue-google-autocomplete
                      :country="['au']"
                      ref="address"
                      id="map2"
                      classname="form-control p-18 alt"
                      placeholder="Please type your address"
                      v-on:placechanged="getAddressData"
                    >
                    </vue-google-autocomplete>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group my-2">
                    <label for="au_adress">Street Address</label>
                    <input
                      class="form-control p-18 alt"
                      type="text"
                      placeholder="Street Address:"
                      v-model="au_address"
                    />
                    <span
                      v-if="errors.au_address"
                      class="form-text text-danger"
                      >{{ errors.au_address[0] }}</span
                    >
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group my-2">
                    <label for="au_post_code">Post Code</label>
                    <input
                      class="form-control p-18 alt"
                      type="tel"
                      placeholder="Post Code:"
                      v-model="au_post_code"
                    />
                    <span
                      v-if="errors.au_post_code"
                      class="form-text text-danger"
                      >{{ errors.au_post_code[0] }}</span
                    >
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group my-2">
                    <label for="au_post_code">City</label>
                    <input
                      class="form-control p-18 alt"
                      type="text"
                      placeholder="State:"
                      v-model="au_city"
                    />
                    <span v-if="errors.au_city" class="form-text text-danger">{{
                      errors.au_city[0]
                    }}</span>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group my-2">
                    <label for="au_post_code">State</label>
                    <input
                      class="form-control p-18 alt"
                      type="text"
                      placeholder="State:"
                      v-model="au_state"
                    />
                    <span
                      v-if="errors.au_state"
                      class="form-text text-danger"
                      >{{ errors.au_state[0] }}</span
                    >
                  </div>
                </div>
              </div>
            </div>

            <div>
              <h3 class="block-title alt mt-2">Personal Document</h3>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group my-2">
                    <label for="au_adress">Driver License (Front)</label>
                    <div>
                      <img
                        :src="
                          driver_license_front
                            ? driver_license_front
                            : 'https://via.placeholder.com/300x150?text=Driver+License+(Front)'
                        "
                        class="doc-img"
                      />
                    </div>
                    <input
                      class="form-input-control mb-3"
                      type="file"
                      accept="image/*"
                      @change="previewImage"
                    />
                    <div v-if="licenseFrontData != null">
                      <img
                        class="preview"
                        :src="choosenLicenseFront"
                        style="
                          width: 180px;
                          border-radius: 10px;
                          margin-bottom: 10px;
                        "
                      />
                      <br />
                      <button class="btn btn-primary btn-sm" @click="onUpload">
                        <i class="icon-upload mr-1"> </i> Upload
                      </button>
                      <!-- <button
                      class="btn btn-sm btn-danger rounded ml-1"
                      @click="deleteImage"
                    >
                      <i class="icon-trash mr-1"> </i> Delete
                    </button> -->
                    </div>
                    <span
                      v-if="errors.driver_license_front"
                      class="form-text text-danger"
                      >{{ errors.driver_license_front[0] }}</span
                    >
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group my-2">
                    <label for="au_adress">Driver License (Back)</label>
                    <div>
                      <img
                        :src="
                          driver_license_back
                            ? driver_license_back
                            : 'https://via.placeholder.com/300x150?text=Driver+License+(Back)'
                        "
                        class="doc-img"
                      />
                    </div>
                    <input
                      class="form-input-control mb-3"
                      type="file"
                      accept="image/*"
                      @change="previewLicenseBack"
                    />
                    <div v-if="licenseBackData != null">
                      <img
                        class="preview"
                        :src="choosenLicenseBack"
                        style="
                          width: 180px;
                          border-radius: 10px;
                          margin-bottom: 10px;
                        "
                      />
                      <br />
                      <button
                        class="btn btn-primary btn-sm"
                        @click="onUploadLicenseBack"
                      >
                        <i class="icon-upload mr-1"> </i> Upload
                      </button>
                      <!-- <button
                      class="btn btn-sm btn-danger rounded ml-1"
                      @click="deleteLicenseBack"
                    >
                      <i class="icon-trash mr-1"> </i> Delete
                    </button> -->
                    </div>
                    <span
                      v-if="errors.driver_license_back"
                      class="form-text text-danger"
                      >{{ errors.driver_license_back[0] }}</span
                    >
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group my-2">
                    <label for="au_adress">Passport (Front)</label>
                    <div>
                      <img
                        :src="
                          passport_front
                            ? passport_front
                            : 'https://via.placeholder.com/300x150?text=Passport+(Front)'
                        "
                        class="doc-img"
                      />
                    </div>
                    <input
                      class="form-input-control mb-3"
                      type="file"
                      accept="image/*"
                      @change="previewPassportFront"
                    />
                    <div v-if="passportFrontData != null">
                      <img
                        class="preview"
                        :src="choosenPassportFront"
                        style="
                          width: 180px;
                          border-radius: 10px;
                          margin-bottom: 10px;
                        "
                      />
                      <br />
                      <button
                        class="btn btn-primary btn-sm"
                        @click="onUploadPassportFront"
                      >
                        <i class="icon-upload mr-1"> </i> Upload
                      </button>
                      <!-- <button
                      class="btn btn-sm btn-danger rounded ml-1"
                      @click="deletePassportFront"
                    >
                      <i class="icon-trash mr-1"> </i> Delete
                    </button> -->
                    </div>
                    <span
                      v-if="errors.passport_front"
                      class="form-text text-danger"
                      >{{ errors.passport_front[0] }}</span
                    >
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group my-2">
                    <label for="id_card"
                      >ID Card</label
                    >
                    <div>
                      <img
                        :src="
                          id_card
                            ? id_card
                            : 'https://via.placeholder.com/300x150?text=Photo'
                        "
                        class="doc-img"
                      />
                    </div>
                    <input
                      class="form-input-control mb-3"
                      type="file"
                      accept="image/*"
                      @change="previewIdCard"
                    />
                    <div v-if="idCardData != null">
                      <img
                        class="preview"
                        :src="choosenIdCard"
                        style="
                          width: 180px;
                          border-radius: 10px;
                          margin-bottom: 10px;
                        "
                      />
                      <br />
                      <button
                        class="btn btn-primary btn-sm"
                        @click="onUploadIdCard"
                      >
                        <i class="icon-upload mr-1"> </i> Upload
                      </button>
                      <!-- <button
                      class="btn btn-sm btn-danger rounded ml-1"
                      @click="deleteHolding"
                    >
                      <i class="icon-trash mr-1"> </i> Delete
                    </button> -->
                    </div>
                    <span
                      v-if="errors.id_card"
                      class="form-text text-danger"
                      >{{ errors.id_card[0] }}</span
                    >
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="form-group my-2">
                    <label for="holding_license_passpor_photo"
                      >Photo while holding license & passport</label
                    >
                    <div>
                      <img
                        :src="
                          holding_license_passport_photo
                            ? holding_license_passport_photo
                            : 'https://via.placeholder.com/300x150?text=Photo'
                        "
                        class="doc-img"
                      />
                    </div>
                    <input
                      class="form-input-control mb-3"
                      type="file"
                      accept="image/*"
                      @change="previewHolding"
                    />
                    <div v-if="holdingData != null">
                      <img
                        class="preview"
                        :src="choosenHolding"
                        style="
                          width: 180px;
                          border-radius: 10px;
                          margin-bottom: 10px;
                        "
                      />
                      <br />
                      <button
                        class="btn btn-primary btn-sm"
                        @click="onUploadHolding"
                      >
                        <i class="icon-upload mr-1"> </i> Upload
                      </button>
                      <!-- <button
                      class="btn btn-sm btn-danger rounded ml-1"
                      @click="deleteHolding"
                    >
                      <i class="icon-trash mr-1"> </i> Delete
                    </button> -->
                    </div>
                    <span
                      v-if="errors.holding_license_passport_photo"
                      class="form-text text-danger"
                      >{{ errors.holding_license_passport_photo[0] }}</span
                    >
                  </div>
                </div>
                <div class="col-md-6">
                  <label for="passport_exp_date">ID Card Expired Date</label>
                  <div class="form-group my-2">
                    <input
                      type="date"
                      class="form-control"
                      v-model="id_card_exp_date"
                      :min="new Date().toLocaleDateString('en-CA')"
                    />
                    <span
                      v-if="errors.id_card_exp_date"
                      class="form-text text-danger"
                      >{{ errors.id_card_exp_date[0] }}</span
                    >
                  </div>
                </div>
                <div class="col-md-6">
                  <label for="passport_exp_date">Passport Expired Date</label>
                  <div class="form-group my-2">
                    <input
                      type="date"
                      class="form-control"
                      v-model="passport_exp_date"
                      :min="new Date().toLocaleDateString('en-CA')"
                    />
                    <span
                      v-if="errors.passport_exp_date"
                      class="form-text text-danger"
                      >{{ errors.passport_exp_date[0] }}</span
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="row-button">
              <button
                class="btn-theme btn-back mr-3"
                @click.prevent="$router.go(-1)"
              >
                Back
              </button>
              <button
                v-if="
                  driver_license_front &&
                  driver_license_back &&
                  passport_front &&
                  holding_license_passport_photo
                "
                class="btn-theme pull-right btn-reservation-now"
                @click.prevent="updateProfile"
              >
                Update Profile
              </button>
              <button
                v-else
                class="btn-theme pull-right btn-reservation-now disabled"
                @click.prevent="updateProfile"
              >
                Update Profile
              </button>
            </div>
          </form>
        </div>
      </section>
      <!-- /PAGE WITH SIDEBAR -->

      <ContactForm></ContactForm>
    </div>
  </div>
</template>
<script src="https://www.gstatic.com/firebasejs/7.0.0/firebase-storage.js"></script>
<script>
import http from "axios";
import { apiUrl } from "@/constants/config";
import ContactForm from "@/components/ContactForm.vue";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import firebase from "firebase";
import "firebase/storage";

export default {
  name: "Profile",
  components: { ContactForm, VueGoogleAutocomplete },
  data() {
    return {
      loading: false,
      customerType: "international",
      title: "",
      name: "",
      email: "",
      old_password: "",
      new_password: "",
      confirm_password: "",
      phone: 0,
      telephone: 0,
      date_of_birth: "",
      address: {},
      oriAddress: {},
      au_address: "",
      au_post_code: "",
      au_city: "",
      au_state: "",
      au_country: "",
      ori_address: "",
      ori_post_code: "",
      ori_city: "",
      ori_state: "",
      ori_country: "",
      driver_license_front: null,
      driver_license_back: null,
      passport_front: null,
      passport_exp_date: null,
      id_card: null,
      id_card_exp_date: null,
      holding_license_passport_photo: null,
      errors: [],

      licenseFrontData: null,
      uploadLicenseFrontValue: 0,
      choosenLicenseFront: "",

      licenseBackData: null,
      uploadLicenseBackValue: 0,
      choosenLicenseBack: "",

      passportFrontData: null,
      uploadPassportFrontValue: 0,
      choosenPassportFront: "",

      holdingData: null,
      uploadHoldingValue: 0,
      choosenHolding: "",

      idCardData: null,
      uploadIdCardValue: 0,
      choosenIdCard: "",
    };
  },
  created() {
    if (this.$store.state.logged == true) {
      this.getProfile();
    } else {
      this.$router.push("/login");
    }
  },
  methods: {
    getProfile() {
      this.loading = true;
      if (this.$store.state.logged) {
        setTimeout(() => {
          this.loading = false;
        }, 1000);
        var customerData = this.$store.state.userData;
        console.log(customerData)
        this.customer_id = customerData.id;
        this.customerType = customerData.type;
        this.title = customerData.title;
        this.name = customerData.name;
        this.email = customerData.email;
        this.phone = customerData.phone;
        this.telephone = customerData.telephone;
        this.date_of_birth = new Date(customerData.date_of_birth)
          .toISOString()
          .substr(0, 10);
        this.au_address = customerData.au_address;
        this.au_post_code = customerData.au_post_code;
        this.au_city = customerData.au_city;
        this.au_state = customerData.au_state;
        this.au_country = customerData.au_country;
        this.ori_address = customerData.ori_address;
        this.ori_post_code = customerData.ori_post_code;
        this.ori_city = customerData.ori_city;
        this.ori_state = customerData.ori_state;
        this.ori_country = customerData.ori_country;
        this.driver_license_front = customerData.driver_license_front;
        this.driver_license_back = customerData.driver_license_back;
        this.passport_front = customerData.passport_front;
        this.id_card = customerData.id_card;
        this.holding_license_passport_photo = customerData.holding_license_passport_photo;

        if (
          customerData.passport_exp_date == "1970-01-01" ||
          customerData.passport_exp_date == null
        ) {
          this.passport_exp_date = new Date().toISOString().substr(0, 10);
        } else {
          this.passport_exp_date = new Date(customerData.passport_exp_date)
            .toISOString()
            .substr(0, 10);
        }
        if (
          customerData.id_card_exp_date == "1970-01-01" ||
          customerData.id_card_exp_date == null
        ) {
          this.id_card_exp_date = new Date().toISOString().substr(0, 10);
        } else {
          this.id_card_exp_date = new Date(customerData.id_card_exp_date)
            .toISOString()
            .substr(0, 10);
        }
      }
    },
    getOriAddressData(oriAddressData) {
      this.oriAddress = oriAddressData;
      this.ori_address = oriAddressData.street_number
        ? oriAddressData.street_number + " " + oriAddressData.route
        : oriAddressData.route;
      this.ori_post_code = oriAddressData.post_code;
      this.ori_city = oriAddressData.administrative_area_level_2;
      this.ori_state = oriAddressData.administrative_area_level_1;
      this.ori_country = oriAddressData.country;
      console.log(this.address);
    },
    getAddressData(addressData) {
      this.address = addressData;
      this.au_address = addressData.street_number
        ? addressData.street_number + " " + addressData.route
        : addressData.route;
      this.au_post_code = addressData.post_code;
      this.au_city = addressData.administrative_area_level_2;
      this.au_state = addressData.administrative_area_level_1;
      console.log(this.address);
    },

    previewImage(event) {
      this.uploadLicenseFrontValue = 0;
      this.licenseFrontData = event.target.files[0];
      this.choosenLicenseFront = URL.createObjectURL(this.licenseFrontData);
      this.onUpload();
    },

    onUpload() {
      this.loading = true;
      var rand = Math.random().toString(36).substring(2, 8);
      var now = Math.floor(Date.now() / 1000);
      console.log(firebase);
      const storageRef = firebase
        .storage()
        .ref(`${now + "_" + rand + "_" + this.licenseFrontData.name}`)
        .put(this.licenseFrontData);
      storageRef.on(
        `state_changed`,
        (snapshot) => {
          this.uploadLicenseFrontValue =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        (error) => {
          console.log(error.message);
        },
        () => {
          this.uploadLicenseFrontValue = 100;
          this.licenseFrontData = null;
          this.choosenLicenseFront = null;

          storageRef.snapshot.ref.getDownloadURL().then((url) => {
            this.driver_license_front = url;
            this.loading = false;
            this.$notify({
              type: "success",
              text: "Success Upload Driver License (Front)",
            });
          });
        }
      );
    },

    deleteImage() {
      var storage = firebase.storage();
      var storageRef = storage.refFromURL(this.driver_license_front);

      var result = confirm("Are you sure to delete?");
      if (result) {
        var deleteProcess = storageRef.delete();

        if (deleteProcess) {
          this.choosenLicenseFront = null;
          this.licenseFrontData = null;
          this.driver_license_front = null;
          this.$toast.success("File deleted successfully");
        } else {
          this.$toast.error(data.error);
        }
      }
    },

    previewLicenseBack(event) {
      this.uploadLicenseBackValue = 0;
      this.licenseBackData = event.target.files[0];
      this.choosenLicenseBack = URL.createObjectURL(this.licenseBackData);
      this.onUploadLicenseBack();
    },

    onUploadLicenseBack() {
      console.log(this.licenseBackData);
      this.loading = true;
      var rand = Math.random().toString(36).substring(2, 8);
      var now = Math.floor(Date.now() / 1000);
      console.log(firebase);
      const storageRef = firebase
        .storage()
        .ref(`${now + "_" + rand + "_" + this.licenseBackData.name}`)
        .put(this.licenseBackData);
      storageRef.on(
        `state_changed`,
        (snapshot) => {
          this.uploadLicenseBackValue =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        (error) => {
          console.log(error.message);
        },
        () => {
          this.uploadLicenseBackValue = 100;
          this.licenseBackData = null;
          this.choosenLicenseBack = null;

          storageRef.snapshot.ref.getDownloadURL().then((url) => {
            this.driver_license_back = url;
            this.loading = false;
            this.$notify({
              type: "success",
              text: "Success Upload Driver License (Back)",
            });
          });
        }
      );
    },

    deleteLicenseBack() {
      var storage = firebase.storage();
      var storageRef = storage.refFromURL(this.driver_license_back);

      var result = confirm("Are you sure to delete?");
      if (result) {
        var deleteProcess = storageRef.delete();

        if (deleteProcess) {
          this.choosenLicenseBack = null;
          this.licenseBackData = null;
          this.driver_license_back = null;
          this.$toast.success("File deleted successfully");
        } else {
          this.$toast.error(data.error);
        }
      }
    },

    previewPassportFront(event) {
      this.uploadPasportFrontValue = 0;
      this.passportFrontData = event.target.files[0];
      this.choosenPassportFront = URL.createObjectURL(this.passportFrontData);
      this.onUploadPassportFront();
    },

    onUploadPassportFront() {
      console.log(this.passportFrontData);
      this.loading = true;
      var rand = Math.random().toString(36).substring(2, 8);
      var now = Math.floor(Date.now() / 1000);
      console.log(firebase);
      const storageRef = firebase
        .storage()
        .ref(`${now + "_" + rand + "_" + this.passportFrontData.name}`)
        .put(this.passportFrontData);
      storageRef.on(
        `state_changed`,
        (snapshot) => {
          this.uploadPassportFrontValue =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        (error) => {
          console.log(error.message);
        },
        () => {
          this.uploadPassportFrontValue = 100;
          this.passportFrontData = null;
          this.choosenPassportFront = null;

          storageRef.snapshot.ref.getDownloadURL().then((url) => {
            this.passport_front = url;
            this.loading = false;
            this.$notify({
              type: "success",
              text: "Success Upload Passport",
            });
          });
        }
      );
    },

    deletePassportFront() {
      var storage = firebase.storage();
      var storageRef = storage.refFromURL(this.passport_front);

      var result = confirm("Are you sure to delete?");
      if (result) {
        var deleteProcess = storageRef.delete();

        if (deleteProcess) {
          this.choosenPassportFront = null;
          this.passportFrontData = null;
          this.passport_front = null;
          this.$toast.success("File deleted successfully");
        } else {
          this.$toast.error(data.error);
        }
      }
    },

    previewHolding(event) {
      this.uploadHoldingValue = 0;
      this.holdingData = event.target.files[0];
      this.choosenHolding = URL.createObjectURL(this.holdingData);
      this.onUploadHolding();
    },

    onUploadHolding() {
      this.loading = true;
      var rand = Math.random().toString(36).substring(2, 8);
      var now = Math.floor(Date.now() / 1000);
      console.log(firebase);
      const storageRef = firebase
        .storage()
        .ref(`${now + "_" + rand + "_" + this.holdingData.name}`)
        .put(this.holdingData);
      storageRef.on(
        `state_changed`,
        (snapshot) => {
          this.uploadHoldingValue =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        (error) => {
          console.log(error.message);
        },
        () => {
          this.uploadHoldingValue = 100;
          this.holdingData = null;
          this.choosenHolding = null;

          storageRef.snapshot.ref.getDownloadURL().then((url) => {
            this.holding_license_passport_photo = url;
            this.loading = false;
            this.$notify({
              type: "success",
              text: "Success Upload Photo",
            });
          });
        }
      );
    },

    deleteHolding() {
      var storage = firebase.storage();
      var storageRef = storage.refFromURL(this.holding_license_passport_photo);

      var result = confirm("Are you sure to delete?");
      if (result) {
        var deleteProcess = storageRef.delete();

        if (deleteProcess) {
          this.choosenHolding = null;
          this.holdingData = null;
          this.holding_license_passport_photo = null;
          this.$toast.success("File deleted successfully");
        } else {
          this.$toast.error(data.error);
        }
      }
    },

    previewIdCard(event) {
      this.uploadIdCardValue = 0;
      this.idCardData = event.target.files[0];
      this.choosenIdCard = URL.createObjectURL(this.idCardData);
      this.onUploadIdCard();
    },

    onUploadIdCard() {
      this.loading = true;
      var rand = Math.random().toString(36).substring(2, 8);
      var now = Math.floor(Date.now() / 1000);
      console.log(firebase);
      const storageRef = firebase
        .storage()
        .ref(`${now + "_" + rand + "_" + this.idCardData.name}`)
        .put(this.idCardData);
      storageRef.on(
        `state_changed`,
        (snapshot) => {
          this.uploadIdCardValue =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        },
        (error) => {
          console.log(error.message);
        },
        () => {
          this.uploadIdCardValue = 100;
          this.idCardData = null;
          this.choosenIdCard = null;

          storageRef.snapshot.ref.getDownloadURL().then((url) => {
            this.id_card = url;
            this.loading = false;
            this.$notify({
              type: "success",
              text: "Success Upload Photo",
            });
          });
        }
      );
    },

    deleteIdCard() {
      var storage = firebase.storage();
      var storageRef = storage.refFromURL(this.id_card);

      var result = confirm("Are you sure to delete?");
      if (result) {
        var deleteProcess = storageRef.delete();

        if (deleteProcess) {
          this.choosenIdCard = null;
          this.idCardData = null;
          this.id_card = null;
          this.$toast.success("File deleted successfully");
        } else {
          this.$toast.error(data.error);
        }
      }
    },

    updateProfile() {
      this.loading = true;

      const body = {
        id: this.customer_id,
        type: this.customerType,
        title: this.title,
        name: this.name,
        email: this.email,
        password: this.new_password ? this.new_password : null,
        password_confirmation: this.confirm_password
          ? this.confirm_password
          : null,
        phone: this.phone,
        telephone: this.telephone,
        date_of_birth: this.date_of_birth,
        au_address: this.au_address,
        au_post_code: this.au_post_code,
        au_city: this.au_city,
        au_state: this.au_state,
        au_country: this.au_country,
        ori_address: this.ori_address,
        ori_post_code: this.ori_post_code,
        ori_city: this.ori_city,
        ori_state: this.ori_state,
        ori_country: this.ori_country,
        driver_license_front: this.driver_license_front,
        driver_license_back: this.driver_license_back,
        passport_front: this.passport_front,
        passport_exp_date: this.passport_exp_date,
        id_card: this.id_card,
        id_card_exp_date: this.id_card_exp_date,
        holding_license_passport_photo: this.holding_license_passport_photo,
      };
      console.log(body);
      this.loading = false;
      http
        .post(apiUrl + "v1/profile/update/" + this.customer_id, body)
        .then((data) => {
          this.loading = false;
          if (data.data.status === true) {
            this.$store.commit("setUserData", body);
            this.$notify({
              type: "success",
              text: "Profile updated successfully",
            });
            this.errors = [];
          } else {
            this.errors = data.data.errors;
          }
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },
  },
};
</script>
<style>
.my-profile .form-control {
  padding: 18px !important;
  font-size: 14px !important;
}

.my-profile .btn-sm {
  padding: 8px !important;
  font-size: 13px !important;
  border-radius: 6px !important;
}

.my-profile .row-button {
  margin: 20px 0;
  display: flex;
  justify-content: flex-end;
}

.my-profile .btn-back {
  background-color: #5e5e5e;
  border-color: #5e5e5e !important;
}

.my-profile .btn-back:hover {
  background-color: #1b1b1b;
}
</style>