<template>
  <section class="page-section contact dark section-contact">
    <div class="container">
      <!-- Get in touch -->
      <h2
        class="section-title wow fadeInDown"
        data-wow-offset="200"
        data-wow-delay="100ms"
      >
        <small>Feel Free to Say Hello!</small>
        <span>Get in Touch With Us</span>
      </h2>
      <div class="row" style="margin-top: 30px; !important">
        <div
          class="form-contact col-md-6 wow fadeInLeft"
          data-wow-offset="200"
          data-wow-delay="200ms"
        >
          <!-- Contact form -->
          <form
            name="contact-form"
            method="post"
            action="#"
            class="contact-form"
            id="contact-form"
          >
            <div class="row">
              <div class="col-md-6">
                <div class="outer required">
                  <div class="form-group af-inner has-icon">
                    <label class="sr-only" for="name">Name</label>
                    <input
                      v-model="contactUsName"
                      type="text"
                      name="name"
                      id="name"
                      placeholder="Name"
                      value=""
                      size="30"
                      data-toggle="tooltip"
                      title="Name is required"
                      class="form-control placeholder"
                    />
                    <span v-if="errors.name" class="text-danger mt-3">
                      {{ errors.name[0] }}
                    </span>
                    <span class="form-control-icon"
                      ><i class="fa fa-user"></i
                    ></span>
                  </div>
                </div>
              </div>
              <div class="col-md-6">
                <div class="outer required">
                  <div class="form-group af-inner has-icon">
                    <label class="sr-only" for="email">Email</label>
                    <input
                      v-model="contactUsEmail"
                      type="text"
                      name="email"
                      id="email"
                      placeholder="Email"
                      value=""
                      size="30"
                      data-toggle="tooltip"
                      title="Email is required"
                      class="form-control placeholder"
                    />
                    <span v-if="errors.email" class="text-danger mt-3">
                      {{ errors.email[0] }}
                    </span>
                    <span class="form-control-icon"
                      ><i class="fa fa-envelope"></i
                    ></span>
                  </div>
                </div>
              </div>
            </div>
            <div class="outer required">
              <div class="form-group af-inner has-icon">
                <label class="sr-only" for="subject">Subject</label>
                <input
                  v-model="contactUsSubject"
                  type="text"
                  name="subject"
                  id="subject"
                  placeholder="Subject"
                  value=""
                  size="30"
                  data-toggle="tooltip"
                  title="Subject is required"
                  class="form-control placeholder"
                />
                <span v-if="errors.subject" class="text-danger mt-3">
                  {{ errors.subject[0] }}
                </span>
                <span class="form-control-icon"
                  ><i class="fa fa-bars"></i
                ></span>
              </div>
            </div>
            <div class="form-group af-inner has-icon">
              <label class="sr-only" for="input-message">Message</label>
              <textarea
                v-model="contactUsMessage"
                name="message"
                id="input-message"
                placeholder="Message"
                rows="4"
                cols="50"
                data-toggle="tooltip"
                title="Message is required"
                class="form-control placeholder"
              ></textarea>
              <span v-if="errors.message" class="text-danger mt-3">
                {{ errors.message[0] }}
              </span>
              <span class="form-control-icon"><i class="fa fa-bars"></i></span>
            </div>
            <div class="outer required">
              <div class="form-group af-inner">
                <button
                  @click.prevent="sendMessage"
                  class="
                    form-button form-button-submit
                    btn btn-block btn-theme
                    ripple-effect
                    btn-theme-dark btn-send-message
                  "
                  id="submit_btn"
                >
                  Send Message
                </button>
                <h4 v-if="messageSent" class="mt-3">
                  Your message has been sent successfully. Thank you for
                  contacting us
                </h4>
              </div>
            </div>
          </form>
          <!-- /Contact form -->
        </div>
        <div
          class="col-md-6 wow fadeInRight"
          data-wow-offset="200"
          data-wow-delay="200ms"
        >
          <p>
            {{dataConfig ? dataConfig.contact_text : ''}}
          </p>
          <ul class="media-list contact-list">
            <li class="media">
              <div class="media-left"><i class="fa fa-home"></i></div>
              <div class="media-body">
                Adress: {{dataConfig ? dataConfig.primary_address : ''}}
              </div>
            </li>
            <li class="media">
              <div class="media-left"><i class="fa fa-phone"></i></div>
              <div class="media-body">Support Phone: {{dataConfig ? dataConfig.support_contact : ''}}</div>
            </li>
            <li class="media">
              <div class="media-left"><i class="fa fa-envelope"></i></div>
              <div class="media-body">
                Email: {{dataConfig ? dataConfig.primary_email : ''}}
              </div>
            </li>
            <li class="media">
              <div class="media-left"><i class="fab fa-facebook"></i></div>
              <div class="media-body">Facebook: {{dataConfig ? dataConfig.facebook : ''}}</div>
            </li>
            <li class="media">
              <div class="media-left"><i class="fa fa-clock"></i></div>
              <div class="media-body">
                Working Hours: {{dataConfig ? dataConfig.working_hours : ''}} except on Sundays
              </div>
            </li>
            
          </ul>
        </div>
      </div>
      <!-- /Get in touch -->
    </div>
  </section>
</template>

<script>
import http from "axios";
import { apiUrl } from "@/constants/config";

export default {
  data() {
    return {
      dataConfig: {},
      contactUsName: "",
      contactUsEmail: "",
      contactUsSubject: "",
      contactUsMessage: "",
      errors: [],
      messageSent: false,
    };
  },
  created(){
    this.getData();
  },
  methods: {
    sendMessage() {
      this.loading = true;
      const body = {
        name: this.contactUsName,
        email: this.contactUsEmail,
        subject: this.contactUsSubject,
        message: this.contactUsMessage,
      };
      console.log(body);
      http
        .post(apiUrl + "admin/contact-forms", body)
        .then((data) => {
          if (data.data.status === true) {
            this.messageSent = true;
            this.contactUsName = "";
            this.contactUsEmail = "";
            this.contactUsSubject = "";
            this.contactUsMessage = "";
            this.errors = [];
            this.$notify({
              type: "success",
              text: "Your message has been sent",
              duration: 4000,
            });
          } else {
            this.errors = data.data.errors;
          }
        })
        .catch((err) => console.log(err));
    },
    getData(){
      http
        .get(apiUrl+'v1')
        .then(data =>{
          this.dataConfig = data.data.config;
          console.log(this.dataConfig);
        })
        .catch((err) => console.log(err))
    },
  },
};
</script>

<style>
.section-contact {
  font-size: 15px;
}
.section-contact .btn-send-message {
  padding: 13px 20px !important;
  font-size: 14px !important;
  font-weight: 600 !important;
  background-color: #000;
  color: #fff;
}
.section-contact .btn-send-message:hover {
  background-color: #f1be02;
  transition: 0.4s;
}
.contact-form {
  margin-top: 2.8rem !important;
}
</style>