<template>
  <div>
    <section class="page-section rental-offers">
      <div class="container">
        <h2
          class="section-title wow fadeInUp"
          data-wow-offset="70"
          data-wow-delay="100ms"
        >
          <small>What a Kind of Car You Want</small>
          <span>Great Rental Offers for You</span>
        </h2>
        <b-tabs>
          <b-tab v-for="(item,index) in categories" :key="index"
          :title="item.name" :active="index == 1">
            <div>
              <div>
                <vueper-slides
                  class="no-shadow mt-4"
                  slide-multiple
                  :slide-ratio="1 / 4"
                  :visible-slides="3"
                  :gap="3"
                  :dragging-distance="200"
                  :fixed-height="true"
                  :arrows="false"
                  :breakpoints="{
                    992: { visibleSlides: 2, slideMultiple: 1 },
                    600: { visibleSlides: 1, slideMultiple: 1 },
                  }"
                >
                  <vueper-slide
                    v-for="(vehicle, index) in item.vehicle"
                    :key="index"
                  >
                    <template #content>
                      <div
                        class="
                          thumbnail
                          no-border no-padding
                          thumbnail-car-card
                          slide-item
                        "
                      >
                        <div class="media" style="display: flex; height: 220px">
                          {{ vehicle.image }}
                          <a
                            class="media-link"
                            data-gal="prettyPhoto"
                            href="#"
                            @click.prevent="
                              selectImage(JSON.parse(vehicle.images)[0])
                            "
                          >
                            <img :src="JSON.parse(vehicle.images)[0]" alt="" />
                            <div v-if="vehicle.stock == 0" class="leased-out-badge">
                              Leased Out
                            </div>
                            <span class="icon-view"
                              ><strong><i class="fa fa-eye"></i></strong
                            ></span>
                          </a>
                        </div>
                        <div class="caption text-center">
                          <h4 class="caption-title">
                            <a href="#">{{ vehicle.name }}</a>
                          </h4>
                          <span v-if="vehicle.stock == 0" class="leased-out-text"> Leased Out</span>
                          <div class="caption-text" v-if="!vehicle.hourly">
                            Start from {{ vehicle.price[0].price }}$ / 1 day
                          </div>
                          <div class="caption-text" v-else>
                            Start from {{ vehicle.hourly }}$ /hour
                          </div>
                          <div class="buttons">
                            <router-link
                            v-if="vehicle.stock == 1"
                              :to="{ path: '/booking/' + vehicle.id }"
                              class="btn btn-theme ripple-effect btn-rentit"
                              >Rent It</router-link
                            >
                            <router-link
                            v-else
                              :to="{ path: '/booking/' + vehicle.id }"
                              class="btn btn-theme ripple-effect btn-rentit disabled"
                              >Rent It</router-link
                            >
                          </div>
                          <table class="table" style="width: 100%">
                            <tr>
                              <td
                                style="
                                  width: 20%%;
                                  border-right: solid 1px #e9e9e9;
                                "
                              >
                                <i class="fa fa-car"></i>
                                <br />
                                {{ vehicle.reg_year }}
                              </td>
                              <td style="width: 25%">
                                <i class="fas fa-tachometer-alt"></i>
                                <br />
                                {{ vehicle.fuel }}
                              </td>
                              <td style="width: 32%">
                                <i class="fa fa-cog"></i>
                                <br />
                                {{ vehicle.engine_type }}
                              </td>
                              <td style="width: 23%">
                                <i class="fa fa-road"></i>
                                <br />
                                {{ vehicle.km }}
                              </td>
                            </tr>
                          </table>
                        </div>
                      </div>
                    </template>
                  </vueper-slide>
                </vueper-slides>
              </div>
            </div>
          </b-tab>
        </b-tabs>
      </div>
    </section>

    <ImageModalOffers :selectedimage="selectedImageOffers"></ImageModalOffers>
  </div>
</template>
<script>
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";
import ImageModalOffers from "@/components/ImageModalOffers.vue";
export default {
  props: ["categories"],
  components: { VueperSlides, VueperSlide, ImageModalOffers },
  data() {
    return {
      selectedImageOffers: "",
      breakpoints_offers: {
        800: {
          visibleSlides: 2,
          slideMultiple: 2,
        },
        600: {
          arrows: false,
          bullets: false,
          visibleSlides: 1,
          slideMultiple: 1,
        },
      },
    };
  },
  methods: {
    selectImage(image) {
      this.selectedImageOffers = image;
      this.$modal.show("imagemodaloffers");
      console.log(this.selectedImageOffers);
    },
  },
};
</script>
<style>
.tabs li a {
  padding: 11.5px 26px !important;
}
.rental-offers {
  padding-bottom: 0 !important;
}

.rental-offers .tabs {
  margin-bottom: 0 !important;
}

.rental-offers .nav-tabs > li > a {
  margin-right: 0 !important;
}

@media only screen and (max-width: 991.98px) {
  .rental-offers .tabs ul {
    display: flex !important;
    flex-direction: column;
    align-content: center;
    justify-content: center;
  }
  .rental-offers .tabs li {
    text-align: center;
  }

  .rental-offers .slide-item {
    margin-bottom: 20px !important;
  }

  .rental-offers .vueperslides__bullet {
    opacity: 0 !important;
  }
}

.rental-offers .btn-rentit {
  padding: 10px 40px !important;
  line-height: 1;
  font-weight: 900 !important;
  background-color: #f1be02;
  color: #fff;
}

.rental-offers .btn-rentit:hover {
  background-color: #000;
  transition: 0.3s;
}

.rental-offers .thumbnail .media-link .icon-view {
  position: absolute;
  top: 50%;
  right: 50%;
  margin-right: -40px;
  margin-top: -40px;
  opacity: 0;
  -webkit-animation: fadeOut 0.4s;
  animation: fadeOut 0.4s;
}

.rental-offers .thumbnail .media-link .icon-view:hover {
  opacity: 1;
}

.rental-offers .icon-view strong {
  display: flex;
  width: 68px;
  height: 68px;
  border-radius: 50%;
  top: 50%;
  /* text-align: center; */
  background-color: rgba(0, 0, 0, 0.6);
  color: #fff;
  flex-direction: column;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.rental-offers .vueperslides--fixed-height {
  height: 440px;
}

.rental-offers .slide-item {
  min-height: 390px !important;
}

@media (min-width: 585px) and (max-width: 767.98px) {
  .rental-offers .vueperslides--fixed-height {
    height: 410px !important;
  }

  .rental-offers .slide-item {
    min-height: 400px !important;
  }
}

.rental-offers .vueperslides__bullet .default {
  border-radius: 0 !important;
  background-color: #0000004d;
  border: none;
  box-shadow: none;
  transition: 0.3s;
  width: 16px;
  height: 16px;
  margin-bottom: 2px;
}

.rental-offers .vueperslides__bullet--active .default {
  background-color: #f1be02;
}

.rental-offers .media {
  display: flex;
  justify-content: center;
}
/* .rental-offers .media img {
  width: auto;
  height: 200px;
  object-fit: contain;
} */

.rental-offers .media img {
  max-height: 220px;
  max-width: 350px;
  height:220px;
  width: 100vw;
  object-fit: cover;
  -o-object-fit: contain;
}
</style>