<template>
  <div class="mb-1">
    <div class="panel panel-default">
      <div class="panel-heading" role="tab" :id="'heading' + index" style="border: 1px solid #ddd !important;">
        <b-link block v-b-toggle="'accordion' + index" variant="info" style="text-decoration: none">
          <h4 class="panel-title">
              <span class="dot when-open"><i class="fa fa-minus"></i></span>
              <span class="dot when-closed"><i class="fa fa-plus"></i></span>
            {{ data.question }}
          </h4>
        </b-link>
      </div>
    </div>
    <b-collapse :id="'accordion' + index" :visible="index == 0 ? true : false" accordion="my-accordion" role="tabpanel" style="border: 1px solid #ddd !important;">
      <div :id="'collapse' + index" class="panel-collapse collapse in" role="tabpanel" :aria-labelledby="'heading' + index">
        <div class="panel-body">
            {{ data.answer }}
            <br>
            <img :src="data.images ? JSON.parse(data.images) : ''" style="height:auto; width:100%;">
        </div>
      </div>
    </b-collapse>
  </div>
</template>

<script>
export default {
  props: ['data', 'index'],
}
</script>

<style>
.collapsed > .panel-title > .when-open,
.not-collapsed > .when-closed {
  display: none;
}
.collapsed > .panel-title > .when-closed,
.not-collapsed > .when-open {
  display: block;
}
.not-collapsed > .panel-title > .when-closed,
.not-collapsed > .when-open {
  display: none;
}
</style>