<template>
  <div>
    <section class="page-section breadcrumbs text-center">
        <div class="container">
            <div class="page-header">
                <h1>Login</h1>
            </div>
            <ul class="breadcrumb">
                <li class="mx-2"><a href="#">Home</a></li><i class="fas fa-angle-right"></i>
                <li class="mx-2"><a href="#">Pages</a></li><i class="fas fa-angle-right"></i>
                <li class="active">Login</li>
            </ul>
        </div>
    </section>

    <div class="page-section color">
        <div class="container">
            <div class="row">
                <div class="col-sm-6">
                    <h3 class="block-title"><span>Login</span></h3>
                    <form action="#" class="form-login">
                        <div class="row">
                            <div class="col-md-12 hello-text-wrap">
                                <span class="hello-text text-thin">Hello, welcome to your account</span>
                            </div>
                            <!-- <div class="col-md-12 col-lg-6">
                                <a class="btn btn-theme btn-block btn-icon-left facebook" href="#"><i class="fa fa-facebook"></i>Sign in with Facebook</a>
                            </div>
                            <div class="col-md-12 col-lg-6">
                                <a class="btn btn-theme btn-block btn-icon-left twitter" href="#"><i class="fa fa-twitter"></i>Sign in with Twitter</a>
                            </div> -->
                            <div class="col-md-12">
                                <div class="form-group"><input class="form-control" type="text" placeholder="Your name" v-model="name"></div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group"><input class="form-control" type="email" placeholder="Your email" v-model="email"></div>
                            </div>
                            <div class="col-md-12">
                                <div class="form-group"><input class="form-control" type="password" placeholder="Your password" v-model="password"></div>
                            </div>
                            <div class="col-md-12">
                              <v-select :options="['Local', 'Student', 'International']" class="form-group" placeholder="Choose your type"></v-select>
                              <small>* Please choose international if you are outside Australia</small>
                            </div>
                            <div class="col-md-12 col-lg-6">
                                <div class="checkbox">
                                    <label><input type="checkbox"> Remember me</label>
                                </div>
                            </div>
                            <div class="col-md-12 col-lg-6 text-right-lg">
                                <a class="forgot-password" href="#">forgot password?</a>
                            </div>
                            <div class="col-md-6 pull-right">
                                <a class="btn btn-theme btn-block btn-theme-dark" v-on:click="login()">Login</a>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="col-sm-6">
                    <h3 class="block-title"><span>Create New Account</span></h3>
                    <form action="#" class="create-account">
                        <div class="row">
                            <div class="col-md-12 hello-text-wrap">
                                <span class="hello-text text-thin">Create Your Account</span>
                            </div>
                            <div class="col-md-12">
                                <h3 class="block-title">Signup Today and You'll be able to</h3>
                                <ul class="list-check">
                                    <li><i class="mr-3 far fa-check-square"></i>Online Order Status</li>
                                    <li><i class="mr-3 far fa-check-square"></i>See Ready Hot Deals</li>
                                    <li><i class="mr-3 far fa-check-square"></i>Love List</li>
                                    <li><i class="mr-3 far fa-check-square"></i>Sign up to receive exclusive news and private sales</li>
                                    <li><i class="mr-3 far fa-check-square"></i>Quick Buy Stuffs</li>
                                </ul>
                            </div>
                            <div class="col-md-6">
                                <a class="btn btn-block btn-theme btn-theme-dark btn-create" href="#">Create Account</a>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    <FooterInformation></FooterInformation>
  </div>
</template>

<script>
import FooterInformation from '@/components/FooterInformation'
export default {
  components: { FooterInformation },
  data() {
    return {
      name: '',
      email: '',
      password: '',
    }
  },

}
</script>

<style>
.form-login .btn-theme, .create-account .btn-theme {
  padding-top: 14px !important;
  padding-bottom: 14px !important;
}
.list-check {
  padding-left: 0px !important;
}
input[type="search"] {
  height: 40px;
}
</style>