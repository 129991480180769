<template>
  <div>
    <div
      class="container d-flex justify-content-center my-5 container-search"
      v-bind:style="{ 'background-image': 'url(' + background + ')' }"
    >
      <div class="container">
        <div class="slide1">
          <div class="mt-4 sm-none">
            <h2 class="title text-center d-xs-none d-md-block">
              All Discounts Just For You
            </h2>
            <h3 class="subtitle text-center d-xs-none d-md-block">
              Find Best Rental Car
            </h3>
          </div>
          <div class="container d-flex justify-content-center my-5">
            <div class="col-md-10 mx-auto">
              <div class="form-search">
                <form action="#">
                  <div class="p-4">
                    <!-- <i class="fa fa-globe"></i>
                    <h2>Search for Cheap Rental Cars Wherever Your Are</h2> -->
                    <div class="form-group has-label">
                      <select
                        class="form-select-control w-100"
                        v-model="selectedCategory"
                      >
                        <option value="" selected>Select Services</option>
                        <option
                          v-for="item in categories"
                          :key="item.id"
                          :value="item.id"
                        >
                          {{ item.name }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="row row-inputs">
                    <div class="container-fluid">
                      <div class="col-md-4">
                        <div class="form-group has-label">
                          <label for="formSearchOffDate"
                            >Picking Up Location</label
                          >
                          <select
                            class="form-select-control w-100"
                            v-model="picklocation"
                          >
                            <option value="" selected>
                              Select Pickup Location
                            </option>
                            <option
                              v-for="item in locations"
                              :key="item.id"
                              :value="item.name"
                            >
                              {{ item.name }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group has-label">
                          <label for="formSearchUpDate">Picking Up Date</label>
                          <date-picker
                            v-model="pickdate"
                            format="DD-MM-YYYY"
                            type="date"
                            placeholder="Select Pick Up Date"
                            :disabled-date="disabledBeforeToday"
                            @change="onChangePickDate()"
                          ></date-picker>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div
                          class="
                            form-group
                            has-icon has-label
                            selectpicker-wrapper
                          "
                        >
                          <label>Picking Up Hour</label>
                          <date-picker
                            v-model="pickhour"
                            value-type="format"
                            type="time"
                            format="hh:mm a"
                            placeholder="Select Picking Up Hour"
                            :default-value="new Date().setHours(9, 0)"
                            :time-picker-options="timeOptions"
                          ></date-picker>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row row-inputs">
                    <div class="container-fluid">
                      <div class="col-md-4">
                        <div class="form-group has-label">
                          <label for="formSearchOffDate"
                            >Dropping Off Location</label
                          >
                          <select
                            class="form-select-control w-100"
                            v-model="droplocation"
                          >
                            <option value="" selected>
                              Select Drop Off Location
                            </option>
                            <option
                              v-for="item in locations"
                              :key="item.id"
                              :value="item.name"
                            >
                              {{ item.name }}
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div class="form-group has-label">
                          <label for="formSearchOffDate"
                            >Dropping Off Date</label
                          >
                          <date-picker
                            v-model="dropdate"
                            format="DD-MM-YYYY"
                            type="date"
                            placeholder="Select Pick Up Date"
                            :disabled-date="disabledBeforePickdate"
                            :class="!pickdate ? 'disabled' : ''"
                          ></date-picker>
                        </div>
                      </div>
                      <div class="col-md-4">
                        <div
                          class="
                            form-group
                            has-icon has-label
                            selectpicker-wrapper
                          "
                        >
                          <label>Dropping Off Hour</label>
                          <date-picker
                            v-model="drophour"
                            value-type="format"
                            type="time"
                            format="hh:mm a"
                            placeholder="Select Dropping Off Hour"
                            :default-value="new Date().setHours(9, 0)"
                            :time-picker-options="{
                              start: '09:00',
                              step: '00:30',
                              end: '17:00',
                            }"
                          ></date-picker>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row row-submit">
                    <div class="container-fluid">
                      <div class="inner text-right">
                        <div
                          v-if="pickdate && pickhour && dropdate && drophour"
                        >
                          <button
                            @click="findCar"
                            class="btn btn-submit btn-theme pull-right"
                          >
                            Find Car
                          </button>
                        </div>
                        <div v-else>
                          <a
                            href="javascript:void(0)"
                            class="btn btn-submit btn-theme pull-right disabled"
                          >
                            Find Car
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <FooterInformation></FooterInformation>
  </div>
</template>

<script>
import http from "axios";
import { apiUrl } from "@/constants/config";
import FooterInformation from "@/components/FooterInformation";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
export default {
  name: "Reservation",
  components: { FooterInformation, DatePicker },
  data() {
    return {
      selectedCategory: "",
      picklocation: "",
      pickdate: "",
      pickhour: "",
      droplocation: "",
      dropdate: "",
      drophour: "",

      timeOptions: {},

      locations: [],
      categories: [],
      background: "",
    };
  },
  created() {
    this.getLocation();
    if (this.$store.state.reservationData) {
      var pickdate = "";
      var dropdate = "";

      if (
        new Date(this.$store.state.reservationData.pickup_date) < new Date()
      ) {
        pickdate = new Date();
      } else {
        pickdate = new Date(this.$store.state.reservationData.pickup_date);
      }

      if (
        new Date(this.$store.state.reservationData.dropoff_date) < new Date()
      ) {
        dropdate = new Date(Date.now() + 3600 * 1000 * 24);
      } else {
        dropdate = new Date(this.$store.state.reservationData.dropoff_date);
      }
      this.selectedCategory = this.$store.state.reservationData.selectedCategory;
      this.picklocation = this.$store.state.reservationData.pickup_location;
      this.pickdate = pickdate;
      this.pickhour = this.$store.state.reservationData.pickup_hour;
      this.droplocation = this.$store.state.reservationData.dropoff_location;
      this.dropdate = dropdate;
      this.drophour = this.$store.state.reservationData.dropoff_hour;
    }

    this.getBackground();
    this.getOptionsTime();
  },

  methods: {
    getBackground() {
      http
        .get(apiUrl + "v1")
        .then((data) => {
          if (data.data.status === true) {
            this.background = data.data.home_config.background_image;
            this.categories = data.data.categories;
          } else {
            this.errors = data.data.errors;
          }
        })
        .catch((err) => console.log(err));
    },
    getOptionsTime() {
      var currentTime = new Date().getHours();
      var currentMinutes = new Date().getMinutes();
      var minutes = parseInt("00");
      if (currentMinutes < 30) {
        minutes = 30;
      } else {
        currentTime = new Date().getHours() + 1;
      }
      var currentHours = ("0" + currentTime).substr(-2);

      if (this.pickdate <= new Date()) {
        this.timeOptions = {
          start: currentHours + ":" + minutes,
          end: "17:00",
          step: "00:30",
        };
      } else {
        this.timeOptions = {
          start: "09:00",
          end: "17:00",
          step: "00:30",
        };
      }
    },
    onChangePickDate() {
      this.pickhour = null;
      this.dropdate = null;
      this.drophour = null;

      var currentTime = new Date().getHours();
      var currentMinutes = new Date().getMinutes();
      var minutes = parseInt("00");
      if (currentMinutes < 30) {
        minutes = 30;
      } else {
        currentTime = new Date().getHours() + 1;
      }
      var currentHours = ("0" + currentTime).substr(-2);

      if (this.pickdate <= new Date()) {
        this.timeOptions = {
          start: currentHours + ":" + minutes,
          end: "17:00",
          step: "00:30",
        };
      } else {
        this.timeOptions = {
          start: "09:00",
          end: "17:00",
          step: "00:30",
        };
      }
    },
    getLocation() {
      http
        .get(apiUrl + "v1")
        .then((data) => {
          if (data.data.status === true) {
            this.locations = data.data.locations;
          } else {
            this.errors = data.data.errors;
          }
        })
        .catch((err) => console.log(err));
    },
    disabledBeforeToday(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      return date < today;
    },
    disabledBeforePickdate(date) {
      return date < this.pickdate;
    },
    disabledBeforeCurrentHour(date) {
      const today = new Date();
      const now = today.getHours();
      return date < new Date(new Date().setHours(now));
    },
    disabledAfterHours(date) {
      return date.getHours() < 9 || date.getHours() > 17;
    },
    findCar() {
      // alert('tes');
      const pickdate = new Date(this.pickdate);
      const dropdate = new Date(this.dropdate);
      const pickhour = this.pickhour.slice(0, 5);

      const pdate = pickdate.getDate();
      const pmonth = pickdate.getMonth() + 1;
      const pyear = pickdate.getFullYear();

      const parampickdate = pdate + "/" + pmonth + "/" + pyear;
      if (this.pickdate && this.pickhour) {
        const body = {
          selectedCategory: this.selectedCategory,
          pickup_location: this.picklocation,
          pickup_date: pickdate,
          pickup_hour: this.pickhour,
          dropoff_location: this.droplocation,
          dropoff_date: dropdate,
          dropoff_hour: this.drophour,
          selected_car: null,
        };
        this.$store.commit("setReservationData", body);
        this.$router.push({
          path: "/vehicles",
          query: { date: parampickdate, hour: pickhour },
        });
      } else {
        alert("Please complete form");
      }
    },
  },
};
</script>

<style>
.slide1 .title,
.slide1 .subtitle {
  color: #000 !important;
}

.container-search {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

@media only screen and (max-width: 767.98px) {
  .container-search {
    background-position: inherit !important;
  }
}

.form-search .form-control {
  font-size: 14px !important;
  color: #1c1c1c !important;
}

.form-search .form-select-control {
  font-size: 14px !important;
  background-color: #fff !important;
}
@media only screen and (max-width: 991.98px) {
  .form-search .row-inputs .container-fluid {
    display: flex;
    padding-right: 25px;
    padding-left: 25px;
    flex-direction: column;
  }
}

.form-search .row-submit .container-fluid > .inner {
  justify-content: flex-end;
}

.slide1 .form-search {
  background: #f1f1f190 !important ;
}

.form-search .form-select-control {
  font-size: 14px !important;
  background-color: #fff !important;
}

.form-search .form-title {
  background-color: #c1c1c190 !important;
}
.form-search .form-control {
  font-size: 14px !important;
  color: #1c1c1c;
}
@media only screen and (max-width: 600px) {
  .form-search .row-inputs .container-fluid {
    display: flex;
    padding-right: 25px;
    padding-left: 25px;
    flex-direction: column;
  }
}

.form-search .row-submit .container-fluid > .inner {
  justify-content: flex-end;
}
@media only screen and (max-width: 600px) {
  .section-reservation .sm-none {
    display: none;
  }
}

.section-reservation label {
  color: #14181c !important;
}

.section-reservation .form-control {
  background-color: #fff !important;
}
</style>