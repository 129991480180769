<template>
  <div>
    <VueperSlides autoplay :infinite="true">
      <VueperSlide v-for="(banner, i) in data" :key="i" :image="banner.image" />
    </VueperSlides>
  </div>
</template>

<script>
import { VueperSlides, VueperSlide } from "vueperslides";

export default {
  props: ['data'],
  components: {
    VueperSlides,
    VueperSlide,
  }
}
</script>

<style>
.vueperslide {
  background-size: cover;
  background-repeat: no-repeat;
}
</style>