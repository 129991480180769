<template>
  <section class="page-section find-car dark">
    <div class="container pt-5 pb-3">
      <form action="#" class="form-find-car">
        <div class="row">
          <div
            class="col-md-12 col-lg-3 wow fadeInDown"
            data-wow-offset="200"
            data-wow-delay="100ms"
          >
            <h2 class="section-title text-left no-margin">
              <small>Great Rental Cars</small>
              <span>Find your car</span>
            </h2>
          </div>
          <div
            class="col-md-6 col-lg-3 wow fadeInDown"
            data-wow-offset="200"
            data-wow-delay="300ms"
          >
            <div class="form-group has-icon has-label">
              <label for="formFindCarDate">Picking Up Date</label>
               <date-picker
                    v-model="findCarDate"
                    format="MM-DD-YYYY"
                    type="date"
                    placeholder="Picking Up Date"
                    :disabled-date="disabledBeforeToday"
                  ></date-picker>
                  <!-- <input type="date" v-model="findCarDate"> -->
            </div>
          </div>
          <div
            class="col-md-6 col-lg-2 wow fadeInDown"
            data-wow-offset="200"
            data-wow-delay="300ms"
          >
            <div class="form-group has-icon has-label">
              <label for="formFindCarDate">Picking Up Hour</label>
              <date-picker
                v-model="findCarHour"
                value-type="format"
                type="time"
                format="hh:mm a"
                placeholder="Picking Up Hour"
                :default-value="new Date().setHours(9, 0)"
                :time-picker-options="{
                      start: '09:00',
                      step: '00:30',
                      end: '17:00',
                    }"
              ></date-picker>
            </div>
          </div>
          <div
            class="col-md-6 col-lg-2 wow fadeInDown"
            data-wow-offset="200"
            data-wow-delay="400ms"
          >
            <div class="form-group has-icon has-label">
              <label for="formFindCarCategory">Price Category</label>
              <model-select
                :options="categories"
                v-model="findCarCategory"
                placeholder="Select Category"
                class="dark form-control price-category"
              >
              </model-select>
            </div>
          </div>
          <div
            class="col-md-6 col-lg-2 wow fadeInDown"
            data-wow-offset="200"
            data-wow-delay="500ms"
          >
            <div class="form-group">
              
                <button v-if="findCarDate && findCarHour && findCarCategory" @click="findYourCar"
                  class="btn btn-block btn-submit ripple-effect btn-theme"
                >
                  Find Car
                </button>
                <button v-else
                  class="btn btn-block btn-submit ripple-effect btn-theme disabled"
                >
                  Find Car
                </button>
                
            </div>
          </div>
        </div>
      </form>
    </div>
  </section>
</template>

<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import { ModelSelect } from "vue-search-select";
import "vue-search-select/dist/VueSearchSelect.css";
export default {
  components: { ModelSelect, DatePicker },
  props: ["categories"],
  data() {
    return {
      findCarDate: null,
      findCarHour: null,
      findCarCategory: null,
    };
  },
  methods: {
    disabledBeforeToday(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      return date < today;
    },

    disabledAfterHours(date) {
      return date.getHours() < 9 || date.getHours() > 16;
    },
    findYourCar() {
      const pickdate = new Date(this.findCarDate);
      
      const pdate = pickdate.getDate();
      const pmonth = pickdate.getMonth()+1;
      const pyear =  pickdate.getFullYear();

      const findCarDate  = pdate+'/'+pmonth+'/'+pyear;
      
      if (this.findCarDate && this.findCarHour && this.findCarCategory != 0) {
          const body = {
          pickup_location: null,
          pickup_date: findCarDate,
          pickup_hour: this.findCarHour,
          dropoff_location: null,
          dropoff_date: null,
          dropoff_hour: null,
          category_id: this.findCarCategory,
        };
        this.$store.commit("setReservationData", body);
        this.$router.push({
          path: "/vehicles",
          query: { pickdate: findCarDate, pickhour: this.findCarHour, categoryid:this.findCarCategory },
        });
      } else {
        this.$notify({
              type: "error",
              text: "Please complete form",
            });
        return;
      }
    },
  },
};
</script>

<style>
.form-find-car .ui.fluid.dropdown {
  background: rgba(255, 255, 255, 0.2);
  border: 1px solid rgba(255, 255, 255, 0);
  color: rgba(255, 255, 255, 0.6);
}

.form-find-car .form-control {
  font-size: 14px !important;
}
</style>