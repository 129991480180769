<template>
  <div class="content-area section-terms-conditions">
    <div id="preloader" v-if="loading">
      <div id="preloader-status">
        <div class="spinner">
          <div class="rect1"></div>
          <div class="rect2"></div>
          <div class="rect3"></div>
          <div class="rect4"></div>
          <div class="rect5"></div>
        </div>
        <div id="preloader-title">Loading</div>
      </div>
    </div>

    <section class="page-section breadcrumbs text-center">
      <div class="container">
        <div class="page-header">
          <h1>TERMS AND CONDITIONS</h1>
        </div>
        <ul class="breadcrumb">
          <li class="mx-2"><a href="#">Home</a></li>
          <i class="fas fa-angle-right"></i>
          <li class="mx-2"><a href="#">Pages</a></li>
          <i class="fas fa-angle-right"></i>
          <li class="active mx-2">TERMS AND CONDITIONS</li>
        </ul>
      </div>
    </section>

    <section class="page-section color">
      <div class="container">
        <div id="preloader" v-if="loading">
          <div id="preloader-status">
            <div class="spinner">
              <div class="rect1"></div>
              <div class="rect2"></div>
              <div class="rect3"></div>
              <div class="rect4"></div>
              <div class="rect5"></div>
            </div>
            <div id="preloader-title">Loading</div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div v-if="dataConfig">
              <p
                v-html="
                  dataConfig.terms_conditions ? dataConfig.terms_conditions : ''
                "
              ></p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <FooterInformation></FooterInformation>
  </div>
</template>

<script>
import http from "axios";
import { apiUrl } from "@/constants/config";
import FooterInformation from "@/components/FooterInformation";

export default {
  components: { FooterInformation },
  data() {
    return {
      messageSent: false,
      loading: true,
      dataConfig: [],
    };
  },
  created() {
    this.getDataConfig();
  },
  methods: {
    getDataConfig() {
      http
        .get(apiUrl + "v1")
        .then((data) => {
          if (data.data.status == true) {
            this.dataConfig = data.data.home_config;
            this.loading = false;
          }
        })
        .catch((err) => console.log(err));
    },
  },
};
</script>
<style>
.section-terms-conditions {
  font-size: 14px !important;
}
</style>
