<template>
  <v-modal name="detailorder" :height="'70%'" :width="'95%'" v-if="$store.state.orderDetail">
    <div class="row px-5 pt-5">
      <div class="col-md-12 d-flex justify-content-between align-item-center w-100">
        <h3 class="block-title pt-5"><span>Detail Order</span></h3>
      </div>
    </div>
    <div class="row px-5">
      <div class="col-md-6">
        <table class="table table-bordered">
          <tr>
            <td>Invoice</td>
            <td>{{ $store.state.orderDetail.invoice }}</td>
          </tr>
          <tr>
            <td>Vehicle</td>
            <td>{{$store.state.orderDetail.vehicle ? $store.state.orderDetail.vehicle.name : '' }}</td>
          </tr>
          <tr>
            <td>Duration</td>
            <td>{{ $store.state.orderDetail.days }} day(s) {{ getRentalDuration() }} hour(s)</td>
          </tr>
          <tr>
            <td>Price</td>
            <td>${{ $store.state.orderDetail.vehicle_price }}/day</td>
          </tr>
        </table>
      </div>
      <div class="col-md-6">
        <table class="table table-bordered">
          <tr>
            <td>Pickup Location</td>
            <td>{{ $store.state.orderDetail.pickup_location }}</td>
          </tr>
          <tr>
            <td>Drop Off Location</td>
            <td>{{ $store.state.orderDetail.dropoff_location }}</td>
          </tr>
          <tr>
            <td>Booking Time</td>
            <td>
              {{ new Date($store.state.orderDetail.booking_time).toLocaleDateString() }}
            </td>
          </tr>
          <tr>
            <td>Dropping Time</td>
            <td>
              {{ new Date($store.state.orderDetail.dropping_time).toLocaleDateString() }}
            </td>
          </tr>
        </table>
      </div>
    </div>
    <div class="row px-5">
      <div class="col-md-6">
        <h4 class="mt-3">Extras</h4>
        <table class="table table-bordered">
          <thead>
            <tr>
              <td>No</td>
              <td>Item</td>
              <td>Price</td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in extras" :key="index">
              <td>{{ parseInt(index + 1) }}</td>
              <td>{{ item.name }}</td>
              <td>${{ item.price }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="col-md-6">
        <h4 class="mt-3">Insurance</h4>
        <table class="table table-bordered">
          <thead>
            <tr>
              <td>No</td>
              <td>Item</td>
              <td>Price</td>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in insurance" :key="index">
              <td>{{ parseInt(index + 1) }}</td>
              <td>{{ item.name }}</td>
              <td>${{ item.price }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row px-5">
      <div class="col-md-12">
        <h4 class="mt-3">Cost</h4>
        <table class="table table-bordered">
          <tr>
            <td>Subtotal</td>
            <td>${{($store.state.orderDetail.vehicle_price*$store.state.orderDetail.days).toFixed(2)}}</td>
          </tr>
          <tr>
            <td>Extras and Insurance cost</td>
            <td>${{(parseFloat($store.state.orderDetail.total - ($store.state.orderDetail.vehicle_price*$store.state.orderDetail.days))).toFixed(2)}}</td>
          </tr>
          <tr>
            <td>GST inc. (10%)</td>
            <td>${{($store.state.orderDetail.total/11).toFixed(2)}}</td>
          </tr>
          <tr>
            <td>Total</td>
            <td>${{$store.state.orderDetail.total.toFixed(2)}}</td>
          </tr>
        </table>
      </div>
    </div>
  </v-modal>
</template>

<script>
export default {
  props: ["$store.state.orderDetail", "extras", "insurance"],
  data() {
    return {};
  },
  methods: {
    getRentalDuration() {
      if (this.$store.state.orderDetail.days == 0) {
        const h1 = this.$store.state.orderDetail.booking_time
        const h2 = this.$store.state.orderDetail.dropping_time
        var timeStart = new Date(h1);
        var timeEnd = new Date(h2);

        var diff = (timeEnd - timeStart) / 60000; //dividing by seconds and milliseconds

        var minutes = diff % 60;
        var hours = (diff - minutes) / 60;
        // this.rentalHourDuration = hours;

        return Math.abs(hours / 2);
      }
    }
  }
};
</script>

<style>
.vm--modal {
  overflow: auto;
}
</style>