<template>
  <div
    class="
      thumbnail
      no-border no-padding
      thumbnail-car-card
      clearfix
      d-flex
      justify-content-center
      vehicle-card
      mb-5
    "
  >
    <!-- <div class="media col-md-12 col-lg-4 bg-vehicle-image" v-bind:style="{ 'background-image': 'url(' + JSON.parse(vehicle.images)[0] + ')' }"> -->
    <div class="media col-md-12 col-lg-4 justify-content-center">
      <a
        class="media-link"
        data-gal="prettyPhoto"
        href="#"
        @click.prevent="selectImageVehicle(JSON.parse(vehicle.images)[0])"
      >
        <div style="display: flex; height: 220px">
          <img :src="JSON.parse(vehicle.images)[0]" alt="Vehicle Image" />
          <div
            v-if="vehicle.stock == 0"
            class="leased-out-badge leased-out-badge-sm"
          >
            Leased Out
          </div>
        </div>
        <!-- <img class="bg-vehicle-image" v-bind:style="{ 'background-image': 'url(' + JSON.parse(vehicle.images)[0] + ')' }"> -->
        <span class="icon-view my-auto"
          ><strong
            ><i
              class="fa fa-eye"
              style="position: relative; top: 33.3%"
            ></i></strong
        ></span>
      </a>
    </div>
    <div class="caption col-md-12 col-lg-8">
      <div class="wrapper">
        <div class="flex-row justify-content-between">
          <h4 class="caption-title">
            <a href="#">{{ vehicle.name }}</a>
            <span v-if="vehicle.stock == 0" class="leased-out-text"
              >Leased Out</span
            >
          </h4>
          <div class="rating">
            <star-rating
              :read-only="true"
              :show-rating="false"
              :star-size="16"
              v-model="rating"
            ></star-rating>
          </div>
        </div>
        <h5 class="caption-title-sub" v-if="!vehicle.hourly">Start from {{ minPrice }}$ / a day</h5>
        <h5 class="caption-title-sub" v-else>Start from {{ vehicle.hourly }}$ / 1 hour</h5>
        <hr />
        <!-- {{findhotdeals}} -->
        <!-- {{vehicle.price.map(item => item.hotdeals_price).find(item => item > 1)}} -->
        <div class="caption-text my-4">{{ vehicle.desc }}</div>
      </div>
      <table class="table">
        <tr class="">
          <td style="width: 15%">
            <i class="fa fa-car"></i> <br />{{ vehicle.reg_year }}
          </td>
          <td style="width: 25%">
            <i class="fas fa-tachometer-alt"></i><br />
            {{ vehicle.engine_type }}
          </td>
          <td style="width: 17%">
            <i class="fa fa-cog"></i><br />
            {{ vehicle.transmision }}
          </td>
          <td style="width: 17%">
            <i class="fa fa-road"></i><br />
            {{ vehicle.km }}
          </td>
          <td style="width: 26%; vertical-align: middle" class="buttons p-0">
            <div v-if="vehicle.stock == 1">
              <router-link
                v-if="reserv"
                :to="{ path: '/booking/' + vehicle.id }"
                class="
                  btn
                  btn-submit
                  btn-theme
                  btn-theme-dark
                  btn-block
                  btn-rentit-vehicle
                "
                style="color: white !important"
              >
                Rent It
              </router-link>
              <router-link
                v-else
                :to="{ path: '/reservation' }"
                class="
                  btn
                  btn-submit
                  btn-theme
                  btn-theme-dark
                  btn-block
                  btn-rentit-vehicle
                "
                style="color: white !important"
              >
                Rent It
              </router-link>
            </div>
            <div v-else>
              <button class="btn
                  btn-submit
                  btn-theme
                  btn-theme-dark
                  btn-block
                  btn-rentit-vehicle
                  disabled">Rent It</button>
            </div>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import StarRating from "vue-star-rating";
export default {
  props: ["vehicle", "findhotdeals"],
  components: { StarRating },
  data() {
    return {
      rating: 4,
      reserv: [],
    };
  },
  computed: {
    minPrice() {
      var price = Math.min.apply(
        Math,
        this.vehicle.price.map((x) =>
          x?.hotdeals_price ? x?.hotdeals_price : x?.price
        )
      );
      return price == "Infinity" ? 0 : price;
    },
  },
  created(){
    this.reserv = this.$store.state.reservationData;
    console.log(this.reserv.length);
  },
  methods: {
    selectImageVehicle(value) {
      this.$emit("clickimage", value);
    },
  },
};
</script>

<style scoped>
.flex-column {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.vehicle-card .media img {
  max-width: 220px;
  max-height: 250px;
  max-height: auto !important;
  display: block;
  width: 100vw;
  object-fit: cover;
}
.vehicle-card .media-link .icon-view {
  top: 30% !important;
}

.vehicle-card .caption {
  display: flex;
  flex-direction: column;
  align-self: flex-end;
}
.vehicle-card .thumbnail .caption {
  padding: 9px 0px 0px 0px;
}
.vehicle-card .table > tr > td,
.table > tr > th,
.table > tr > td,
.table > tr > th,
.table > thead > tr > td,
.table > thead > tr > th {
  line-height: 1.42857143;
  border-top: 1px solid #ddd;
  border-right: 1px solid #ddd;
  border-left: 1px solid #ddd;
  text-align: center;
}
.vehicle-card .btn-block {
  display: block !important;
  padding: 12px !important;
}
.vehicle-card .wrapper {
  padding: 0px 20px;
}

.vehicle-card .btn-rentit-vehicle {
  background-color: #000;
  line-height: 2;
}

.vehicle-card .btn-rentit-vehicle:hover {
  background-color: #f1be02;
  transition: 0.3s;
}

.vehicle-card .caption-text {
  font-size: 13px;
}

.vehicle-card .vue-star-rating-rating-text {
  display: none !important;
}

.vehicle-card .thumbnail-car-card .buttons .btn-theme {
  line-height: 1.8 !important;
}
</style>