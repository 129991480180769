<template>
  <div class="register-modal">
    <v-modal name="register">
      <div class="row row-modal">
        <div class="col-md-6">
          <h3 class="block-title pt-5"><span>Create New Account</span></h3>
          <form action="#" class="form-login">
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <input
                    class="form-control"
                    type="text"
                    placeholder="Your name"
                    v-model="name"
                    :class="errors.name && 'border-danger'"
                  />
                </div>
                <small class="errors-label" v-if="errors.name">{{
                  errors.name[0]
                }}</small>
              </div>
              <div class="col-md-12">
                <div class="form-group">
                  <input
                    class="form-control"
                    type="tel"
                    placeholder="Your phone"
                    v-model="phone"
                    :class="errors.phone && 'border-danger'"
                  />
                </div>
                <p>Use country code. ex: 61*** , 62***</p>
                <small class="errors-label" v-if="errors.phone">{{
                  errors.phone[0]
                }}</small>
              </div>
              <div class="col-md-12">
                <div class="form-group">
                  <input
                    class="form-control"
                    type="text"
                    placeholder="Your date of birth"
                    v-model="date_of_birth"
                    onfocus="(this.type='date')"
                    :max="minDate"
                    :class="errors.date_of_birth && 'border-danger'"
                  />
                </div>
                <small class="errors-label" v-if="errors.date_of_birth">{{
                  errors.date_of_birth[0]
                }}</small>
              </div>
              <div class="col-md-12">
                <div class="form-group">
                  <input
                    class="form-control"
                    type="email"
                    placeholder="Your email"
                    v-model="email"
                    :class="errors.email && 'border-danger'"
                  />
                </div>
                <small class="errors-label" v-if="errors.email">{{
                  errors.email[0]
                }}</small>
              </div>
              <div class="col-md-12">
                <div class="form-group">
                  <input
                    class="form-control"
                    type="password"
                    placeholder="Your password"
                    v-model="password"
                    :class="errors.password && 'border-danger'"
                  />
                </div>
                <small class="errors-label" v-if="errors.password">{{
                  errors.password[0]
                }}</small>
              </div>
              <div class="col-md-12">
                <div class="form-group">
                  <input
                    class="form-control"
                    type="password"
                    placeholder="Confirm your password"
                    v-model="password_confirmation"
                    :class="errors.password_confirmation && 'border-danger'"
                  />
                </div>
                <small
                  class="errors-label"
                  v-if="errors.password_confirmation"
                  >{{ errors.password_confirmation[0] }}</small
                >
              </div>
              <div class="col-md-12 pull-right">
                <a
                  class="btn btn-theme btn-block btn-theme-dark"
                  v-on:click="register()"
                  >Create Account</a
                >
              </div>
            </div>
          </form>
        </div>
        <div class="col-md-6">
          <form action="#" class="create-account">
            <div class="row pt-5">
              <div class="col-md-12 hello-text-wrap">
                <span class="hello-text text-thin d-sm-none d-md-block"
                  >Create Your Account</span
                >
              </div>
              <div class="col-md-12">
                <h3 class="block-title">
                  Be the first to get our exclusive offers and latest news
                </h3>
                <label
                  class="container-checkbox"
                  style="color: #000 !important"
                >
                  Subscribe to our newsletter

                  <input
                    id="subscribe_checkbox"
                    type="checkbox"
                    v-model="subscribeCheck"
                  />
                  <span class="mark-checkbox"></span>
                </label>
              </div>
            </div>
          </form>
        </div>
      </div>
    </v-modal>
  </div>
</template>

<script>
import { apiUrl } from "@/constants/config.js";
import axios from "axios";
export default {
  data() {
    return {
      errors: [],
      name: null,
      phone: null,
      date_of_birth: null,
      email: null,
      password: null,
      password_confirmation: null,
      subscribeCheck: false,
      minDate: new Date(new Date().setFullYear(new Date().getFullYear() - 18)).toLocaleDateString('en-CA'),
    };
  },
  methods: {
    register() {
      const body = {
        name: this.name,
        phone: this.phone,
        date_of_birth: this.date_of_birth,
        email: this.email,
        password: this.password,
        password_confirmation: this.password_confirmation,
      };

      axios
        .post(apiUrl + "v1/auth/register", body)
        .then((res) => {
          if (res.data.status) {
            this.$store.commit("setLogged", true);
            this.$store.commit("setToken", res.data.token);
            this.$store.commit("setUserData", res.data.customer);

            if (this.subscribeCheck == true) {
              this.subscribe();
            }

            this.$router.push("/");
          } else if (!res.data.status) {
            this.errors = res.data.errors;
          }
        })
        .catch((err) => console.log(err));
    },

    subscribe() {
      const body = {
        email: this.email,
      };
      axios
        .post(apiUrl + "v1/subscribe", body)
        .then((data) => {
          console.log(data.data.status);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style>
.register-modal .vm--modal {
  overflow: auto;
  top: 8% !important;
  left: 8% !important;
  height: 85% !important;
  width: 85% !important;
  padding: 40px;
}

@media only screen and (max-width: 600px) {
  .register-modal .vm--modal {
    top: 0 !important;
    left: 0 !important;
    width: 100% !important;
    height: 100% !important;
  }
}
</style>