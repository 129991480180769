var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"content-area content my-order"},[(_vm.loading)?_c('div',{attrs:{"id":"preloader"}},[_vm._m(0)]):_vm._e(),_vm._m(1),_c('section',{staticClass:"page-section with-sidebar sub-page"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"col-md-12 content",attrs:{"id":"content"}},[_c('table',{staticClass:"table table-bordered table-hover table-responsive-md"},[_vm._m(2),_c('tbody',[_vm._l((_vm.orders),function(item){return _vm._l((item.payment_sequence.length),function(i){return _c('tr',{key:item.invoice + i},[_c('td',{attrs:{"id":'invoice' + i}},[_vm._v(_vm._s(item.invoice))]),_c('td',[_vm._v(_vm._s(item.vehicle ? item.vehicle.name : ""))]),_c('td',[_vm._v(" "+_vm._s(new Date(item.booking_time).toLocaleDateString())+" ")]),_c('td',[_vm._v(" "+_vm._s(new Date(item.dropping_time).toLocaleDateString())+" ")]),_c('td',[_vm._v("$"+_vm._s(item.charge_period_amount))]),_c('td',[(
                        item.payment[i - 1] &&
                        item.payment[i - 1].is_paid === 1
                      )?_c('b-badge',{attrs:{"variant":"success"}},[_vm._v("Paid")]):_c('span',{staticClass:"text-danger"},[_vm._v(" Unpaid ")])],1),_c('td',[(
                        item.payment[i - 1] &&
                        item.payment[i - 1].is_paid === 1
                      )?_c('a',{attrs:{"href":item.payment[i - 1].receipt_url,"target":"_blank"}},[_c('b-badge',{attrs:{"variant":"info"}},[_vm._v("Receipt")])],1):_c('b-button',{staticClass:"btn btn-success btn-detail",staticStyle:{"font-size":"9.5pt !important"},on:{"click":function($event){return _vm.payNow(item)}}},[_vm._v("Pay Now")])],1),_c('td',[_c('b-button',{staticClass:"btn btn-success btn-detail",staticStyle:{"font-size":"10pt !important"},on:{"click":function($event){return _vm.showDetailOrder(item)}}},[_vm._v(" Detail ")])],1)])})})],2)])]),_c('DetailOrderModal',{attrs:{"detailorder":_vm.detailOrder,"extras":_vm.extras,"insurance":_vm.insurance}})],1)]),_c('ContactForm'),(_vm.loading)?_c('div',{attrs:{"id":"preloader"}},[_vm._m(3)]):_vm._e(),_c('div',{staticClass:"paynow-modal"},[(_vm.$store.state.orderToBePaid)?_c('v-modal',{attrs:{"name":"paynowmodal"}},[_c('div',{staticClass:"paynowmodal-content"},[_c('div',{staticClass:"w-100"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"form-group my-2"},[_c('label',{attrs:{"for":"rego_no"}},[_vm._v(" Rego No ")]),_c('input',{staticClass:"form-control my-2",attrs:{"readonly":""},domProps:{"value":_vm.$store.state.orderToBePaid.vehicle.name}})])]),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"form-group my-2"},[_c('label',{attrs:{"for":"rego_no"}},[_vm._v(" Booking Date ")]),_c('input',{staticClass:"form-control my-2",attrs:{"readonly":""},domProps:{"value":new Date(
                        _vm.$store.state.orderToBePaid.booking_time
                      ).toLocaleDateString()}})])]),_c('div',{staticClass:"col-md-4"},[_c('div',{staticClass:"form-group my-2"},[_c('label',{attrs:{"for":"amount"}},[_vm._v(" Amount ")]),_c('input',{staticClass:"form-control my-2",attrs:{"readonly":""},domProps:{"value":'$' + _vm.$store.state.orderToBePaid.charge_period_amount}})])]),_c('div',{staticClass:"col-md-12"},[_c('div',{staticClass:"form-group my-2"},[_c('label',{attrs:{"for":"amount"}},[_vm._v("Credit Card Information ")]),_c('stripe-element-card',{ref:"elementRef",staticClass:"mt-2",attrs:{"pk":_vm.publishableKey},on:{"token":_vm.tokenCreated}})],1)])]),_c('button',{staticClass:"btn btn-sm-cus mt-4 float-right",on:{"click":_vm.submit}},[_vm._v(" Pay now ")])])])]):_vm._e()],1)],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"preloader-status"}},[_c('div',{staticClass:"spinner"},[_c('div',{staticClass:"rect1"}),_c('div',{staticClass:"rect2"}),_c('div',{staticClass:"rect3"}),_c('div',{staticClass:"rect4"}),_c('div',{staticClass:"rect5"})]),_c('div',{attrs:{"id":"preloader-title"}},[_vm._v("Loading")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"page-section breadcrumbs text-center"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"page-header"},[_c('h1',[_vm._v("List Order")])]),_c('ul',{staticClass:"breadcrumb"},[_c('li',[_c('a',{attrs:{"href":"#"}},[_vm._v("Home")])]),_c('i',{staticClass:"fas fa-angle-right mx-2"}),_c('li',[_c('a',{attrs:{"href":"#"}},[_vm._v("Pages")])]),_c('i',{staticClass:"fas fa-angle-right mx-2"}),_c('li',{staticClass:"active"},[_vm._v("List Order")])])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('td',[_vm._v("Invoice Number")]),_c('td',[_vm._v("Vehicle")]),_c('td',[_vm._v("Booking Time")]),_c('td',[_vm._v("Dropping Time")]),_c('td',[_vm._v("Amount")]),_c('td',[_vm._v("Status")]),_c('td',[_vm._v("Payment")]),_c('td')])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"preloader-status"}},[_c('div',{staticClass:"spinner"},[_c('div',{staticClass:"rect1"}),_c('div',{staticClass:"rect2"}),_c('div',{staticClass:"rect3"}),_c('div',{staticClass:"rect4"}),_c('div',{staticClass:"rect5"})]),_c('div',{attrs:{"id":"preloader-title"}},[_vm._v("Loading")])])}]

export { render, staticRenderFns }