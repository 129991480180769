<template>
  <div>
    <section class="page-section dark section-wwa">
      <div class="container">
        <div class="row">
          <div
            class="col-md-6 wow fadeInLeft text-side"
            data-wow-offset="200"
            data-wow-delay="100ms"
          >
            <h4 class="section-title text-left">
              {{title}}
            </h4>
            <p></p>
            <ul class="list-icons">
              <div class="wwa-description">
                {{description}}
              </div>
            </ul>
            <p class="btn-row">
              <router-link
                to="/vehicles"
                class="btn btn-theme ripple-effect btn-theme-md btn-vehicle"
                >See All Vehicles</router-link
              >
              <router-link
                to="/reservation"
                class="
                  btn btn-theme
                  ripple-effect
                  btn-theme-md btn-theme-transparent btn-reservation
                "
                >Reservation Now</router-link
              >
            </p>
          </div>
          <div
            class="col-md-6 wow fadeInRight"
            data-wow-offset="200"
            data-wow-delay="300ms"
          >
            <vueper-slides
              fade
              :touchable="false"
              fixed-height="370px"
              :breakpoints="breakpoints_wwa"
            >
              <vueper-slide
                v-for="(img, index) in images"
                :key="index"
                :image="img"
              />
            </vueper-slides>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";
export default {
  props: ["title", "description", "images"],
  components: { VueperSlides, VueperSlide },
  data() {
    return {
      breakpoints_wwa: {
        600: {
          arrows: false,
          bullets: false,
        },
      },
    };
  },
};
</script>
<style>
@media only screen and (max-width: 677.98px) {
  .section-wwa {
    margin-top: 20px;
  }
}

.section-wwa .wwa-description{
  text-align: justify; 
  text-justify: inter-word;
  font-size: 20px;
  line-height: 26px;
}

.section-wwa .text-side {
  font-size: 15px;
  line-height: 1.6;
}

.section-wwa .list-icons {
  padding: 0 !important;
}

.section-wwa .list-icons i,
.section-wwa .list-icons svg {
  margin-right: 14px !important;
}

.section-wwa .btn-row {
  margin-top: 50px;
  display: flex;
}

.section-wwa .btn {
  font-size: 14px;
  padding: 17px 25px !important;
}

.section-wwa .btn-vehicle {
  background-color: #f1be02;
  color: #fff;
  margin-left: 0 !important;
}
.section-wwa .btn-reservation {
  margin-left: 20px;
  border: 1px solid #fff;
}

.section-wwa .vueperslides__arrow {
  border: 3px solid #000;
  color: #000 !important;
}

.section-wwa .vueperslides__arrow:hover {
  border: 2px solid #000;
  color: #fff !important;
  background-color: #000 !important;
}
.section-wwa .vueperslides__arrow--prev {
  left: 2.5em !important;
}

.section-wwa .vueperslides__arrow--next {
  right: 2.5em !important;
}

.section-wwa .vueperslides__arrow svg {
  width: 30px;
}
</style>