<template>
  <div>
    <div class="content-area content my-car-history">
      <div id="preloader" v-if="loading">
            <div id="preloader-status">
              <div class="spinner">
                <div class="rect1"></div>
                <div class="rect2"></div>
                <div class="rect3"></div>
                <div class="rect4"></div>
                <div class="rect5"></div>
              </div>
              <div id="preloader-title">Loading</div>
            </div>
          </div>
      <!-- BREADCRUMBS -->
      <section class="page-section breadcrumbs text-center ">
        <div class="container">
          <div class="page-header">
            <h1>Car History</h1>
          </div>
          <ul class="breadcrumb">
            <li><a href="#">Home</a></li>
            <i class="fas fa-angle-right mx-2"></i>
            <li><a href="#">Pages</a></li>
            <i class="fas fa-angle-right mx-2"></i>
            <li class="active">Car History</li>
          </ul>
        </div>
      </section>
      <!-- /BREADCRUMBS -->

      <!-- PAGE WITH SIDEBAR -->
      <section class="page-section with-sidebar sub-page">
        <div class="container">
            <div class="col-md-12 content" id="content">
                <table class="table table-bordered table-hover table-responsive-md">
                    <thead>
                        <tr>
                            <td>Rego No.</td>
                            <td>Invoice Number</td>
                            <td>Title</td>
                            <td>Description</td>
                            <td>Date</td>
                            <td>Amount of Fine</td>
                            <td>Status</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(item, index) in fines" :key="index">
                            <td>{{item.order.vehicle ? item.order.vehicle.name : ''}}</td>
                            <td>{{item.order.invoice}}</td>
                            <td>{{item.title}}</td>
                            <td>{{item.desc}}</td>
                            <td>{{new Date(item.date).toLocaleDateString()}}</td>
                            <td>${{item.amount}}</td>
                            <td>
                                <b-badge v-if="item.status === 'unpaid'" variant="danger">Unpaid</b-badge>
                                <b-badge v-if="item.status === 'paid'" variant="success">Paid</b-badge>
                            </td>
                        </tr>
                    </tbody>
                </table>
          </div>
        </div>
      </section>
      <!-- /PAGE WITH SIDEBAR -->

     <ContactForm></ContactForm>
    </div>
  </div>
</template>
<script>
import http from "axios";
import { apiUrl } from "@/constants/config";
import ContactForm from "@/components/ContactForm.vue";

export default {
  name: "CarHistory",
  components: {ContactForm},
  data() {
    return {
      customerId : this.$store.state.userData.id,
      fines : [],
      loading: false,
    };
  },
  created() {
    if(this.$store.state.userData){
      this.getCarHistory();
    } else {
      this.$router.push('/login');
    }
  },  
  methods: {
    getCarHistory(){
      this.loading = true;
      http 
          .get(apiUrl+"v1/orders/fines/"+this.customerId)
          .then((data) =>{
            this.loading = false
            this.fines = data.data.fines;
          })
          .catch((err) => console.log(err));
    }
  },
};
</script>
<style>
.my-car-history{
  font-size: 14px !important;
}
</style>