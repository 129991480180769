<template>
  <div class="image-modal">
    <v-modal name="imagemodal" v-if="selectedimage">
      <div style="padding: 15px 10px 15px 25px">
        <a style="float: right" class="ml-5" @click="closeModal">
          <i class="fa fa-2x fa-times" style="cursor: pointer"></i>
        </a>
        <div class="d-flex justify-content-center align-items-center ml-4">
          <img :src="selectedimage" class="selected-image" />
        </div>
      </div>
    </v-modal>
  </div>
</template>

<script>
export default {
  props: ["selectedimage"],
  methods: {
    closeModal() {
      this.$modal.hide("imagemodal");
    },
  },
};
</script>

<style>
.image-modal .vm--modal {
  overflow: auto;
  display: inline-table;
  padding: 5px 0px 5px 5px;
  background-color: #ffffff;
  width: auto !important;
  height: auto !important;
  position: absolute;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%);
}

.image-modal img {
  height: 450px;
  width: auto;
}

.image-modal .btn-close {
  float: right;
  background-color: #a3a3a3;
  border-radius: 40% !important;
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  padding: 2px 20px !important;
}

@media only screen and (max-width: 600px) {
  .image-modal .vm--modal {
    width: 100% !important;
    height: 100% !important;
  }

  .image-modal img {
    height: auto;
    width: 100%;
  }
}
</style>