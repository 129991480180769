<template>
  <div class="container mt-4">
    <stripe-element-card
      ref="elementRef"
      :pk="publishableKey"
      @token="tokenCreated"
    />
    <button class="btn btn-sm" @click.prevent="submit">Generate token</button>
  </div>
</template>

<script>
import { StripeElementCard } from "@vue-stripe/vue-stripe";
export default {
  components: {
    StripeElementCard,
  },
  data() {
    return {
      publishableKey: "pk_test_DfuJNEtCXS9lkzvU3kPYRXkf",
      token: null,
    };
  },
  methods: {
    submit() {
      // this will trigger the process
      this.$refs.elementRef.submit();
    },
    tokenCreated(token) {
      console.log(token.id);
      // handle the token
      // send it to your server
    },
  },
};
</script>