<template>
  <div>
    <aside class="sidebar sidebar-vehicle" id="sidebar">
      <!-- widget -->
      <div v-if="showfindcar" class="widget shadow widget-find-car">
        <h4 class="widget-title">Find Best Rental Car</h4>
        <div class="widget-content">
          <!-- Search form -->
          <div class="form-search light">
            <form action="#">
              <div class="form-group has-icon has-label">
                <label for="formSearchUpLocation3">Picking Up Location</label>
               <select class="form-select-control w-100" v-model="pickup_location">
                    <option value=null selected>Select Picking Up Location</option>
                    <option v-for="item in locations" :key="item.id" :value="item.name">
                      {{item.name}}
                    </option>
                  </select>
              </div>
              <div class="form-group has-icon has-label">
                <label for="formSearchOffLocation3"
                  >Dropping Off Location</label
                >
                <select class="form-select-control w-100" v-model="dropoff_location">
                    <option value=null selected>Select Dropping Off Location</option>
                    <option v-for="item in locations" :key="item.id" :value="item.name">
                      {{item.name}}
                    </option>
                  </select>

              </div>
              <div class="form-group has-icon has-label">
                <label for="formSearchUpDate3">Pick Up Date</label>
                <date-picker
                    v-model="pickup_date"
                    format="DD-MM-YYYY"
                    type="date"
                    placeholder="Select Pick Up Date"
                    :disabled-date="disabledBeforeToday"
                  ></date-picker>
              </div>
              <div class="form-group has-icon has-label selectpicker-wrapper">
                <label>Picking Up Hour</label>
                <date-picker
                    v-model="pickup_hour"
                    value-type="format"
                    type="time"
                    format="hh:mm a"
                    placeholder="Select Picking Up Hour"
                    :default-value="new Date().setHours(9, 0)"
                    :time-picker-options="{
                      start: '09:00',
                      step: '00:30',
                      end: '17:00',
                    }"
                  ></date-picker>
              </div>
              <div class="form-group selectpicker-wrapper">
                <label>Select Category</label>
               <model-select
                  :options="categories"
                  v-model="category"
                  placeholder="Select Category"
                >
                </model-select>
              </div>
              <div class="form-group selectpicker-wrapper">
                <label>Select Type</label>
                <model-select
                  :options="brands"
                  v-model="brand"
                  placeholder="Select Type"
                >
                </model-select>
              </div>
              <button
                type="submit"
                id="formSearchSubmit3"
                class="btn btn-submit btn-theme btn-theme-dark btn-block"
                v-on:click.prevent="findCar([pickup_date,pickup_hour,category,brand,pickup_location,dropoff_location,dropoff_date,dropoff_hour])"
              >
                Find Car
              </button>
            </form>
          </div>
          <!-- /Search form -->
        </div>
      </div>
      <!-- /widget -->
      <!-- widget price filter -->
      <div v-if="showfilterprice" class="widget shadow widget-filter-price">
        <h4 class="widget-title">Price</h4>
        <div class="widget-content">
          <Slider
            v-model="sliderPrice"
            :range="true"
            :max="500"
            class="slider-red"
            :tooltips="false"
          />
          <div class="d-flex justify-content-between mt-5">
            <button
              v-on:click.prevent="filterPrice(sliderPrice)"
              class="btn btn-theme btn-theme-dark btn-filter"
            >
              Filter
            </button>
            <div class="text-price">
              ${{ sliderPrice[0] }} - ${{ sliderPrice[1] }}
            </div>
          </div>
        </div>
        <!-- object value -->
      </div>
      <!-- /widget price filter -->
      <!-- widget testimonials -->
      <div class="widget shadow">
        <div class="widget-title">Testimonials</div>
        <vueper-slides :arrows="false" fixed-height="250px" class="no-shadow">
          <vueper-slide
            v-for="item in testimonies"
            :key="item.id">
            <template #content>
            <div class="carousel-cell">
            <div class="testimonials-carousel">
              <div class="testimonial">
                <div class="media">
                  <div class="media-body">
                    <div class="testimonial-text">
                     {{item.testimonies ? item.testimonies : ''}}
                    </div>
                    <div class="testimonial-name">
                      {{item.name ? item.name : ''}}
                      <span class="testimonial-position"
                        >{{item.job_title ? item.job_title : ''}}</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
            </template>
          </vueper-slide>
        </vueper-slides>
      </div>
      <div class="widget shadow widget-helping-center">
        <h4 class="widget-title">Helping Center</h4>
        <div class="widget-content">
          <p>
            {{homeconfig ? homeconfig.help_center_text : ''}}
          </p>
          <h5 class="widget-title-sub">+{{homeconfig ? homeconfig.contact_text : ''}}</h5>
          <p v-if="homeconfig">
            <a :href="'mailto:'+homeconfig.email"
              >{{homeconfig.email}}</a
            >
          </p>
          <div class="button">
            <router-link to="/contact" class="btn btn-block btn-theme btn-theme-dark btn-20"
              >Support Center</router-link
            >
          </div>
        </div>
      </div>
    </aside>
  </div>
</template>

<script>
import http from "axios";
import { apiUrl } from "@/constants/config";
import Slider from "@vueform/slider/dist/slider.vue2.js";
import { ModelSelect } from "vue-search-select";
import "vue-search-select/dist/VueSearchSelect.css";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";

export default {
  props: ["locations","categories", "brands", "showfindcar", "showfilterprice","homeconfig"],
  components: {
    Slider,
    ModelSelect,
    VueperSlides,
    VueperSlide,
    DatePicker
  },
  data() {
    return {
      flickityOptions: {
        initialIndex: 1,
        percentPosition: false,
      },

      selectedCategory: "",
      pickup_location: "",
      pickup_date: "",
      pickup_hour: "",
      dropoff_location: "",
      dropoff_date: "",
      dropoff_hour: "",
      category: null,
      brand: '',
      minPrice: 0,
      maxPrice: 500,
      sliderPrice: [0, 500],

      testimonies:{},
    };
  },
  created(){
    if(this.$store.state.reservationData){
      var pickdate = "";
      var dropdate = "";

      if(new Date(this.$store.state.reservationData.pickup_date) < new Date()){
        pickdate = new Date();
      } else {
        pickdate = new Date(this.$store.state.reservationData.pickup_date);
      }

      // if(new Date(this.$store.state.reservationData.dropoff_date) < new Date()){
      //   dropdate = new Date(Date.now() + ( 3600 * 1000 * 24));
      // } else {
        dropdate = new Date(this.$store.state.reservationData.dropoff_date);
      // }
      this.pickup_date = pickdate;
      this.pickup_hour = this.$store.state.reservationData.pickup_hour;
      this.category = this.$store.state.reservationData.selectedCategory;
      this.pickup_location = this.$store.state.reservationData.pickup_location;
      this.dropoff_location = this.$store.state.reservationData.dropoff_location;
      this.dropoff_date = dropdate;
      this.dropoff_hour = this.$store.state.reservationData.dropoff_hour;
      // this.category = this.$store.state.reservationData.category_id;
    }
    console.log(this.brands)

    this.getTestimony();
    this.findCar();
  },
  methods: {
    getTestimony(){
      http
          .get(apiUrl +'v1/testimony')
          .then((data) =>{
            this.testimonies = data.data.testimonies.slice(0,3);
            console.log(this.testimonies);
          })
          .catch((err) => {
            console.log(err);
          })
    },
    disabledBeforeToday(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      return date < today;
    },
    disabledAfterHours(date) {
      return date.getHours() < 9 || date.getHours() > 17;
    },
    filterPrice(value) {
        this.$emit("clickfilter", value);
    },
    findCar(value){
     if(value[0] && value[1]){

        this.$emit("clickfind", value);
      } else {
        alert('Please complete search form');
      }
    },
  },
};
</script>

<style src="@vueform/slider/themes/default.css"></style>
<style>
.slider-red {
  --slider-connect-bg: #f1be02;
  --slider-tooltip-bg: #f1be02;
  --slider-handle-ring-color: #e2b202;
  margin: 10px 5px;
}

.sidebar .text-price {
  font-size: 15px;
  font-weight: 700;
  color: #7f7f7f;
  width: 120px;
  line-height: 33px;
  text-align: right;
}

.sidebar .btn-filter{
  padding: 13px 20px !important;
  background-color: #000;
  color:#fff;
}
.sidebar .btn-filter:hover{
  background-color: #f1be02;
}

.widget-find-car .form-control{
  color: #949494 !important;
  font-size: 14px !important;
}

.widget-helping-center{
  font-size: 15px !important;
}

.widget-find-car .form-select-control{
  border: 1px solid #d3d3d3 !important;
  background-color: #fff !important;
}
.vueperslides__bullet .default {
  background-color: #0000004d;
  border: none;
  box-shadow: none;
  transition: 0.3s;
  width: 16px;
  height: 16px;
  margin-bottom: 2px;
}
.sidebar-vehicle .vueperslides__bullet--active .default{background-color: #f1be02;}
</style>