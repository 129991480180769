<template>
  <div class="footer">
    <div class="footer-widgets">
        <div class="container">
            <div class="row">
                <div class="col-md-3">
                    <div class="widget">
                        <h4 class="widget-title">About Us</h4>
                        <p>
                            {{aboutUs}}
                        </p>
                        <ul class="social-icons">
                            <li><a :href="this.$store.state.config.config.facebook" target="_blank" class="facebook"><i class="fab fa-facebook-f"></i></a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="widget">
                        <h4 class="widget-title">News Letter</h4>
                            <div class="form-group">
                                <input v-model="emailSubscribeFooter" class="form-control h50-form-control" type="text" placeholder="Enter Your Mail"/>
                                <h6 v-if="errors.email" class="text-danger mt-3">
                                   {{errors.email[0]}}
                                </h6>
                                <h6 v-if="successSubscribe" class="text-primary mt-3">
                                    Thank you for subscribing!
                                </h6>
                            </div>
                            <div class="form-group">
                                <button @click.prevent="subscribe" class="btn btn-theme btn-theme-transparent btn-border-theme">Subscribe</button>
                            </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="widget widget-categories">
                        <h4 class="widget-title">Information</h4>
                        <ul>
                            <li>
                                <router-link to="about-us">
                                <a><i class="mr-2 fas fa-arrow-circle-right"></i>About Us</a>
                                </router-link>
                            </li>
                            <li>
                                <router-link to="delivery-information">
                                <a><i class="mr-2 fas fa-arrow-circle-right"></i>Delivery Information</a>
                                </router-link>
                            </li>
                            <li>
                                <router-link to="contact">
                                <a><i class="mr-2 fas fa-arrow-circle-right"></i>Contact Us</a>
                                </router-link>
                            </li>
                            <li>
                                <router-link to="terms-conditions">
                                <a><i class="mr-2 fas fa-arrow-circle-right"></i>Terms and Conditions</a>
                                </router-link>
                            </li>
                            <li>
                                <router-link to="privacy-policy">
                                <a><i class="mr-2 fas fa-arrow-circle-right"></i>Privacy Policy</a>
                                </router-link>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="widget widget-tag-cloud">
                        <h4 class="widget-title">Item Tags</h4>
                        <ul>
                            <li v-for="tag in tags" :key="tag">
                                <a href="#">{{tag}}</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import http from "axios";
import { apiUrl } from "@/constants/config";

export default {
    data(){
        return{
            tags: {},
            aboutUs: null,
            successSubscribe: false,
            emailSubscribeFooter : "",
            errors: [],
        }
    },
    created(){
        this.getData();
    },
    methods:{
        getData(){
            http
                .get(apiUrl+'v1')
                .then((data) => {
                    this.tags = JSON.parse(data.data.home_config.tags);
                    this.aboutUs = data.data.config.contact_text;
                    console.log(this.aboutUs);
                })
                .catch();
        },

        subscribe(){
            const body = {
                email : this.emailSubscribeFooter,
            }
            http.post(apiUrl+'v1/subscribe',body).then((data) => {
                if(data.data.status == true){
                    this.successSubscribe = true;
                    this.emailSubscribeFooter = '';
                    this.$notify({
                        type: "success",
                        text: "Thank you for subscribing",
                        duration: 5000,
                    });
                } else {
                    this.errors = data.data.errors;
                }
            })
            .catch()
        }
    },

}
</script>

<style>
.footer{
    font-size: 15px;
}
</style>