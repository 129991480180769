<template>
  <div>
    <div id="preloader" v-if="loading">
      <div id="preloader-status">
        <div class="spinner">
          <div class="rect1"></div>
          <div class="rect2"></div>
          <div class="rect3"></div>
          <div class="rect4"></div>
          <div class="rect5"></div>
        </div>
        <div id="preloader-title">Loading</div>
      </div>
    </div>

    <section
      class="page-section breadcrumbs text-center"
      style="z-index: 0 !important"

    >
      <div class="container">
        <div class="page-header">
          <h1>Car Listing</h1>
        </div>
        <ul class="breadcrumb justify-content-center">
          <li class="mx-2"><a href="#">Home</a></li>
          <i class="fas fa-angle-right"></i>
          <li class="mx-2"><a href="#">Pages</a></li>
          <i class="fas fa-angle-right"></i>
          <li class="active mx-2">List Car</li>
        </ul>
      </div>
    </section>
    <div class="page-section">
      <div class="container mt-5">
        <div class="row">
          <div class="col-md-8">
            <VehicleCard
              v-for="vehicle in vehicles"
              :key="vehicle.id"
              :vehicle="vehicle"
              @clickimage="selectImage"
            ></VehicleCard>
          </div>
          <div class="col-md-4">
            <VehicleSidebar
              :locations="locations"
              :categories="categories"
              :brands="brands"
              :showfindcar="showfindcar"
              :showfilterprice="showfilterprice"
              @clickfilter="filterPrice"
              @clickfind="findCar"
              :errors="errors"
              :homeconfig="homeConfig"
            ></VehicleSidebar>
          </div>
        </div>
        <ImageModalVehicle
          :selectedimage="selectedImageVehicle"
        ></ImageModalVehicle>
      </div>
    </div>
  </div>
</template>

<script>
import VehicleCard from "@/components/VehicleCard";
import http from "axios";
import { apiUrl } from "@/constants/config";
import VehicleSidebar from "@/components/VehicleSidebar";
import ImageModalVehicle from "@/components/ImageModalVehicle";

export default {
  components: { VehicleCard, VehicleSidebar, ImageModalVehicle },
  data() {
    return {
      vehicles: [],
      vehicleLoaded: false,
      selectedImageVehicle: "",
      categories: [],
      brands: [],
      locations: [],
      showfindcar: true,
      showfilterprice: true,
      filterpickdate: this.$route.params.pickdate,
      filterpickhour: this.$route.params.pickhour,
      errors: [],
      homeConfig: [],
      loading: true,
    };
  },
  created() {
    if (this.$store.state.showSideMenu == true) {
      this.$store.state.showSideMenu = !this.$store.state.showSideMenu;
    }

    http
      .get(apiUrl + "v1/vehicles/hotdeals")
      .then((data) => {
        this.vehicles = data.data.vehicles.filter(
          (item) => item.price.length > 0
        );
        this.categories = data.data.categories.map((item) => {
          const categories = {};

          categories.value = item.id;
          categories.text = item.name;
          return categories;
        });
        this.categories.unshift({ value: "", text: "All Category" });

        this.brands = data.data.brands.map((item) => {
          const brands = {};

          brands.value = item.id;
          brands.text = item.name;
          return brands;
        });
        this.brands.unshift({ value: "", text: "All Type" });

        this.locations = data.data.locations;
        console.log(data.data);
        this.loading = false;
      })
      .catch((err) => console.log(err));

      http
      .get(apiUrl + "v1")
      .then((data) => {
        this.homeConfig = data.data.home_config;
        this.loading = false;
      })
      .catch((err) => console.log(err));
  },
  methods: {
    selectImage(value) {
      this.selectedImageVehicle = value;
      this.$modal.show("imagemodalvehicle");
    },
    findCar(value) {
      const pickdate = new Date(value[0]);
      
      const pdate = pickdate.getDate();
      const pmonth = pickdate.getMonth()+1;
      const pyear =  pickdate.getFullYear();

      const pickup_date  = pdate+'/'+pmonth+'/'+pyear;
      const body = {
        pickup_date: pickup_date,
        pickup_hour: value[1],
        category_id: value[2],
        brand_id: value[3],
      };
      console.log(body);
      http
        .post(apiUrl + "v1/vehicles/filter", body)
        .then((data) => {
          this.vehicles = data.data.vehicles;
        })
        .catch((err) => console.log(err));
    },

    filterPrice(value) {
      http
        .get(apiUrl + "v1/vehicles/hotdeals")
        .then((data) => {
          var objectVehicles = data.data.vehicles;
          this.vehicles = objectVehicles.filter(
            (item) =>
              item.price[0].price >= value[0] && item.price[0].price <= value[1]
          );
        })
        .catch((err) => console.log(err));
    },
  },
};
</script>

<style>
</style>