<template>
  <section class="page-section image subscribe">
      <div class="container">
          <h2 class="section-title wow fadeInDown" data-wow-offset="200" data-wow-delay="100ms">
              <small>You Can Follow Us By E Mail</small>
              <span>Subscrıbe</span>
          </h2>
          <div class="row wow fadeInDown mt-5 justify-content-center" data-wow-offset="200" data-wow-delay="200ms">
              <div class="col-md-8">
                  <p class="text-center mb-5" v-html="data ? data : ''">
                   
                  </p>
                  <!-- Subscribe form -->
                  <div class="form-subscribe">
                      <div class="form-group">
                          <label for="formSubscribeEmail" class="sr-only">Enter your email here</label>
                          <input v-model="email_subscribe" type="text" class="form-control" id="formSubscribeEmail" placeholder="Enter your email here" title="Email is required" style="padding: 24px 12px;">
                          <span v-if="errors.email" class="text-danger mt-1">
                              {{errors.email[0]}}
                          </span>
                          
                      </div>
                      <button @click.prevent="subscribe" class="btn btn-submit btn-theme ripple-effect btn-theme-dark">Subscribe</button>
                  </div>
                  <!-- Subscribe form -->
              </div>
          </div>
      </div>
  </section>
</template>

<script>
import http from "axios";
import { apiUrl } from "@/constants/config";
export default {
    props:["data"],
    data(){
        return{
            email_subscribe: "",
            errors:[],
        }
    },
    methods:{
        subscribe(){
            const body = {
                email : this.email_subscribe,
            }
            http.post(apiUrl+'v1/subscribe', body).then((data) => {
                if(data.data.status == true){
                    this.successSubscribe = true;
                    this.email_subscribe = '';
                    this.errors = [];
                    this.$notify({
                        type: "success",
                        text: "Thank you for subscribing!",
                        duration: "4000",
                    });
                } else {
                    this.errors = data.data.errors;
                }
            })
            .catch()
        }
    },

}
</script>

<style>
.subscribe {
    font-size: 15px;
}
</style>