<template>
    <div class="container mt-5">
        <h2>Your Address</h2>
        <div class="row mt-4">
            <div class="col-md">
        <vue-google-autocomplete
            ref="address"
            id="map"
            classname="form-control"
            placeholder="Please type your address"
            v-on:placechanged="getAddressData"
        >
        </vue-google-autocomplete>
        </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                City
                <input type="text" class="form-control" v-model="city">
            </div>
            <div class="col-md-6">
                State
                <input type="text" class="form-control" v-model="state">
            </div>
            <div class="col-md-6">
                State
                <input type="text" class="form-control" v-model="country">
            </div>
        </div>

        <button @click="tesNotif">
            Tes
        </button>
    </div>
</template>

<script>
import VueGoogleAutocomplete from 'vue-google-autocomplete'

    export default {
        components: { VueGoogleAutocomplete },

        data: function () {
            return {
              address: '',
              city:'',
              state:'',
              country:'',
            }
        },

        mounted() {
            // To demonstrate functionality of exposed component functions
            // Here we make focus on the user input
            this.$refs.address.focus();
        },

        methods: {
            tesNotif(){
                this.$notify({
                    type:'success',
                    text:'Success Upload'
                });
            },
            /**
            * When the location found
            * @param {Object} addressData Data of the found location
            * @param {Object} placeResultData PlaceResult object
            * @param {String} id Input container ID
            */
            getAddressData: function (addressData) {
                this.address = addressData;
                this.city = addressData.administrative_area_level_2;
                this.state = addressData.administrative_area_level_1;
                this.country = addressData.country;
                console.log(this.address);
                console.log(this.address.administrative_area_level_2);
            }
        }
    }
</script>