<template>
  <div class="content-area">
    <div id="preloader" v-if="loading">
      <div id="preloader-status">
        <div class="spinner">
          <div class="rect1"></div>
          <div class="rect2"></div>
          <div class="rect3"></div>
          <div class="rect4"></div>
          <div class="rect5"></div>
        </div>
        <div id="preloader-title">Loading</div>
      </div>
    </div>

    <section class="page-section breadcrumbs text-center">
      <div class="container">
        <div class="page-header">
          <h1>FAQS</h1>
        </div>
        <ul class="breadcrumb justify-content-center">
          <li class="mx-2"><a href="#">Home</a></li>
          <i class="fas fa-angle-right"></i>
          <li class="mx-2"><a href="#">Pages</a></li>
          <i class="fas fa-angle-right"></i>
          <li class="active mx-2">FAQS</li>
        </ul>
      </div>
    </section>

    <section class="page-section with-sidebar sub-page mt-5">
      <div class="container">
        <div class="row">
          <aside class="col-md-12 col-lg-3 sidebar mb-4" id="sidebar">
            <div class="widget shadow">
              <div class="widget-search">
                <input
                  v-on:keyup.enter="searchFaq"
                  class="form-control"
                  type="text"
                  placeholder="Search"
                  v-model="searchValue"
                />
                <button @click.prevent="searchFaq">
                  <i class="fa fa-search"></i>
                </button>
              </div>
            </div>
            <div class="widget shadow car-categories mb-4">
              <h4 class="widget-title">Categories</h4>
              <div class="widget-content">
                <ul>
                  <li
                    v-for="faq_cat in this.$store.state.config.faq_cats"
                    :key="faq_cat.id"
                  >
                    <span
                      class="arrow"
                      block
                      v-b-toggle="'accordion-cat' + faq_cat.id"
                      variant="info"
                      style="text-decoration: none"
                      ><i class="fa fa-angle-down"></i
                    ></span>
                    <a @click="showByCategory(faq_cat.id)">{{
                      faq_cat.name
                    }}</a>
                    <b-collapse
                      :id="'accordion-cat' + faq_cat.id"
                      :visible="true"
                      accordion="my-cat-accordion"
                      role="tabpanel"
                    >
                      <ul class="children">
                        <li
                          v-for="faq_sub in faq_cat.faq_subcategory"
                          :key="faq_sub.id"
                        >
                          <a @click="showBySubcategory(faq_sub.id)"
                            >{{ faq_sub.name
                            }}<span class="count">{{
                              faq_sub.faq.length
                            }}</span></a
                          >
                        </li>
                      </ul>
                    </b-collapse>
                  </li>
                </ul>
              </div>
            </div>
            <div class="widget shadow widget-helping-center col-md-none">
              <h4 class="widget-title">Helping Center</h4>
              <div class="widget-content">
                <p>
                  {{ homeConfig ? homeConfig.help_center_text : "" }}
                </p>
                <h5 class="widget-title-sub">
                  +{{ homeConfig ? homeConfig.phone : "" }}
                </h5>
                <p v-if="homeConfig">
                  <a :href="'mailto:' + homeConfig.email">{{
                    homeConfig.email
                  }}</a>
                </p>
                <div class="button">
                  <router-link to="/contact" class="btn btn-block btn-main-theme btn-20"
                    >Support Center</router-link
                  >
                </div>
              </div>
            </div>
          </aside>

          <div class="col-md-12 col-lg-9 content" id="content">
            <!-- FAQ -->
            <div
              class="panel-group accordion"
              id="accordion"
              role="tablist"
              aria-multiselectable="true"
            >
              <div v-for="(faq, index) in faqs" :key="faq.id">
                <FaqCard
                  :index="index"
                  :data="faq"
                  :accordion="accordion"
                ></FaqCard>
              </div>
            </div>
            <!-- /FAQ -->
          </div>

          <aside class="col-md-12 col-lg-none sidebar" id="sidebar">
            <div class="widget shadow widget-helping-center">
              <h4 class="widget-title">Helping Center</h4>
              <div class="widget-content">
                <p>
                  {{ homeConfig ? homeConfig.help_center_text : "" }}
                </p>
                <h5 class="widget-title-sub">
                  +{{ homeConfig ? homeConfig.phone : "" }}
                </h5>
                <p v-if="homeConfig">
                  <a :href="'mailto:' + homeConfig.email">{{
                    homeConfig.email
                  }}</a>
                </p>
                <div class="button d-sm-flex justify-content-center">
                  <router-link
                    to="/contact"
                    class="btn btn-block btn-theme btn-main-theme btn-theme-dark btn-20"
                    >Support Center</router-link
                  >
                </div>
              </div>
            </div>
          </aside>
        </div>
      </div>
    </section>
    <ContactForm></ContactForm>
  </div>
</template>

<script>
import http from "axios";
import { apiUrl } from "@/constants/config";
import FaqCard from "@/components/FaqCard";
import ContactForm from "@/components/ContactForm";
export default {
  data() {
    return {
      faqs: this.$store.state.config.faqs,
      accordion: null,
      homeConfig: [],
      loading: true,
      searchValue: "",
    };
  },
  components: { FaqCard, ContactForm },
  created() {
    if (this.$store.state.showSideMenu == true) {
      this.$store.state.showSideMenu = !this.$store.state.showSideMenu;
    }

    this.hideLoading();
    this.getHomeConfig();
  },
  methods: {
    showBySubcategory(id) {
      this.faqs = this.$store.state.config.faqs.filter(
        (faq) => faq.faq_subcategory_id == id
      );
      this.loading = false;
    },
    showByCategory(id) {
      this.faqs = this.$store.state.config.faqs.filter(
        (faq) => faq.faq_subcategory.faq_category_id == id
      );
      this.loading = false;
    },

    getHomeConfig() {
      http
        .get(apiUrl + "v1")
        .then((data) => {
          this.homeConfig = data.data.home_config;
          console.log(this.homeConfig);
          this.loading = false;
        })
        .catch((err) => console.log(err));
    },

    hideLoading() {
      setTimeout(() => {
        this.loading = false;
      }, 1000);
    },

    searchFaq() {
      const body = {
        search: this.searchValue,
      };
      http
        .post(apiUrl + "v1/faq/search", body)
        .then((data) => {
          this.faqs = data.data.faqs;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style>
ul,
ol {
  padding-left: 0;
}
a {
  cursor: pointer;
}
</style>