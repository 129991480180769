<template>
  <div>
    <div class="col-md-12 col-lg-4 wow fadeInUp" data-wow-offset="70" data-wow-duration="1s">
        <div class="thumbnail thumbnail-featured no-border no-padding">
            <div class="media">
                <a class="media-link" href="#">
                    <div class="caption" style="min-height:450px;">
                        <div class="caption-wrapper div-table">
                            <div class="caption-inner div-cell">
                                <div class="caption-icon">
                                    <div v-html="icon"/>
                                </div>
                                <h4 class="caption-title">{{ title }}</h4>
                                <div class="caption-text">{{ desc }}</div>
                                <!-- <div class="buttons">
                                    <span class="btn btn-theme ripple-effect btn-theme-transparent">Read More</span>
                                </div> -->
                            </div>
                        </div>
                    </div>
                    <div class="caption hovered">
                        <div class="caption-wrapper div-table">
                            <div class="caption-inner div-cell">
                                <div class="caption-icon">
                                    <div v-html="icon"/>
                                </div>
                                <h4 class="caption-title">{{ title }}</h4>
                            </div>
                        </div>
                    </div>
                </a>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['title', 'desc','icon'],
}
</script>

<style>
.thumbnail .caption.hovered {
    text-decoration: none !important;
}
</style>