<template>
  <div class="section-quotes col-md-12 mx-auto">
    <vueper-slides fixed-height="true" :arrows="false" class="no-shadow">
      <vueper-slide v-for="item in data" :key="item.id">
        <template #content>
          <div class="testimonials-vueper-slides">
            <div class="testimonial">
              <div class="media">
                <div class="media-left">
                  <a href="#">
                    <!-- <b-avatar :src="item.photo" size="6rem" class="media-object testimonial-avatar"></b-avatar> -->
                    <img
                      class="media-object testimonial-avatar"
                      :src="item.photo"
                      alt="Testimonial avatar"
                    />
                  </a>
                </div>
                <div class="media-body">
                  <div class="testimonial-text">
                    <i class="fa fa-quote-left mb-3"></i>
                    <br />
                    <div>
                    {{ item.quotes }}
                    </div>
                    <br />
                    <i class="fa fa-quote-right float-right mt-3"></i>
                  </div>
                  <div class="testimonial-name">
                    {{ item.name }}
                    <span class="testimonial-position">{{
                      item.job_title
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </vueper-slide>
    </vueper-slides>
  </div>
</template>

<script>
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";
export default {
  props: ["data"],
  components: {
    VueperSlides,
    VueperSlide,
  },
  data() {
    return {
      flickityOptions: {
        initialIndex: 1,
        prevNextButtons: true,
        pageDots: true,
        wrapAround: true,
        freeScroll: true,
        imagesLoaded: true,
        percentPosition: false,
      },

      cellAlign: "left",
      contain: true,
      drop_off: "",
    };
  },
};
</script>

<style>
.quotes {
  font-size: 10rem;
  display: inline-block;
}

.section-quotes .testimonial {
  margin-top: 10px;
  padding-left: 100px;
  padding-right: 100px;
  position: relative;
}

.section-quotes .b-avatar {
  margin-top: 70px;
}

.section-quotes .media-left {
  margin-right: 15px !important;
}

.section-quotes .testimonial-name {
  font-size: 18px;
  font-weight: 700;
  line-height: 20px;
  margin-top: 2px;
}

.section-quotes .testimonial-text {
  font-size: 24px;
  font-weight: 300;
  line-height: 36px;
  max-width: 900px;
  margin: 0;
  padding: 30px 0;
  position: relative;
  color: #929292;
  text-align: justify;
}

.section-quotes .testimonial-position {
  font-size: 14px;
  font-weight: 300;
}

.section-quotes .media img {
  margin-top: 50px;
  vertical-align: middle;
  border-style: none;
  border-radius: 50%;
  width: 150px;
  height: 150px;
  background-size: cover;
  margin-right: 20px;
  border: 1px solid #e0e0e0;
  border-style: double;
}

.section-quotes .vueperslides--fixed-height {
  height: 400px;
}

@media (min-width: 601px) and (max-width: 767px) {
  .section-quotes .testimonial-text {
    font-size: 22px;
    line-height: 32px;
  }
}

@media (min-width: 391px) and (max-width: 599.98px) {
  .section-quotes .vueperslides--fixed-height {
    height: 640px !important;
  }
  .section-quotes .testimonial-text {
    font-size: 22px !important;
    line-height: 28px !important;
  }
  .section-quotes .media-left {
    margin-right: 10px !important;
  }

  .section-quotes .media img {
    margin-right: 0px;
  }
}
@media only screen and (max-width: 991.98px) {
  .section-quotes .testimonial {
    padding-left: 0;
    padding-right: 0;
  }
}
@media only screen and (max-width: 600.98px) {
  .section-quotes .testimonial .media {
    display: flex;
    flex-direction: column;
  }

  .section-quotes .testimonial .media-body{
    margin: 0 .5vh !important;
  }

  .section-quotes .media-left {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: -50px;
  }

  .section-quotes .testimonial-text {
    width: 80vw !important;
    text-align: justify;
  }
}
@media only screen and (max-width: 479.98px) {
  .section-quotes .vueperslides--fixed-height {
    height: 500px !important;
  }
}
@media (min-width: 480.1px) and (max-width: 600.98px) {
  .section-quotes .vueperslides--fixed-height {
    height: 480px !important;
  }
}

@media only screen and (max-width: 401px) {
  .section-quotes .vueperslides--fixed-height {
    height: 640px !important;
  }

  .section-quotes .testimonial-text {
    font-size: 18px !important;
    line-height: 32px !important;
  }
}
</style>