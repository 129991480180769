<template>
  <div>
    <div class="content-area content-booking">
      <!-- BREADCRUMBS -->
      <section class="page-section breadcrumbs text-center">
        <div class="container">
          <div class="page-header">
            <h1>Car Booking</h1>
          </div>
          <ul class="breadcrumb">
            <li><a href="#">Home</a></li>
            <i class="fas fa-angle-right mx-2"></i>
            <li><a href="#">Pages</a></li>
            <i class="fas fa-angle-right mx-2"></i>
            <li class="active">Booking & Payment</li>
          </ul>
        </div>
      </section>
      <!-- /BREADCRUMBS -->

      <!-- PAGE WITH SIDEBAR -->
      <section class="page-section with-sidebar sub-page">
        <div class="container">
          <div id="preloader" v-if="loading_booking">
            <div id="preloader-status">
              <div class="spinner">
                <div class="rect1"></div>
                <div class="rect2"></div>
                <div class="rect3"></div>
                <div class="rect4"></div>
                <div class="rect5"></div>
              </div>
              <div id="preloader-title">Loading</div>
            </div>
          </div>

          <div class="row-content-vehicle row">
            <div class="col-md-9 content" id="content">
              <div style="padding-left: 15px; padding-right: 15px">
                <h3 class="block-title alt">Car Information</h3>
                <div class="car-big-card alt">
                  <div class="row">
                    <div class="col-md-8 mt-5">
                      <div>
                        <vueper-slides
                          ref="vueperslides1"
                          :touchable="false"
                          fade
                          :bullets="false"
                          :autoplay="false"
                          @slide="
                            $refs.vueperslides2.goToSlide(
                              $event.currentSlide.index,
                              { emit: false }
                            )
                          "
                          fixed-height="355px"
                          class="vueper1"
                        >
                          <vueper-slide
                            v-for="(slide, i) in slides"
                            :key="i"
                            :image="slide.image"
                          >
                          </vueper-slide>
                        </vueper-slides>

                        <vueper-slides
                          class="no-shadow thumbnails"
                          ref="vueperslides2"
                          @slide="
                            $refs.vueperslides1.goToSlide(
                              $event.currentSlide.index,
                              { emit: false }
                            )
                          "
                          :visible-slides="slides.length"
                          :bullets="false"
                          :touchable="false"
                          :gap="2.5"
                          :arrows="false"
                        >
                          <vueper-slide
                            v-for="(slide, i) in slides"
                            :key="i"
                            :image="slide.image"
                            @click.native="$refs.vueperslides2.goToSlide(i)"
                          >
                          </vueper-slide>
                        </vueper-slides>
                      </div>
                    </div>
                    <div class="col-md-4 mt-5">
                      <div class="car-details">
                        <div class="list">
                          <ul>
                            <li class="title">
                              <h2>
                                {{ vehicle.name }}
                              </h2>
                            </li>
                            <li>
                              <i
                                class="fa fa-check-circle mr-2 text-warning"
                                aria-hidden="true"
                              ></i>
                              {{ vehicle.engine_type }} Engine
                            </li>
                            <li>
                              <i
                                class="fa fa-check-circle mr-2 text-warning"
                                aria-hidden="true"
                              ></i>
                              {{ vehicle.km }} Km
                            </li>
                            <li>
                              <i
                                class="fa fa-check-circle mr-2 text-warning"
                                aria-hidden="true"
                              ></i>
                              Transmission {{ vehicle.transmision }}
                            </li>
                            <!-- <li>5 Year service included</li> -->
                            <li>
                              <i
                                class="fa fa-check-circle mr-2 text-warning"
                                aria-hidden="true"
                              ></i
                              >Manufacturing Year {{ vehicle.reg_year }}
                            </li>
                            <li>
                              <i
                                class="fa fa-check-circle mr-2 text-warning"
                                aria-hidden="true"
                              ></i
                              >{{ vehicle.door }} Doors
                            </li>
                          </ul>
                        </div>
                        <div class="price">
                          <strong>{{ rentalDuration > 0 && vehicle.price ? vehicle.price[0].price : vehicle.hourly }}</strong>
                          <span>$/for 1 {{rentalDuration > 0 ? 'day' : 'hour'}}</span>
                          <i class="fa fa-info-circle"></i>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr class="page-divider half transparent" />

                <div class="row mt-3">
                  <div class="col-md-6 justify-content-between">
                    <h3 class="block-title alt">Extras</h3>
                    <form role="form" class="form-extras">
                      <div v-for="(item, index) in extrasList" :key="index">
                        <label class="container-checkbox"
                          >{{ item.item }}
                          <input
                            :id="'extras_checkboxl_' + index"
                            type="checkbox"
                            :value="item"
                            v-model="extras_items"
                            @change="sumTotal()"
                          />
                          <span class="mark-checkbox"></span>
                          <span class="pull-right"
                            >{{ item.price }} $ /for a day</span
                          >
                        </label>
                      </div>
                    </form>
                  </div>

                  <div class="col-md-6 justify-content-between">
                    <h3 class="block-title alt">Insurances</h3>
                    <form role="form" class="form-extras">
                      <div v-for="(item, index) in insuranceList" :key="index">
                        <label class="container-checkbox"
                          >{{ item.item }}
                          <input
                            :id="'insurance_checkboxl_' + index"
                            type="checkbox"
                            :value="item"
                            v-model="insurance_items"
                            @change="sumTotal()"
                          />
                          <span class="mark-checkbox"></span>
                          <span class="pull-right"
                            >{{ item.price }} $ /for a day</span
                          >
                        </label>
                      </div>
                    </form>
                  </div>
                </div>

                <!-- Login Required -->
                <div v-if="this.$store.state.logged">
                  <div
                    v-if="
                      (!au_address && !ori_address) ||
                      !passport_front ||
                      !driver_license_front ||
                      !driver_license_back ||
                      !holding_license_passport_photo
                    "
                  >
                    <b-alert
                      variant="danger"
                      show
                      style="margin-top: -10px; margin-bottom: 30px"
                    >
                      It looks like you haven't completed your profile, please
                      complete them on the profile page
                      <router-link to="/my-profile" class="btn btn-sm-cus"
                        >Go to profile page
                      </router-link>
                    </b-alert>
                  </div>
                  <div v-else>
                    <h3 class="block-title alt">
                      Customer Information

                      <p
                        v-if="showSubCustomerInformation == false"
                        class="show-sub-button"
                        @click="toggleSubCustomerInformation"
                      >
                        +
                      </p>
                      <p
                        v-else
                        class="show-sub-button"
                        @click="toggleSubCustomerInformation"
                      >
                        -
                      </p>
                    </h3>
                    <div
                      v-if="showSubCustomerInformation"
                      class="sub-customner-information"
                    >
                      <form action="#" class="form-delivery">
                        <div class="row">
                          <div class="col-md-6">
                            <div class="form-group my-2">
                              <label for="name"> Name </label>
                              <input
                                id="fbk-name"
                                title="Name is required"
                                data-toggle="tooltip"
                                class="form-control p-18 alt"
                                type="text"
                                placeholder="Name and Surname:*"
                                v-model="name"
                              />
                              <span
                                v-if="errors.name"
                                class="form-text text-danger"
                                >{{ errors.name[0] }}</span
                              >
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group my-2">
                              <label for="email">Email </label>
                              <input
                                id="fbk-email"
                                title="Email is required"
                                data-toggle="tooltip"
                                class="form-control p-18 alt"
                                type="email"
                                placeholder="Your Email Address:*"
                                v-model="email"
                              />
                              <span
                                v-if="errors.email"
                                class="form-text text-danger"
                                >{{ errors.email[0] }}</span
                              >
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group my-2">
                              <label for="phone number">
                                Telephone Number
                              </label>
                              <input
                                class="form-control p-18 alt"
                                type="tel"
                                placeholder="Telephone Number:"
                                v-model="telephone"
                              />
                              <span
                                v-if="errors.telephone"
                                class="form-text text-danger"
                                >{{ errors.telephone[0] }}</span
                              >
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group my-2">
                              <label for="cell phone number"
                                >Cell Phone Number
                              </label>
                              <input
                                class="form-control p-18 alt"
                                type="tel"
                                placeholder="Cell Phone Number:"
                                v-model="phone"
                              />
                              <p>Use country code. ex: 61*** , 62***</p>
                              <span
                                v-if="errors.phone"
                                class="form-text text-danger"
                                >{{ errors.phone[0] }}</span
                              >
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group my-2">
                              <label for="date_of_birth">Date Of Birth </label>
                              <input
                                class="form-control p-18 alt"
                                type="date"
                                v-model="date_of_birth"
                              />
                              <span
                                v-if="errors.date_of_birth"
                                class="form-text text-danger"
                                >{{ errors.date_of_birth[0] }}</span
                              >
                            </div>
                          </div>
                        </div>
                      </form>

                      <b-alert
                        variant="danger"
                        show
                        style="margin-top: -30px; margin-bottom: 10px"
                        v-if="
                          !passport_front ||
                          !driver_license_front ||
                          !driver_license_back ||
                          !holding_license_passport_photo
                        "
                      >
                        It looks like you haven't completed your profile, please
                        complete them on the profile page
                        <router-link to="/my-profile" class="btn btn-sm-cus"
                          >Go to profile page
                        </router-link>
                      </b-alert>

                      <div class="row mb-4">
                        <div class="col-md-12">
                          <div class="form-group my-2">
                            <label for="au_adress">Search Address</label>
                            <vue-google-autocomplete
                              ref="address"
                              id="map2"
                              classname="form-control p-18 alt"
                              placeholder="Please type your address"
                              v-on:placechanged="getAddressData"
                            >
                            </vue-google-autocomplete>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group my-2">
                            <label for="au_adress">Street Address</label>
                            <input
                              class="form-control p-18 alt"
                              type="text"
                              placeholder="Street Address:"
                              v-model="au_address"
                            />
                            <span
                              v-if="errors.au_address"
                              class="form-text text-danger"
                              >{{ errors.au_address[0] }}</span
                            >
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group my-2">
                            <label for="au_post_code">Post Code</label>
                            <input
                              class="form-control p-18 alt"
                              type="tel"
                              placeholder="Post Code:"
                              v-model="au_post_code"
                            />
                            <span
                              v-if="errors.au_post_code"
                              class="form-text text-danger"
                              >{{ errors.au_post_code[0] }}</span
                            >
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group my-2">
                            <label for="au_city">City</label>
                            <input
                              class="form-control p-18 alt"
                              type="text"
                              placeholder="City:"
                              v-model="au_city"
                            />
                            <span
                              v-if="errors.au_city"
                              class="form-text text-danger"
                              >{{ errors.au_city[0] }}</span
                            >
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group my-2">
                            <label for="au_state">State</label>
                            <input
                              class="form-control p-18 alt"
                              type="text"
                              placeholder="State:"
                              v-model="au_state"
                            />
                            <span
                              v-if="errors.au_state"
                              class="form-text text-danger"
                              >{{ errors.au_state[0] }}</span
                            >
                          </div>
                        </div>
                      </div>
                    </div>

                    <div v-if="customerType === 'international'">
                      <h3 class="block-title alt">
                        Original Address
                        <p
                          v-if="showSubOriginalAddress == false"
                          class="show-sub-button"
                          @click="toggleSubOriginalAddress"
                        >
                          +
                        </p>
                        <p
                          v-else
                          class="show-sub-button"
                          @click="toggleSubOriginalAddress"
                        >
                          -
                        </p>
                      </h3>
                      <div v-if="showSubOriginalAddress">
                        <div class="row mb-4">
                          <div class="col-md-12">
                            <div class="form-group my-2">
                              <label for="au_adress">Search Address</label>
                              <vue-google-autocomplete
                                ref="address"
                                id="map1"
                                classname="form-control p-18 alt"
                                placeholder="Please type your address"
                                v-on:placechanged="getOriAddressData"
                              >
                              </vue-google-autocomplete>
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group my-2">
                              <label for="au_adress">Street Address</label>
                              <input
                                class="form-control p-18 alt"
                                type="text"
                                placeholder="Street Address:"
                                v-model="ori_address"
                              />
                              <span
                                v-if="errors.ori_address"
                                class="form-text text-danger"
                                >{{ errors.ori_address[0] }}</span
                              >
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group my-2">
                              <label for="au_post_code"
                                >Original Post Code</label
                              >
                              <input
                                class="form-control p-18 alt"
                                type="tel"
                                placeholder="Post Code:"
                                v-model="ori_post_code"
                              />
                              <span
                                v-if="errors.ori_post_code"
                                class="form-text text-danger"
                                >{{ errors.ori_post_code[0] }}</span
                              >
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group my-2">
                              <label for="ori_city">Original City</label>
                              <input
                                class="form-control p-18 alt"
                                type="text"
                                placeholder="Original City:"
                                v-model="ori_city"
                              />
                              <span
                                v-if="errors.ori_city"
                                class="form-text text-danger"
                                >{{ errors.ori_city[0] }}</span
                              >
                            </div>
                          </div>
                          <div class="col-md-6">
                            <div class="form-group my-2">
                              <label for="ori_state">Original State</label>
                              <input
                                class="form-control p-18 alt"
                                type="text"
                                placeholder="Original State:"
                                v-model="ori_state"
                              />
                              <span
                                v-if="errors.ori_state"
                                class="form-text text-danger"
                                >{{ errors.ori_state[0] }}</span
                              >
                            </div>
                          </div>

                          <div class="col-md-6">
                            <div class="form-group my-2">
                              <label for="au_post_code">Original Country</label>
                              <input
                                class="form-control p-18 alt"
                                type="text"
                                placeholder="Post Code:"
                                v-model="ori_country"
                              />
                              <span
                                v-if="errors.ori_country"
                                class="form-text text-danger"
                                >{{ errors.ori_country[0] }}</span
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <h3 class="block-title alt">
                      Personal Document
                      <p
                        v-if="showSubPersonalDocument == false"
                        class="show-sub-button"
                        @click="toggleSubPersonalDocument"
                      >
                        +
                      </p>
                      <p
                        v-else
                        class="show-sub-button"
                        @click="toggleSubPersonalDocument"
                      >
                        -
                      </p>
                    </h3>
                    <div v-if="showSubPersonalDocument">
                      <div class="row mb-5">
                        <div class="col-md-6">
                          <label>Driver License (Front)</label>
                          <div>
                            <img :src="driver_license_front" class="doc-img" />
                          </div>
                        </div>
                        <div class="col-md-6">
                          <label>Driver License (Back)</label>
                          <div>
                            <img :src="driver_license_back" class="doc-img" />
                          </div>
                        </div>
                        <div class="col-md-6">
                          <label>Passport (Front)</label>
                          <div>
                            <img :src="passport_front" class="doc-img" />
                          </div>
                        </div>
                        <div class="col-md-6">
                          <label
                            >Photo while holding license and passport</label
                          >
                          <div>
                            <img
                              :src="holding_license_passport_photo"
                              class="doc-img"
                            />
                          </div>
                        </div>
                        <div class="col-md-6">
                          <label for="passport_exp_date"
                            >Passport Expired Date</label
                          >
                          <div class="form-group my-2">
                            <input
                              type="date"
                              class="form-control"
                              v-model="passport_exp_date"
                            />
                            <span
                              v-if="errors.passport_exp_date"
                              class="form-text text-danger"
                              >{{ errors.passport_exp_date[0] }}</span
                            >
                          </div>
                        </div>
                        <div class="col-md-6">
                          <label for="passport_exp_date"
                            >Want to update your document?</label
                          >
                          <div class="form-group">
                            <router-link
                              class="btn btn-sm-cus"
                              to="/my-profile"
                              style="margin: 5px 0 !important"
                            >
                              Go to profile page
                            </router-link>
                          </div>
                        </div>
                      </div>
                    </div>

                    <h3 class="block-title alt">Payment</h3>
                    <div class="row form-credit-card mb-5">
                      <!-- <div class="col-md-6">
                    <div class="form-group my-2">
                      <label for="credit_card_holder">Credit Card Holder </label>
                      <input
                        class="form-control p-18 alt"
                        type="text"
                        placeholder="Credit Card Holder"
                      />
                    </div>
                  </div> -->
                      <div class="col-md-12">
                        <div class="form-group my-2">
                          <label for="payment_period">Payment Method</label>
                          <select
                            class="form-select-control w-100"
                            v-model="payment_method"
                          >
                            <option value="CC">
                              Credit Card
                            </option>
                            <option value="BANK">
                              Bank Transfer
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="col-md-6" v-if="vehicle.category.name == 'Private Car Rental'">
                        <div class="form-group my-2">
                          <label for="payment_period">Payment Period</label>
                          <select
                            class="form-select-control w-100"
                            v-model="payment_period"
                          >
                            <option v-if="rentalDuration == 0 " value="1">
                              Cash
                            </option>
                            <option v-if="rentalDuration >= 7" value="7">
                              Weekly
                            </option>
                            <option v-if="rentalDuration >= 14" value="14">
                              Fortnightly
                            </option>
                            <option v-if="rentalDuration >= 30" value="30">
                              Monthly
                            </option>
                            <option v-if="rentalDuration >= 365" value="365">
                              Yearly
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="col-md-6" v-if="vehicle.category.name == 'Private Car Rental'">
                        <div class="form-group my-2">
                          <label for="payment_period">Calculation</label>
                          <br />
                          <strong>Total</strong> : ${{ grand_total.toFixed(2) }}
                          <br />
                          <div v-if="payment_period == 1">
                            <strong>Cash Payment</strong>
                            <!-- ({{
                              Math.floor(rentalDuration / payment_period)
                            }}
                            days) : ${{
                              (
                                payment_period
                              ).toFixed(2)
                            }} -->
                          </div>
                          <div v-if="payment_period == 7">
                            <strong>Weekly Payment</strong> ({{
                              Math.round(rentalDuration / payment_period)
                            }}
                            weeks) : ${{
                              (
                                grand_total /
                                Math.round(rentalDuration / payment_period)
                              ).toFixed(2)
                            }}
                          </div>
                          <div v-if="payment_period == 14">
                            <strong>Fortnightly Payment</strong> ({{
                              Math.round(rentalDuration / payment_period)
                            }}
                            weeks) : ${{
                              (
                                grand_total /
                                Math.round(rentalDuration / payment_period)
                              ).toFixed(2)
                            }}
                          </div>
                          <div v-if="payment_period == 30">
                            <strong>Monthly Payment</strong> ({{
                              Math.round(rentalDuration / payment_period)
                            }}
                            months) : ${{
                              (
                                grand_total /
                                Math.round(rentalDuration / payment_period)
                              ).toFixed(2)
                            }}
                          </div>
                          <div v-if="payment_period == 365">
                            <strong>Yearly Payment</strong> ({{
                              Math.round(rentalDuration / payment_period)
                            }}
                            year) : ${{
                              (
                                grand_total /
                                Math.round(rentalDuration / payment_period)
                              ).toFixed(2)
                            }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row" v-if="payment_method == 'CC'">
                      <div class="col-md-12">
                        <label for="credit_card_number"
                          >Credit Card Information</label
                        >
                        <stripe-element-card
                          ref="elementRef"
                          :pk="publishableKey"
                          @token="tokenCreated"
                        />
                      </div>
                    </div>

                    <div class="row form-credit-card mb-5">
                      <div ref="card"></div>
                    </div>

                    <h3 class="block-title alt">Additional Information</h3>
                    <form action="#" class="form-additional">
                      <div class="row">
                        <div class="col-md-12">
                          <div class="form-group">
                            <textarea
                              name="fad-message"
                              id="fad-message"
                              title="Addition information is required"
                              data-toggle="tooltip"
                              class="form-control alt"
                              placeholder="Additional Information"
                              cols="30"
                              rows="10"
                              v-model="notes"
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </form>

                    <div class="overflowed reservation-now">
                      <div class="checkbox pull-left">
                        <input
                          id="accept"
                          type="checkbox"
                          name="fd-name"
                          title="Please accept"
                          v-model="accept_checked"
                        />
                        <label for="accept"
                          >I accept all information and Payments etc</label
                        >
                      </div>
                      <a
                        :class="accept_checked == false ? 'disabled' : ''"
                        class="btn-theme pull-right btn-reservation-now"
                        @click.prevent="submit"
                        >Reservation Now</a
                      >
                      <a
                        class="btn-theme pull-right btn-cancel btn-theme-dark"
                        href="#"
                        >Cancel</a
                      >
                    </div>
                  </div>
                </div>
                <div v-else class="text-center">
                  <h3>Please login to order</h3>
                  <div class="button d-flex justify-content-center mt-3">
                    <router-link
                      to="/login"
                      class="btn btn-theme btn-block btn-theme-dark"
                    >
                      Login
                    </router-link>
                  </div>
                </div>
              </div>
            </div>
            <!-- /CONTENT -->
            <aside class="col-md-3 sidebar" id="sidebar">
              <!-- widget detail reservation -->
              <div class="widget shadow widget-details-reservation">
                <h4 class="widget-title">Detail Reservation</h4>
                <div class="widget-content">
                  <h5 class="widget-title-sub">Picking Up Location</h5>
                  <div class="media">
                    <span class="media-object pull-left"
                      ><i class="fa fa-calendar"></i
                    ></span>
                    <div class="media-body">
                      <p>
                        {{
                          new Date(
                            this.$store.state.reservationData.pickup_date
                          ).toLocaleDateString("au", {
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                          })
                        }}
                        /
                        {{ this.$store.state.reservationData.pickup_hour }}
                      </p>
                    </div>
                  </div>
                  <div class="media">
                    <span class="media-object pull-left"
                      ><i class="fa fa-location-arrow"></i
                    ></span>
                    <div class="media-body">
                      <p>
                        From
                        {{ this.$store.state.reservationData.pickup_location }}
                      </p>
                    </div>
                  </div>
                  <h5 class="widget-title-sub">Droping Off Location</h5>
                  <div class="media">
                    <span class="media-object pull-left"
                      ><i class="fa fa-calendar"></i
                    ></span>
                    <div class="media-body">
                      <p>
                        {{
                          new Date(
                            this.$store.state.reservationData.dropoff_date
                          ).toLocaleDateString("au", {
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                          })
                        }}
                        /
                        {{ this.$store.state.reservationData.dropoff_hour }}
                      </p>
                    </div>
                  </div>

                  <div class="media">
                    <span class="media-object pull-left"
                      ><i class="fa fa-location-arrow"></i
                    ></span>
                    <div class="media-body">
                      <p>
                        From
                        {{ this.$store.state.reservationData.dropoff_location }}
                      </p>
                    </div>
                  </div>
                  <h5 class="widget-title-sub">Rental Period</h5>
                  <p>{{ rentalDuration == 0 ? rentalHourDuration + ' hour(s)' : rentalDuration + ' day(s)' }}</p>
                  <div class="button">
                    <router-link
                      to="/reservation"
                      class="
                        btn
                        btn-block
                        btn-theme
                        btn-theme-dark
                        btn-update-reservation
                      "
                      >Update Reservation</router-link
                    >
                  </div>
                </div>
              </div>

              <div
                class="widget shadow widget-details-reservation your-booking"
              >
                <h4 class="widget-title">Your Booking</h4>
                <div class="widget-content">
                  <h5 class="widget-title-sub">Vehicle</h5>
                  <div class="vehicle-image">
                    <!-- {{JSON.parse(vehicle.images)[0]}}
                    <img
                      :src="JSON.parse(vehicle.images)[0]"
                      class="your-booking-img"
                    /> -->
                  </div>
                  <div class="row-detail-booking">
                    <p>{{ vehicle.name }}</p>
                    <p>${{ (grand_total - parseFloat(grand_total / 11)).toFixed(2) }}</p>
                  </div>

                  <h5 v-if="extras_items.length" class="widget-title-sub">
                    Extras
                  </h5>
                  <div
                    v-for="item in extras_items"
                    :key="item.id"
                    class="row-detail-booking"
                  >
                    <p>{{ item.item }}</p>
                    <p>${{ rentalDuration == 0 ? item.price : (rentalDuration * item.price).toFixed(2) }}</p>
                  </div>

                  <h5 v-if="insurance_items.length" class="widget-title-sub">
                    Insurance
                  </h5>
                  <div
                    v-for="item in insurance_items"
                    :key="item.id"
                    class="row-detail-booking"
                  >
                    <p>{{ item.item }}</p>
                    <p>${{ rentalDuration == 0 ? item.price : (rentalDuration * item.price).toFixed(2) }}</p>
                  </div>

                  <div class="row-detail-booking mt-5">
                    <p>GST inc. (10%)</p>
                    <p>
                      ${{
                        grand_total > 1
                          ? parseFloat(grand_total / 11).toFixed(2)
                          : ""
                      }}
                    </p>
                  </div>
                  <div class="row-detail-booking mt-5">
                    <h4>Total Cost</h4>
                    <h4>
                      ${{ grand_total > 1 ? grand_total.toFixed(2) : "" }}
                    </h4>
                  </div>
                </div>
              </div>

              <div class="widget shadow widget-helping-center">
                <h4 class="widget-title">Helping Center</h4>
                <div class="widget-content">
                  <p>
                    {{ homeConfig ? homeConfig.help_center_text : "" }}
                  </p>
                  <h5 class="widget-title-sub">
                    +{{ homeConfig ? homeConfig.phone : "" }}
                  </h5>
                  <p v-if="homeConfig">
                    <a :href="'mailto:' + homeConfig.email">{{
                      homeConfig.email
                    }}</a>
                  </p>
                  <div class="button">
                    <a href="#" class="btn btn-block btn-theme btn-theme-dark"
                      >Support Center</a
                    >
                  </div>
                </div>
              </div>
            </aside>
            <!-- SIDEBAR -->
          </div>

          <!-- <VehicleSidebar
            :showfindcar="showfindcar"
            :showfilter="showfilterprice"
          ></VehicleSidebar> -->

          <!-- /SIDEBAR -->
        </div>
      </section>
      <!-- /PAGE WITH SIDEBAR -->
      <ContactForm></ContactForm>
    </div>
  </div>
</template>
<script>
import http from "axios";
import { apiUrl } from "@/constants/config";
import ContactForm from "@/components/ContactForm";
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";
import "vue-search-select/dist/VueSearchSelect.css";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import { StripeElementCard } from "@vue-stripe/vue-stripe";

export default {
  name: "Booking",
  components: {
    VueperSlides,
    VueperSlide,
    ContactForm,
    VueGoogleAutocomplete,
    StripeElementCard,
  },
  data() {
    return {
      id: this.$route.params.vehicleid,
      showfindcar: false,
      showfilterprice: false,
      vehicle: [],
      vehicle_price: 0,
      display_price: 0,
      display_days: 0,
      slides: [],
      extrasList: [],
      insuranceList: [],
      customer_id: 0,
      invoice: "",
      status: "",
      payment_method: "CC",

      rentalDuration: 0,
      rentalHourDuration: 0,
      payment_period: 7,

      showSubCustomerInformation: false,
      showSubOriginalAddress: false,
      showSubPersonalDocument: false,

      // Customer Data
      customerType: "international",
      name: "",
      email: "",
      phone: 0,
      telephone: 0,
      date_of_birth: "",
      au_address: "",
      au_post_code: "",
      au_city: "",
      au_state: "",
      au_country: "",
      ori_address: "",
      ori_post_code: "",
      ori_city: "",
      ori_state: "",
      ori_country: "",
      driver_license_front: null,
      driver_license_back: null,
      passport_front: null,
      passport_exp_date: null,
      holding_license_passport_photo: null,

      address: {},
      oriAddress: {},

      publishableKey: "pk_test_DfuJNEtCXS9lkzvU3kPYRXkf",
      token: null,
      stripeToken: null,
      successSubmitCC: false,

      homeConfig: [],

      //Booking Data
      booking_time: "",
      dropping_time: "",
      pickup_location: "",
      dropoff_location: "",
      order_items: [],
      extras_items: [],
      insurance_items: [],
      grand_total: 0,
      success_order: false,
      loading_booking: false,
      bookingId: null,
      notes: null,
      accept_checked: false,
      errors: [],
    };
  },
  mounted() {
    this.customerType = "international";
  },
  created() {
    this.getHomeConfig();

    if (this.$store.state.logged == true) {
      this.getAllData();
    } else {
      this.$router.push("/login");
    }
    console.log(this.au_address)
    console.log(this.ori_address)
    console.log(this.passport_front)
    console.log(this.driver_license_front)
    console.log(this.driver_license_back)
    console.log(this.holding_license_passport_photo)
  },
  methods: {
    getAllData() {
      if (this.$store.state.reservationData) {
        if (
          !this.$store.state.reservationData.pickup_date ||
          !this.$store.state.reservationData.pickup_hour ||
          !this.$store.state.reservationData.dropoff_date ||
          !this.$store.state.reservationData.dropoff_hour
        ) {
          alert('k1');
          this.$router.push("/reservation");
        } else {
          this.getRentalDuration();
          this.getVehicleDetail();
          this.getExtrasInsurance();
          this.getCustomerDetail();
        }
      } else {
        alert('k2');
        this.$router.push("/reservation");
      }
    },

    getVehicleDetail() {
      http
        .get(apiUrl + "v1/vehicles/" + this.id)
        .then((data) => {
          this.vehicle = data.data.vehicle;

          if (this.vehicle) {
            if (this.vehicle.stock != 1) {
              this.$router.push("/reservation");
            }
          }

          this.vehicle_price =
            parseFloat(this.vehicle.price[0].price) *
            parseFloat(this.rentalDuration);
          console.log(this.rentalDuration);
          console.log(this.vehicle_price);
          this.display_price = this.vehicle.price[0].price;
          this.display_days = this.vehicle.price[0].days;
          this.selected_duration = this.vehicle.price[0];
          if (this.rentalDuration == 0) {
            this.grand_total = parseFloat(this.vehicle.hourly) * parseFloat(this.rentalHourDuration);
          }else{
            this.grand_total = parseFloat(this.vehicle.price[0].price) * parseFloat(this.rentalDuration);
          }
          var images = JSON.parse(this.vehicle.images);
          this.slides = images.map((item) => {
            const imgs = {};

            imgs.image = item;
            return imgs;
          });

          var prices = this.vehicle.price;
          this.durationList = prices.map((item) => {
            const duration = {};

            duration.label = item.days;
            duration.value = item.price;
            return duration;
          });
        })
        .catch((err) => console.log(err));
    },
    getCustomerDetail() {
      if (this.$store.state.logged) {
        var customerData = this.$store.state.userData;
        this.customer_id = customerData.id;
        this.customerType = customerData.type;
        this.name = customerData.name;
        this.email = customerData.email;
        this.phone = customerData.phone;
        this.telephone = customerData.telephone;
        this.date_of_birth = new Date(customerData.date_of_birth)
          .toISOString()
          .substr(0, 10);
        this.au_address = customerData.au_address;
        this.au_post_code = customerData.au_post_code;
        this.au_city = customerData.au_city;
        this.au_state = customerData.au_state;
        this.au_country = customerData.au_country;
        this.ori_address = customerData.ori_address;
        this.ori_post_code = customerData.ori_post_code;
        this.ori_city = customerData.ori_city;
        this.ori_state = customerData.ori_state;
        this.ori_country = customerData.ori_country;
        this.driver_license_front = customerData.driver_license_front;
        this.driver_license_back = customerData.driver_license_back;
        this.passport_front = customerData.passport_front;
        this.passport_exp_date = new Date(customerData.passport_exp_date)
          .toISOString()
          .substr(0, 10);
        this.holding_license_passport_photo =
          customerData.holding_license_passport_photo;
      }
    },
    getOriAddressData(oriAddressData) {
      this.oriAddress = oriAddressData;
      this.ori_address = oriAddressData.street_number
        ? oriAddressData.street_number + " " + oriAddressData.route
        : oriAddressData.route;
      this.ori_city = oriAddressData.administrative_area_level_2;
      this.ori_state = oriAddressData.administrative_area_level_1;
      this.ori_country = oriAddressData.country;
      console.log(this.address);
    },
    getAddressData(addressData) {
      this.address = addressData;
      this.au_address = addressData.street_number
        ? addressData.street_number + " " + addressData.route
        : addressData.route;
      this.au_city = addressData.administrative_area_level_2;
      this.au_state = addressData.administrative_area_level_1;
      console.log(this.address);
    },
    getExtrasInsurance() {
      http
        .get(apiUrl + "v1/orders")
        .then((data) => {
          this.extrasList = data.data.extras.filter(
            (item) => item.type === "Extras"
          );
          this.insuranceList = data.data.extras.filter(
            (item) => item.type === "Insurance"
          );
        })
        .catch((err) => console.log(err));
    },

    toggleSubCustomerInformation() {
      this.showSubCustomerInformation = !this.showSubCustomerInformation;
    },
    toggleSubOriginalAddress() {
      this.showSubOriginalAddress = !this.showSubOriginalAddress;
    },
    toggleSubPersonalDocument() {
      this.showSubPersonalDocument = !this.showSubPersonalDocument;
    },

    getHomeConfig() {
      http
        .get(apiUrl + "v1")
        .then((data) => {
          this.homeConfig = data.data.home_config;
          console.log(this.homeConfig);
          this.loading = false;
        })
        .catch((err) => console.log(err));
    },

    durationChanged() {
      this.sumTotal();
      this.getDroppingTime();
    },

    getDroppingTime() {
      if (!this.booking_time) {
        return;
      }
      var bookingTime = new Date(this.booking_time);
      var numberOfDaysToAdd = this.selected_duration.days;
      var droppingTime = bookingTime.setDate(
        bookingTime.getDate() + numberOfDaysToAdd
      );
      this.dropping_time = new Date(
        new Date(droppingTime).toString().split("GMT")[0] + " UTC"
      )
        .toISOString()
        .split(".")[0];
    },

    sumTotal() {
      var vehiclePrice = this.selected_duration.price;
      var extrasPrice = this.extras_items.map((item) => item.price);
      var insurancePrice = this.insurance_items.map((item) => item.price);
      // var sumItemPrice = extrasPrice.concat(insurancePrice);
      var totalExtrasPrice = extrasPrice.reduce((acc, item) => {
        return item + acc;
      }, 0);
      var totalInsurancePrice = insurancePrice.reduce((acc, item) => {
        return item + acc;
      }, 0);
      let rentalDuration = this.rentalDuration == 0 ? this.rentalHourDuration : this.rentalDuration;
      let totalItemPrice = 0;


      if (this.rentalDuration == 0) {
        totalItemPrice = parseFloat(totalExtrasPrice) + parseFloat(totalInsurancePrice);

        this.grand_total = (parseFloat(vehiclePrice) * rentalDuration) + parseFloat(totalItemPrice);
      }else{
        totalItemPrice = parseFloat(totalExtrasPrice) * this.selected_duration.days + parseFloat(totalInsurancePrice) * this.selected_duration.days;

        this.grand_total = (parseFloat(vehiclePrice) + parseFloat(totalItemPrice)) * rentalDuration;
      }
    },

    getRentalDuration() {
      const date1 = new Date(this.$store.state.reservationData.pickup_date);
      const date2 = new Date(this.$store.state.reservationData.dropoff_date);

      // One day in milliseconds
      const oneDay = 1000 * 60 * 60 * 24;

      // Calculating the time difference between two dates
      const diffInTime = date2.getTime() - date1.getTime();

      // Calculating the no. of days between two dates
      this.rentalDuration = Math.round(diffInTime / oneDay);
      if (this.rentalDuration == 0) {
        const h1 = this.$store.state.reservationData.pickup_hour
        const h2 = this.$store.state.reservationData.dropoff_hour
        var timeStart = new Date("01/01/2007 " + h1);
        var timeEnd = new Date("01/01/2007 " + h2);

        var diff = (timeEnd - timeStart) / 60000; //dividing by seconds and milliseconds

        var minutes = diff % 60;
        var hours = (diff - minutes) / 60;
        this.rentalHourDuration = hours;
      }
    },

    submit() {
      if (this.payment_method == 'CC') {
        this.$refs.elementRef.submit();
      }
      this.booking();
      this.loading_booking = true;
    },

    tokenCreated(token) {
      this.stripeToken = token.id;

      if (this.stripeToken) {
        this.booking();
      } else {
        this.$notify({
          type: "error",
          text: "There is something wrong",
        });
      }
    },

    booking() {
      var extrasIds = this.extras_items.map((item) => item.id);
      var insuranceIds = this.insurance_items.map((item) => item.id);
      var orderItems = extrasIds.concat(insuranceIds);

      var vehiclePrice = this.selected_duration.price;
      if (
        !this.driver_license_front ||
        !this.driver_license_back ||
        !this.passport_front
      ) {
        this.loading_booking = false;
        this.$notify({
          type: "error",
          text: "Please complete the required documents in your profile page!",
          position: "top center",
          duration: 70000,
        });

        return;
      }

      // var extrasPrice = this.extras_items.map((item) => item.price);
      // var insurancePrice = this.insurance_items.map((item) => item.price);
      // var sumItemPrice = extrasPrice.concat(insurancePrice);
      // var totalItemPrice = sumItemPrice.reduce((acc, item) => {
      //   return item + acc;
      // }, 0);

      const body = {
        vehicle_id: this.id,
        customer_id: this.customer_id,
        type: this.customerType,
        name: this.name,
        email: this.email,
        phone: this.phone,
        telephone: this.telephone,
        date_of_birth: this.date_of_birth,
        au_address: this.au_address,
        au_post_code: this.au_post_code,
        au_city: this.au_city,
        au_state: this.au_state,
        au_country: this.au_country,
        ori_address: this.ori_address,
        ori_post_code: this.ori_post_code,
        ori_city: this.ori_city,
        ori_state: this.ori_state,
        ori_country: this.ori_country,
        booking_time:
          new Date(
            this.$store.state.reservationData.pickup_date
          ).toLocaleDateString("en-CA") +
          " " +
          this.$store.state.reservationData.pickup_hour.slice(0, 5) +
          ":00",
        days: this.rentalDuration,
        vehicle_price: vehiclePrice,
        dropping_time:
          new Date(
            this.$store.state.reservationData.dropoff_date
          ).toLocaleDateString("en-CA") +
          " " +
          this.$store.state.reservationData.dropoff_hour.slice(0, 5) +
          ":00",
        pickup_location: this.$store.state.reservationData.pickup_location,
        dropoff_location: this.$store.state.reservationData.dropoff_location,
        order_items: orderItems,
        payment_period: this.payment_period,
        notes: this.notes,
        total: this.grand_total,
      };

      console.log(body);

      http
        .post(apiUrl + "v1/orders/create", body)
        .then((data) => {
          this.loading_booking = false;
          if (data.data.status === true) {
            this.bookingId = data.data.orders.id;
            if (this.bookingId > 0 && this.stripeToken) {
              this.chargeCreditCard();
              this.$router.push("/success-order");
            } else {
              if (this.payment_method == 'CC') {
                alert("Please submit your credit card");
              }else{
                this.$router.push("/success-order");
              }
            }
            // this.sendSms();
          } else {
            this.errors = data.data.errors;
          }
        })
        .catch((err) => console.log(err));
    },

    sendSms() {
      const body = {
        phone: this.phone,
        message:
          "Thank you for using our service. Please wait for admin to verify your order.",
      };
      http
        .post(apiUrl + "v1/send-sms", body)
        .then((data) => {
          console.log(data.status);
          console.log(body.message);
        })
        .catch((data) => {
          console.log(data.status);
        });
    },

    chargeCreditCard() {
      var amountCharge =
        this.grand_total /
        Math.floor(this.rentalDuration / this.payment_period);

      const body = {
        token: this.stripeToken,
        order_id: this.bookingId,
        email: this.$store.state.userData.email,
        amount: amountCharge,
      };
      http
        .post(apiUrl + "v1/payments/charge-customer", body)
        .then((data) => console.log(data.data))
        .catch((err) => console.log(err));
    },
  },
};
</script>
<style>
@media only screen and (max-width: 767.99px) {
  .row-content-vehicle {
    flex-direction: column-reverse;
  }
}

.content-booking {
  font-size: 15px;
}
.content-booking .vueperslide {
  background-repeat: no-repeat;
  background-size: contain;
}

.content-booking .car-big-card .car-details .price {
  /* padding: 15px 15% !important; */
}

.content-booking .form-control.alt {
  font-size: 13px !important;
}
.content-booking .row-detail-booking {
  display: flex;
  justify-content: space-between;
}

.content-booking .btn-sm-cus {
  background-color: #f1be02 !important;
  padding: 5px 10px !important;
  margin: 10px 0;
  font-size: 13px !important;
}

.content-booking .StripeElement {
  background-color: #f6f6f8 !important;
}

.content-booking .btn-update-reservation {
  padding: 13px 20px !important;
  background-color: #000;
  color: #fff;
}

.content-booking .btn-update-reservation:hover {
  background-color: #f1be02;
}

.row-detail-booking p {
  margin-bottom: 2px !important;
  font-size: 13px;
}

.row-detail-booking h4 {
  color: #6e757f !important;
  font-weight: 700 !important;
}

.your-booking-img {
  width: 120px;
  margin-bottom: 10px;
}

.your-booking .widget-title-sub {
  padding: 10px 20px !important;
  margin: 10px;
}

.car-details .list {
  margin-top: 20px;
}

.content-booking .vueper1 {
  margin-top: 17px !important;
}

.content-booking .vueperslides__arrow svg {
  width: 25px !important;
  padding: 8px !important;
  stroke-width: 2;
}

.content-booking .vueperslides__arrow--prev {
  left: 0 !important;
}
.content-booking .vueperslides__arrow--next {
  right: 0 !important;
}

.content-booking .btn-submit-cc {
  background-color: #f1be02;
}

.content-booking .btn-submit-cc:hover {
  background-color: #000;
  color: #fff;
}

@media only screen and (max-width: 767.98px) {
  .thumbnails {
    max-width: 80%;
    margin-top: -80px;
    margin-left: 10px;
  }

  .content-booking .content {
    padding: 0 !important;
  }

  .content-booking .container-checkbox {
    padding-left: 30px !important;
  }

  .content-booking .container-checkbox .pull-right {
    padding: 0 !important;
  }
}

@media only screen and (min-width: 768px) {
  .thumbnails {
    margin: auto;
    max-width: 60%;
    margin-top: -110px;
    margin-left: 10px;
  }

  .content-booking .car-details .list ul {
    padding-left: 30px !important;
  }
}

.thumbnails .vueperslide {
  box-sizing: border-box;
  border: 5px solid #e7e7e7;
  transition: 0.3s ease-in-out;
  opacity: 0.7;
  cursor: pointer;
  max-width: 25% !important;
  height: 70%;
}

.thumbnails .vueperslide:hover {
  border: 5px solid #d9534f;
}

.vueperslides__arrow {
  background-color: #000;
  opacity: 0.3;
}

.ui.selection.dropdown {
  background-color: #f6f6f8;
  border: 0;
}
.p-18 {
  padding: 1.8rem;
}

.show-sub-button {
  cursor: pointer;
  float: right;
  font-size: 120%;
}
</style>