<template>
  <div class="container mb-5">

      <h2 class="section-title wow fadeInDown mt-5 py-5" data-wow-offset="200" data-wow-delay="100ms">
          <small>Do You Have Any Question or Anything else </small>
          <span>Customer service</span>
      </h2>
      <!-- Team row -->
      <div class="row row-customer-service d-flex justify-content-center">
        <!-- Team 1 -->
          <div v-for="item in data" :key="item.id" class="card-cs col-md-6 col-lg-4 wow fadeInDown">
              <div class="thumbnail thumbnail-team no-border no-padding">
                  <div class="media">
                      <img :src="item.photo" alt="" class="img-cs"/>
                  </div>
                  <div class="caption">
                      <h4 class="caption-title">{{item.name}} <small>{{item.job_title}}</small></h4>
                      <ul class="customer-team-details">
                          <li>Skype: {{item.skype}}</li>
                          <li>Tel: {{item.phone}}</li>
                          <li><a :href="'mailto:'+item.email" style="color: #f1be02;">{{item.email}}</a></li>
                      </ul>
                  </div>
              </div>
          </div>
          <!-- /Team 1 -->

      </div>
      <!-- /Team row -->
  </div>
</template>

<script>
export default {
    props: ['data']
}
</script>

<style>
.row-customer-service.thumbnail{
    height:480px;
}
.thumbnail-team .caption {
    height:220px;
}

.thumbnail-team .customer-team-details{
    padding-inline-start: 0 !important;
    font-size: 15px;
    line-height: 24px;
    color: #7f7f7f;
}

.img-cs{
    height:300px;
    object-fit: cover;
}

@media only screen and (max-width:600px) {
    .thumbnail-team {
        margin-top: 16px;
    }
}
</style>