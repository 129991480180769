<template>
  <div class="image-modal-vehicle">
    <v-modal name="imagemodalvehicle" v-if="selectedimage">
      <div style="padding:15px 10px 15px 25px;">
        <a style="float:right;" class="ml-5" @click="closeModal">
            <i class="fa fa-2x fa-times" style="cursor:pointer;"></i>
        </a>
        <div class="d-flex justify-content-center align-items-center ml-4">
          <img :src="selectedimage" class="selected-image" />
        </div>
      </div>
    </v-modal>
  </div>
</template>

<script>
export default {
  props: ["selectedimage"],
  methods: {
      closeModal(){
          this.$modal.hide('imagemodalvehicle');
      }
  }
};
</script>

<style>
.image-modal-vehicle .vm--modal {
  overflow: auto;
  display: inline-table;
  padding: 5px 0px 5px 5px;
  background-color: #ffffff;
  width: auto !important;
  height: auto !important;
  position: absolute;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%);
}

.image-modal-vehicle img {
  height: 450px;
  width: auto;
}

.image-modal-vehicle .btn-close {
  float: right;
  background-color: #a3a3a3;
  border-radius: 40% !important;
  color: #fff;
  font-size: 12px;
  font-weight: 600;
  padding: 2px 20px !important;
}

@media only screen and (max-width: 600px) {
  .image-modal-vehicle .vm--modal {
    width: 100% !important;
    height: 100% !important;
  }

  .image-modal-vehicle img {
    height: auto;
    width: 100%;
  }
}
</style>