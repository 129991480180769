<template>
  <div>
    <div id="preloader" v-if="loading">
      <div id="preloader-status">
        <div class="spinner">
          <div class="rect1"></div>
          <div class="rect2"></div>
          <div class="rect3"></div>
          <div class="rect4"></div>
          <div class="rect5"></div>
        </div>
        <div id="preloader-title">Loading</div>
      </div>
    </div>
    <Banner  :data="banners" v-if="banners.length > 0" />
    <div class="container">
      <div class="row main-header">
        <div class="col-md">
          <!-- <div class="slide1" v-bind:style="{ 'background-image': 'url(' + backgroundImage + ')' }"> -->
          <div class="slide1">
            <div>
              <h2 class="title text-center">All Discounts Just For You</h2>
              <h3 class="subtitle text-center">Find Best Rental Car</h3>
            </div>
            <search-filter
              :data="locations"
              :background="backgroundImage"
              :categories="allCategories"
            ></search-filter>
          </div>
        </div>
      </div>
    </div>
    <RentalOffers :categories="categories"></RentalOffers>
    <WhoWeAre
      :title="aboutUsTitle"
      :description="aboutUsDescription"
      :images="aboutUsImages"
    ></WhoWeAre>
    <div class="row mx-auto my-2" v-if="features">
      <div class="container my-3">
        <Caption
          :icon="features[0] ? features[0].icon : ''"
          :title="features[0] ? features[0].name : ''"
          :desc="features[0] ? features[0].desc : ''"
        ></Caption>
        <Caption
          :icon="features[1] ? features[1].icon : ''"
          :title="features[1] ? features[1].name : ''"
          :desc="features[1] ? features[1].desc : ''"
        ></Caption>
        <Caption
          :icon="features[2] ? features[2].icon : ''"
          :title="features[2] ? features[2].name : ''"
          :desc="features[2] ? features[2].desc : ''"
        ></Caption>
      </div>
    </div>
    <section class="page-section testimonials">
      <div class="container">
        <div class="row mx-auto d-flex">
          <Quotes :data="this.$store.state.config.quotes"></Quotes>
        </div>
      </div>
    </section>
    <section class="section-vehicle">
      <div class="container p-5">
        <h2
          class="section-title wow fadeInUp"
          data-wow-offset="70"
          data-wow-delay="100ms"
        >
          <small>Select What You Want</small>
          <span>OUR AWESOME RENTAL FLEET CARS</span>
        </h2>
        <div class="row my-3">
          <div class="col-md-12">
            <b-tabs>
              <b-tab
                v-for="(item, index) in categories"
                :key="index"
                :title="item.name"
                :active="index == 1"
              >
                <div class="col-md-12 col-vehicle">
                  <div>
                    <b-card no-body class="mt-3">
                      <b-tabs
                        card
                        vertical
                        class="awesome-sub mt-1"
                        align="center"
                        style="
                          display: flex;
                          align-content: center;
                          justify-content: center;
                        "
                      >
                        <div class="nav">
                          <b-tab
                            v-for="vehicle in item.vehicle.slice(0, 8)"
                            :key="vehicle.id"
                            :title="vehicle.name"
                          >
                            <div class="car-big-card alt">
                              <div
                                class="row row-detail-vehicle"
                                style="margin-top: -5px !important"
                              >
                                <div class="col-md-6 col-lg-8">
                                  <div>
                                    <div>
                                      <vueper-slides
                                        ref="homevueperslides1"
                                        :touchable="false"
                                        no-shadow
                                        fade
                                        :autoplay="false"
                                        :bullets="false"
                                        fixed-height="true"
                                        lazy
                                        lazy-load-on-drag
                                        :dragging-distance="50"
                                        @slide="
                                          $refs.homevueperslides2 &&
                                            $refs.homevueperslides2.goToSlide(
                                              $event.currentSlide.index,
                                              { emit: false }
                                            )
                                        "
                                      >
                                        <vueper-slide
                                          v-for="(slide, i) in JSON.parse(
                                            vehicle.images
                                          )"
                                          :key="i"
                                          :image="slide"
                                        >
                                          <template #content>
                                            <button
                                              class="btn btn-expand-image"
                                              @click.prevent="
                                                selectImage(slide)
                                              "
                                            >
                                              <i class="fas fa-expand-alt"></i>
                                            </button>
                                            <div v-if="vehicle.stock == 0" class="leased-out-badge">
                                              Leased Out
                                            </div>
                                          </template>
                                        </vueper-slide>
                                      </vueper-slides>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-6 col-lg-4">
                                  <div class="car-details">
                                    <div
                                      v-if="vehicle.price.length > 0 && !vehicle.hourly"
                                      class="price"
                                    >
                                      <strong>{{
                                        vehicle.price
                                          ? vehicle.price[vehicle.price.length - 1]
                                              .price
                                          : "-"
                                      }}</strong>
                                      <span
                                        >$/for
                                        {{
                                          vehicle.price
                                            ? vehicle.price[vehicle.price.length - 1]
                                                .days
                                            : "-"
                                        }}
                                        day(s)</span
                                      >
                                      <i class="fa fa-info-circle"></i>
                                    </div>
                                    <div class="price" v-else>
                                      <strong>
                                        {{ vehicle.hourly }}
                                      </strong>
                                      <span>
                                        $/for 1 hour(s)
                                      </span>
                                      <i class="fa fa-info-circle"></i>
                                    </div>
                                    <div class="list">
                                      <ul>
                                        <li class="title">
                                          <h2>
                                            {{ vehicle.name }}
                                            <span v-if="vehicle.stock == 0" class="leased-out-text"> Leased Out</span>
                                          </h2>

                                        </li>
                                        <li>
                                          <i
                                            class="
                                              fa fa-check-circle
                                              mr-2
                                              text-warning
                                              pull-left
                                            "
                                            aria-hidden="true"
                                          ></i>
                                          {{ vehicle.engine_type }}
                                        </li>
                                        <li>
                                          <i
                                            class="
                                              fa fa-check-circle
                                              mr-2
                                              text-warning
                                            "
                                            aria-hidden="true"
                                          ></i>
                                          {{ vehicle.km }} Km
                                        </li>
                                        <li>
                                          <i
                                            class="
                                              fa fa-check-circle
                                              mr-2
                                              text-warning
                                            "
                                            aria-hidden="true"
                                          ></i>
                                          Transmission {{ vehicle.transmision }}
                                        </li>
                                        <li>
                                          <i
                                            class="
                                              fa fa-check-circle
                                              mr-2
                                              text-warning
                                            "
                                            aria-hidden="true"
                                          ></i
                                          >Manufacturing Year
                                          {{ vehicle.reg_year }}
                                        </li>
                                        <li>
                                          <i
                                            class="
                                              fa fa-check-circle
                                              mr-2
                                              text-warning
                                            "
                                            aria-hidden="true"
                                          ></i
                                          >{{ vehicle.door }} Doors
                                        </li>
                                      </ul>
                                    </div>

                                    <div class="button">
                                      <router-link
                                       v-if="vehicle.stock == 1"
                                        :to="{ path: '/booking/' + vehicle.id }"
                                        class="
                                          btn btn-theme
                                          ripple-effect
                                          btn-theme-dark
                                          btn-block
                                          btn-reservation-vehicle
                                        "
                                        >Reservation Now</router-link
                                      >
                                      <router-link
                                       v-else
                                        :to="{ path: '/booking/' + vehicle.id }"
                                        class="
                                          btn btn-theme
                                          ripple-effect
                                          btn-theme-dark
                                          btn-block
                                          btn-reservation-vehicle
                                          disabled
                                        "
                                        >Reservation Now</router-link
                                      >
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </b-tab>
                        </div>
                      </b-tabs>
                    </b-card>
                  </div>
                </div>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </section>

    <section class="page-section testimonials">
      <div class="container">
        <div class="row my-5">
          <div
            class="col-md-3 col-sm-6 wow fadeInDown"
            data-wow-offset="200"
            data-wow-delay="100ms"
          >
            <div class="thumbnail thumbnail-counto no-border no-padding">
              <div class="caption">
                <div class="caption-icon"><i class="fa fa-heart"></i></div>
                <div class="caption-number">
                  {{ this.$store.state.config_home.happy_customer_count }}
                </div>
                <h4 class="caption-title">Happy costumers</h4>
              </div>
            </div>
          </div>
          <div
            class="col-md-3 col-sm-6 wow fadeInDown"
            data-wow-offset="200"
            data-wow-delay="200ms"
          >
            <div class="thumbnail thumbnail-counto no-border no-padding">
              <div class="caption">
                <div class="caption-icon"><i class="fa fa-car"></i></div>
                <div class="caption-number">
                  {{ this.$store.state.config_home.car_count }}
                </div>
                <h4 class="caption-title">Total car count</h4>
              </div>
            </div>
          </div>
          <div
            class="col-md-3 col-sm-6 wow fadeInDown"
            data-wow-offset="200"
            data-wow-delay="300ms"
          >
            <div class="thumbnail thumbnail-counto no-border no-padding">
              <div class="caption">
                <div class="caption-icon"><i class="fa fa-flag"></i></div>
                <div class="caption-number">
                  {{ this.$store.state.config_home.total_km_count }}
                </div>
                <h4 class="caption-title">Total KM/MIL</h4>
              </div>
            </div>
          </div>
          <div
            class="col-md-3 col-sm-6 wow fadeInDown"
            data-wow-offset="200"
            data-wow-delay="400ms"
          >
            <div class="thumbnail thumbnail-counto no-border no-padding">
              <div class="caption">
                <div class="caption-icon"><i class="fa fa-comments"></i></div>
                <div class="caption-number">
                  {{ this.$store.state.config_home.call_center_count }}
                </div>
                <h4 class="caption-title">Call Center Solutions</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section class="page-section">
      <div class="container mb-4">
        <h2
          class="section-title wow fadeInDown"
          data-wow-offset="200"
          data-wow-delay="100ms"
        >
          <small>See What People Ask to Us</small>
          <span>FAQS</span>
        </h2>

        <div class="row mt-5" style="margin-top: -5px !important">
          <div
            class="panel-group accordion"
            id="accordion"
            role="tablist"
            aria-multiselectable="true"
            style="width: 100% !important"
          >
            <div class="col-md-12 col-lg-6" data-wow-offset="200">
              <div v-for="(faq, index) in listFaqs" :key="faq.id">
                <!-- FAQ -->
                <FaqCard
                  v-if="index < listFaqs.length / 2"
                  :index="index"
                  :data="faq"
                ></FaqCard>
                <!-- /FAQ -->
              </div>
            </div>
            <div
              class="col-md-12 col-lg-6 wow fadeInLeft"
              data-wow-offset="200"
              data-wow-delay="200ms"
            >
              <div v-for="(faq, index) in listFaqs" :key="faq.id">
                <FaqCard
                  v-if="index > listFaqs.length / 2"
                  :index="index"
                  :data="faq"
                ></FaqCard>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <FindCarForm :categories="categoryList"></FindCarForm>
    <SubscribeForm
      :data="this.$store.state.config_home.subscribe_text"
    ></SubscribeForm>
    <CustomerServiceCard :data="customerServices"></CustomerServiceCard>
    <ContactForm></ContactForm>
    <ImageModal :selectedimage="selectedImage"></ImageModal>
  </div>
</template>

<script>
import { VueperSlides, VueperSlide } from "vueperslides";
import "vueperslides/dist/vueperslides.css";
import SearchFilter from "@/components/SearchFilter";
import Banner from "@/components/Banner";
import Caption from "@/components/Caption";
import Quotes from "@/components/Quotes";
import FaqCard from "@/components/FaqCard";
import SubscribeForm from "@/components/SubscribeForm";
import FindCarForm from "@/components/FindCarForm";
import CustomerServiceCard from "../components/CustomerServiceCard";
import ContactForm from "../components/ContactForm";
import RentalOffers from "../components/RentalOffers.vue";
import WhoWeAre from "@/components/WhoWeAre.vue";
import ImageModal from "@/components/ImageModal.vue";
import http from "axios";
import { apiUrl } from "@/constants/config";

export default {
  components: {
    SearchFilter,
    VueperSlides,
    VueperSlide,
    RentalOffers,
    WhoWeAre,
    Banner,
    Caption,
    Quotes,
    FaqCard,
    SubscribeForm,
    FindCarForm,
    CustomerServiceCard,
    ContactForm,
    ImageModal,
  },
  data() {
    return {
      flickityOptions: {
        initialIndex: 1,
        prevNextButtons: true,
        pageDots: true,
        wrapAround: true,
        freeScroll: true,
        imagesLoaded: true,
        percentPosition: false,
      },

      cellAlign: "left",
      contain: true,
      backgroundImage: "",
      drop_off: "",
      locations: [],
      selectedImage: "",
      aboutUsTitle: "",
      aboutUsDescription: "",
      aboutUsImages: [],
      banners: [],
      features: [],
      categories: {},
      allCategories: {},
      categoryList: [],
      selectedCategory: 0,
      vehicles: {},
      display_price: 0,
      display_days: 0,
      selectedVehicle: 0,
      customerServices: [],
      listFaqs: this.$store.state.config.faqs,
      loading: true,
    };
  },
  name: "Home",
  created() {
    if (this.$store.state.showSideMenu == true) {
      this.$store.state.showSideMenu = !this.$store.state.showSideMenu;
    }

    this.getHomeData();
  },
  methods: {
    getHomeData() {
      http
        .get(apiUrl + "v1")
        .then((data) => {
          if (data.data.status === true) {
            this.backgroundImage = data.data.home_config.background_image;
            this.aboutUsTitle = data.data.home_config.title_description;
            this.aboutUsDescription = data.data.home_config.description;
            this.aboutUsImages = JSON.parse(data.data.home_config.images);
            this.locations = data.data.locations;
            this.banners = data.data.banners;
            this.features = data.data.features.slice(0, 3);
            this.categories = data.data.categories;
            this.allCategories = data.data.categories;
            this.categoryList = data.data.categories.map((item) => {
              const categories = {};

              categories.value = item.id;
              categories.text = item.name;
              return categories;
            });
            this.customerServices = data.data.customer_services;
            this.loading = false;
          } else {
            this.errors = data.data.errors;
          }
        })
        .catch((err) => console.log(err));
    },

    selectImage(image) {
      this.selectedImage = image;
      this.$modal.show("imagemodal");
    },
  },
};
</script>

<style scoped>
.main-header {
  margin-top: 0 !important;
}

.slide1 {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@media only screen and (max-width: 600px) {
  .slide1 .title {
    font-size: 26px !important;
  }
  .slide1 .subtitle {
    font-size: 36px !important;
  }
}

@media (min-width: 600px) and (max-width: 991.98px) {
  .slide1 .title {
    font-size: 40px !important;
  }
  .slide1 .subtitle {
    font-size: 50px !important;
  }
}

.thumbnail.thumbnail-featured .caption {
  padding: 35px 20px;
  min-height: 450px;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  content: "" !important;
  display: block !important;
  position: absolute;
  width: 0;
  height: 0;
  border-top: 35px solid transparent;
  border-left: 35px solid #f1be02;
  border-bottom: 35px solid transparent;
  border-right: none;
  left: auto;
  right: 0;
  top: 0;
  margin-right: -35px;
  z-index: 2;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff !important;
  background-color: #f1be02 !important;
}
.section-vehicle {
  font-family: "Roboto", sans-serif !important;
}

@media only screen and (max-width: 677.98px) {
  .section-vehicle .btn-reservation {
    margin: 10px 0 0 0 !important;
  }
}

@media only screen and (max-width: 767.98px) {
  .section-vehicle .thumbnails {
    max-width: 80%;
    margin-top: -55px;
    margin-left: 10px;
  }

  .section-vehicle .tabs > ul {
    background-color: #f1be02 !important;
  }
  /*
  .section-vehicle .tabs li {
    width: 70%;
  } */
}
@media only screen and (max-width: 767.98px) {
  .section-vehicle .tabs ul {
    display: flex !important;
    flex-direction: column !important;
    align-content: center !important;
    justify-content: center !important;
  }
}

.section-vehicle .nav-tabs > li > a {
  margin-right: 0 !important;
}

.section-vehicle .btn-expand-image {
  background-color: #0000007d;
  color: #fff;
  float: right;
  padding: 5px 10px !important;
  font-size: 14px;
  margin: 10px;
}

.section-vehicle .btn-reservation-vehicle {
  padding: 13px 20px !important;
  font-size: 14px !important;
  color: #fff;
  background-color: #000;
}

.section-vehicle .btn-reservation-vehicle:hover {
  background-color: #f1be02;
}

.section-vehicle .vueperslide {
  background-repeat: no-repeat;
  background-size: contain;
}

.section-vehicle .vueperslides--fixed-height {
  height: 400px;
}

.section-vehicle .card-body {
  padding: 0 !important;
}

.section-vehicle .tabs {
  margin: 0 !important;
}

.section-vehicle .tabs.awesome-sub ul {
  margin-top: -5px !important;
}

.section-vehicle .car-big-card .car-details .button {
  padding: 0 15% !important;
}

@media only screen and (max-width: 600px) {
  .section-vehicle .vueperslides--fixed-height {
    height: 240px !important;
  }

  .section-vehicle .col-auto ul {
    padding: 0 !important;
    margin-top: -3px !important;
  }
}

@media only screen and (min-width: 768px) {
  .thumbnails {
    margin: auto;
    max-width: 60%;
    margin-top: -110px;
    margin-left: 10px;
  }

  .row-detail-vehicle {
    height: 400px;
  }

  .section-vehicle .card-header {
    padding: 0 !important;
  }
  .section-vehicle .car-details ul {
    padding: 0 !important;
  }

  .section-vehicle .car-big-card.alt .car-details .price {
    padding: 20px 40px !important;
    display: flex;
  }
}

.thumbnails .vueperslide {
  box-sizing: border-box;
  border: 5px solid #e7e7e7;
  transition: 0.3s ease-in-out;
  opacity: 0.7;
  cursor: pointer;
  width: 33%;
  height: 70%;
}

.thumbnails .vueperslide:hover {
  border: 5px solid #d9534f;
}

.vueperslides__arrow {
  background-color: #000;
  opacity: 0.3;
}

.car-big-card.alt .car-details .price {
  padding: 20px;
  background-color: #f5f6f8;
}
</style>