<template>
  <div>
    <div class="content-area content my-order">
      <div id="preloader" v-if="loading">
        <div id="preloader-status">
          <div class="spinner">
            <div class="rect1"></div>
            <div class="rect2"></div>
            <div class="rect3"></div>
            <div class="rect4"></div>
            <div class="rect5"></div>
          </div>
          <div id="preloader-title">Loading</div>
        </div>
      </div>
      <!-- BREADCRUMBS -->
      <section class="page-section breadcrumbs text-center">
        <div class="container">
          <div class="page-header">
            <h1>List Order</h1>
          </div>
          <ul class="breadcrumb">
            <li><a href="#">Home</a></li>
            <i class="fas fa-angle-right mx-2"></i>
            <li><a href="#">Pages</a></li>
            <i class="fas fa-angle-right mx-2"></i>
            <li class="active">List Order</li>
          </ul>
        </div>
      </section>
      <!-- /BREADCRUMBS -->

      <!-- PAGE WITH SIDEBAR -->
      <section class="page-section with-sidebar sub-page">
        <div class="container">
          <!-- CONTENT -->
          <div class="col-md-12 content" id="content">
            <table class="table table-bordered table-hover table-responsive-md">
              <thead>
                <tr>
                  <td>Invoice Number</td>
                  <td>Vehicle</td>
                  <td>Booking Time</td>
                  <td>Dropping Time</td>
                  <td>Amount</td>
                  <td>Status</td>
                  <td>Payment</td>
                  <td></td>
                </tr>
              </thead>
              <tbody>
                <template v-for="item in orders">
                  <!-- <tr v-for="i in (Math.floor(item.days/item.payment_period))" :key="i">
                            <td :class="invoice+i">{{item.invoice}}</td>
                            <td>{{item.vehicle ? item.vehicle.name : ''}}</td>
                            <td>{{new Date(item.booking_time).toLocaleDateString()}}</td>
                            <td>{{new Date(item.dropping_time).toLocaleDateString()}}</td>
                            <td>${{item.payment ? (item.payment.amount/100).foFixed(2) : ''}}</td>
                            <td>
                              <b-badge v-if="item.payment && item.payment.is_paid === 1" variant="success">Paid</b-badge>
                              <b-badge v-else variant="danger">Unpaid</b-badge>
                            </td>
                            <td>
                              <a v-if="item.payment && item.payment.is_paid === 1" :href="item.payment.receipt_url" target="_blank">
                              <b-badge variant="success">Receipt</b-badge>
                              </a>
                            </td>
                            <td>
                                <b-button class="btn btn-success btn-detail" @click="showDetailOrder(item.id)">
                                    Detail
                                </b-button>
                            </td>
                            </tr> -->
                  <tr
                    v-for="i in item.payment_sequence.length"
                    :key="item.invoice + i"
                  >
                    <td :id="'invoice' + i">{{ item.invoice }}</td>
                    <td>{{ item.vehicle ? item.vehicle.name : "" }}</td>
                    <td>
                      {{ new Date(item.booking_time).toLocaleDateString() }}
                    </td>
                    <td>
                      {{ new Date(item.dropping_time).toLocaleDateString() }}
                    </td>
                    <td>${{ item.charge_period_amount }}</td>
                    <td>
                      <b-badge
                        v-if="
                          item.payment[i - 1] &&
                          item.payment[i - 1].is_paid === 1
                        "
                        variant="success"
                        >Paid</b-badge
                      >
                      <span class="text-danger" v-else>
                        Unpaid
                        <!-- {{
                          new Date(item.payment_sequence[i - 1]?.due_date
                        ).toLocaleDateString()
                        }} -->
                      </span>
                    </td>
                    <!-- <td>
                      <b-badge
                        v-if="
                          item.payment[i - 1] &&
                          item.payment[i - 1].is_paid === 1
                        "
                        variant="success"
                        >Paid</b-badge
                      >
                      <span v-else class="text-danger">
                        Due Date :
                        {{
                          new Date(item.payment_sequence[i - 1].due_date
                        ).toLocaleDateString()
                        }}
                      </span>
                    </td> -->
                    <td>
                      <a
                        v-if="
                          item.payment[i - 1] &&
                          item.payment[i - 1].is_paid === 1
                        "
                        :href="item.payment[i - 1].receipt_url"
                        target="_blank"
                      >
                        <b-badge variant="info">Receipt</b-badge>
                      </a>
                      <b-button
                        style="font-size: 9.5pt !important;"
                        class="btn btn-success btn-detail"
                        v-else
                        @click="payNow(item)"
                        >Pay Now</b-button
                      >
                    </td>
                    <td>
                      <b-button
                        style="font-size: 10pt !important;"
                        class="btn btn-success btn-detail"
                        @click="showDetailOrder(item)"
                      >
                        Detail
                      </b-button>
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
          <!-- /CONTENT -->
          <!-- <td>
                                <b-badge v-if="item.status === 'Pending'" variant="warning">Pending</b-badge>
                                <b-badge v-if="item.status === 'Rejected'" variant="dark">Rejected</b-badge>
                                <b-badge v-if="item.status === 'Booked'" variant="primary">Booked</b-badge>
                                <b-badge v-if="item.status === 'Done'" variant="success">Done</b-badge>
                                <b-badge v-if="item.status === 'Cancel'" variant="danger">Cancel</b-badge>
                            </td> -->
          <DetailOrderModal
            :detailorder="detailOrder"
            :extras="extras"
            :insurance="insurance"
          ></DetailOrderModal>
        </div>
      </section>
      <!-- /PAGE WITH SIDEBAR -->

      <ContactForm></ContactForm>
      <div id="preloader" v-if="loading">
        <div id="preloader-status">
          <div class="spinner">
            <div class="rect1"></div>
            <div class="rect2"></div>
            <div class="rect3"></div>
            <div class="rect4"></div>
            <div class="rect5"></div>
          </div>
          <div id="preloader-title">Loading</div>
        </div>
      </div>
      <div class="paynow-modal">
        <v-modal name="paynowmodal" v-if="$store.state.orderToBePaid">
          <div class="paynowmodal-content">
            <div class="w-100">
              <div class="row">
                <div class="col-md-4">
                  <div class="form-group my-2">
                    <label for="rego_no"> Rego No </label>
                    <input
                      class="form-control my-2"
                      readonly
                      :value="$store.state.orderToBePaid.vehicle.name"
                    />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group my-2">
                    <label for="rego_no"> Booking Date </label>
                    <input
                      class="form-control my-2"
                      readonly
                      :value="
                        new Date(
                          $store.state.orderToBePaid.booking_time
                        ).toLocaleDateString()
                      "
                    />
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group my-2">
                    <label for="amount"> Amount </label>
                    <input
                      class="form-control my-2"
                      readonly
                      :value="
                        '$' + $store.state.orderToBePaid.charge_period_amount
                      "
                    />
                  </div>
                </div>
                <div class="col-md-12">
                  <div class="form-group my-2">
                    <label for="amount">Credit Card Information </label>
                    <stripe-element-card
                      ref="elementRef"
                      :pk="publishableKey"
                      @token="tokenCreated"
                      class="mt-2"
                    />
                  </div>
                </div>
              </div>
              <button class="btn btn-sm-cus mt-4 float-right" @click="submit">
                Pay now
              </button>
            </div>
          </div>
        </v-modal>
      </div>
    </div>
  </div>
</template>
<script>
import http from "axios";
import { apiUrl } from "@/constants/config";
import DetailOrderModal from "@/components/DetailOrderModal";
import ContactForm from "@/components/ContactForm";
import { StripeElementCard } from "@vue-stripe/vue-stripe";

export default {
  name: "OrderHistory",
  components: { DetailOrderModal, ContactForm, StripeElementCard },
  data() {
    return {
      customer_id: this.$store.state.userData.id,
      orders: [],
      detailOrder: [],
      extras: [],
      insurance: [],
      selectedOrder: 0,
      orderToBePaid: null,

      publishableKey: "pk_test_DfuJNEtCXS9lkzvU3kPYRXkf",
      token: null,
      stripeToken: null,
      loading: false,

      errors: [],
    };
  },

  created() {
    this.getOrder();
  },
  methods: {
    getOrder() {
      this.loading = true;
      http
        .get(apiUrl + "v1/orders/" + this.customer_id)
        .then((data) => {
          this.loading = false;
          this.orders = data.data.orders;
          console.log(this.orders);
        })
        .catch((err) => console.log(err));
    },

    showDetailOrder(value) {
      this.$store.commit("setOrderData", value);
      console.log(this.$store.state.orderDetail);
      // this.getDetailOrder();
      this.$modal.show("detailorder");
    },

    payNow(value) {
      if (this.$store.state.logged == true) {
        this.$store.commit("setOrderToBePaid", value);
        this.$modal.show("paynowmodal");
      } else {
        this.$router.push("/login");
      }
    },

    submit() {
      this.$refs.elementRef.submit();
      this.loading = true;
    },

    tokenCreated(token) {
      this.stripeToken = token.id;
      const body = {
        token: this.stripeToken,
        order_id: this.$store.state.orderToBePaid.id,
        email: this.$store.state.orderToBePaid.customer.email,
        amount: this.$store.state.orderToBePaid.charge_period_amount,
      };
      http
        .post(apiUrl + "v1/payments/charge-customer", body)
        .then((data) => {
          if (data.data.status == true) {
            this.getOrder();
            this.$notify({
              type: "success",
              text: "Your credit card information has been saved",
              duration: 4500,
            });
            this.$modal.hide("paynowmodal");
            this.loading = false;
            this.updatePaymentSequence();
          }
        })
        .catch((err) => console.log(err));
    },

    getDetailOrder() {
      http
        .get(apiUrl + "v1/orders/" + this.customer_id)
        .then((data) => {
          var order = data.data.orders;
          this.detailOrder = order[0];
          this.extras = order[0].order_item.filter(
            (item) => item.type === "Extras"
          );
          this.insurance = order[0].order_item.filter(
            (item) => item.type === "Insurance"
          );
        })
        .catch((err) => console.log(err));
    },

    updatePaymentSequence() {
      const body = {
        order_id: this.$store.state.orderToBePaid.id,
      };
      http
        .post(apiUrl + "v1/payments/update-payment-sequence", body)
        .then((data) => console.log(data.status))
        .cath((err) => console.log(err));
    },
  },
};
</script>
<style>
.my-order .btn-detail {
  padding: 2px 10px !important;
  border-radius: 4px !important;
  font-size: 12px !important;
}
.my-order .table {
  font-size: 14px !important;
}

.paynow-modal .vm--modal {
  overflow: auto;
  height: 30% !important;
  width: 50% !important;
  position: absolute;
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%);
}

.paynow-modal .paynowmodal-content {
  padding: 40px 20px;
}

@media only screen and (max-width: 600px) {
  .paynow-modal .vm--modal {
    top: 0 !important;
    left: 0 !important;
    width: 100% !important;
    height: 100% !important;
  }
}
</style>